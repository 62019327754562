import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class DataService {
    constructor(private http: HttpClient) {
    }

    saveUserToken(token) {
        this.setItem('token', JSON.stringify(token));
    }
    saveUserSearch(userSearch) {
        this.setItem('userSearch', JSON.stringify(userSearch));
    }

    saveCountries(countries: any) {
        this.setItem('countries', JSON.stringify(countries));
    }

    getKeyByValue(object, value) {
        var result = JSON.parse(object);
        for (var key in result) { if (result[key] === value) { return key } }
    }

    setItem(key: string, val: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                window.localStorage.setItem(key, val);
                resolve(true);
            } catch (error) {
                reject(false);
            }
        });
    }

    getItem(key): Promise<string | boolean> {
        return new Promise((resolve, reject) => {
            try {
                const data = window.localStorage.getItem(key);
                data ? resolve(data) : reject(false);
            } catch (error) {
                reject(false);
            }
        });
    }

    getOS() {
        var userAgent = window.navigator.userAgent,
            platform = window.navigator.platform,
            macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'],
            os = null;

        if (macosPlatforms.indexOf(platform) !== -1) {
            os = 'Mac OS';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            os = 'iOS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            os = 'Windows';
        } else if (/Android/.test(userAgent)) {
            os = 'Android';
        } else if (!os && /Linux/.test(platform)) {
            os = 'Linux';
        }

        return os;
    }
}
