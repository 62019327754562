import {Component, OnDestroy, OnInit} from '@angular/core';
import * as $ from 'jquery';
import {NavigationEnd, Router, ActivatedRoute} from '@angular/router';
import {filter} from 'rxjs/internal/operators';
import {HttpClient} from '@angular/common/http';
import {DataService, AlertService, AuthenticationService} from '@app/_services';
import {apiUrls} from '@app/shared/app.constants';
import {environment} from '@environments/environment';
import {FormGroup, FormBuilder, FormControl, Validators, FormArray} from '@angular/forms';
import {MessageService} from '@app/_services/message.service';
import {HeaderService} from '@app/shared/header/header.service';
import {DestinationService} from '@app/destinations/destinations.service';
import {MustMatch} from '@app/_helpers/must-match.validator';
import {first} from 'rxjs/operators';

@Component({
    selector: 'app-activate-account',
    templateUrl: './activate-account.component.html',
    styleUrls: ['./activate-account.component.css']
})
export class ActivateAccountComponent implements OnInit {

    passwordsubmitted = false;
    passwordForm: FormGroup;
    redirectTo: any;
    hashcode: any;
    alertMessage: any;

    constructor(private http: HttpClient,
                private fb: FormBuilder,
                private authenticationService: AuthenticationService,
                private route: ActivatedRoute,
                private dataService: DataService,
                private headerService: HeaderService,
                private messageService: MessageService,
                private destinationService: DestinationService,
                private router: Router,
                private formBuilder: FormBuilder) {

    }

    ngOnInit(): void {
        this.route.params.subscribe(routeParams => {
            this.hashcode = routeParams.passwordhash;
        });
        this.activateAccount();

    }


    activateAccount() {
        $('.loader').show();
        const subs = this.http.post<any>(`${environment.apiUrl}/${apiUrls.activateAccount}`,
            {
                'hashcode': this.hashcode
            }).subscribe(res => {
            subs.unsubscribe();
            if (res) {
                $('.loader').hide();
                this.alertMessage = res.message;
                this.showAlert();
            }
        }, (err) => {
            $('.loader').hide();
        }, () => {
        });
    }

    showAlert() {
        $('.layer').show();
        $('.alert').show();
        $('#alerttitle').html('Registration Succesful.');
        $('#alertContent').html(this.alertMessage);
    }

    closeAlert() {
        $('.layer').hide();
        $('.alert').hide();
        $('#alerttitle').html('');
        $('#alertContent').html('');
        this.router.navigate(['/login']);
    }

}
