<div class="login-signup show">
    <div class="login-signup-centered" id="signupformdiv">
        <div class="log-sign-main" style="min-height: 300px">

        </div>
    </div>
</div>

<div class="layer" style="display: none"></div>
<div class="alert" style="display: none">
    <h2 id="alerttitle">Alert !!!</h2>
    <button class="btn alert-close" (click)="closeAlert()"><img src="assets/images/close.svg"></button>
    <p id="alertContent"></p>
    <div class="alertbtns" style="margin-top: 15px">
        <button id="applyCoupon" class="red" (click)="closeAlert()">Okay</button>
    </div>
</div>