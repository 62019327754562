<div class="login-signup2" style="display: none">
    <div class="login-signup2-centered" id="loginform">
        <div class="log-sign-main2">
            <h2>Login</h2>
            <div id="poperror" style="text-align: center;color: red;"></div>
            <button class="btn log-sign-close" (click)="loginpopupclose()"><img src="assets/images/close.svg"></button>
            <div class="log-sign-form">
                <form class="form" [formGroup]="loginFormPop">
                    <div class="form-field">
                        <input type="text" class="form-control" id="username" formControlName="username" placeholder="Your Username" [ngClass]="{ 'is-invalid': submittedpop && f.username.errors }">
                        <div *ngIf="submittedpop && f.username.errors" class="invalid-feedback">
                            <div *ngIf="f.username.errors.required">Username is required</div>
                        </div>
                        <span class="log-sign-icon"><img src="../assets/images/user-icon.svg"></span>
                    </div>
                    <div class="form-field">
                        <input type="password" class="form-control"  formControlName="password" placeholder="Your Password" id="password" [ngClass]="{ 'is-invalid': submittedpop && f.password.errors }">
                        <div *ngIf="submittedpop && f.password.errors" class="invalid-feedback">
                            <div *ngIf="f.password.errors.required">Password is required</div>
                            <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                        </div>
                        <span class="log-sign-icon"><img src="../assets/images/privacy.svg"></span>
                    </div>
                    <div class="row">
                        <div class="col-xs-6">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="customCheck11">
                                <label class="custom-control-label" for="customCheck11">Remember me</label>
                            </div>
                        </div>
                        <div class="col-xs-6 text-right">
                            <a [routerLinkActive]="['active']" routerLink="/forgot-password" class="forgot-pass-btn">Forgot password?</a>
                        </div>
                    </div>
                    <button type="button" (click)="onPopSubmitLogin()" class="btn btn-sign">Submit</button>
                </form>
                <div class="signup-link" >Don't have an account Create?<a (click)="showreg()">Signup</a></div>
                <div class="forgotPass"><a (click)="showforgot()">Forgot password?</a></div>
            </div>
        </div>
    </div>
    <div class="login-signup2-centered" id="registerform" style="display: none">
        <div class="log-sign-main2">
            <h2>Register</h2>
            <button class="btn log-sign-close" (click)="loginpopupclose()"><img src="assets/images/close.svg"></button>
            <div class="log-sign-form">
                <div id="regerrortop"></div>
                <form class="form appointment-form" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                    <fieldset>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 select-section">
                            <div class="row">
                                <div class="form-group">
                                    <input class="form-control" type="text" id="firstName" formControlName="firstName"
                                           placeholder="First Name"
                                           [ngClass]="{ 'is-invalid': submitted && reg.firstName.errors }">
                                    <div *ngIf="submitted && reg.firstName.errors" class="invalid-feedback">
                                        <div *ngIf="reg.firstName.errors.required">First Name Is required</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="row">
                                <div class="form-group">
                                    <input class="form-control" type="text" id="mobile" formControlName="mobile"
                                           placeholder="Your mobile number"
                                           [ngClass]="{ 'is-invalid': submitted && reg.mobile.errors }">
                                    <div *ngIf="submitted && reg.mobile.errors" class="invalid-feedback">
                                        <div *ngIf="reg.mobile.errors.required">Mobile number is required</div>
                                        <div *ngIf="reg.mobile.errors.pattern">Please, Enter 10 digit Mobile Number.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="row">
                                <div class="form-group">
                                    <input class="form-control" type="email" id="email" formControlName="email"
                                           placeholder="Your Email"
                                           [ngClass]="{ 'is-invalid': submitted && reg.email.errors }">
                                    <div *ngIf="submitted && reg.email.errors" class="invalid-feedback">
                                        <div *ngIf="reg.email.errors.required">Email is required</div>
                                        <div *ngIf="reg.email.errors.email">Email must be a valid email address
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="row">
                                <div class="form-group">
                                    <input type="password" formControlName="password" class="form-control" placeholder="Enter Password" [ngClass]="{ 'is-invalid': submitted && reg.password.errors }" />
                                    <div *ngIf="submitted && reg.password.errors" class="invalid-feedback">
                                        <div *ngIf="reg.password.errors.required">Password is required</div>
                                        <div *ngIf="reg.password.errors.minlength">Password must be at least 6 characters</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <input type="password" formControlName="confirmPassword" class="form-control" placeholder="Confirm Password" [ngClass]="{ 'is-invalid': submitted && reg.confirmPassword.errors }" />
                                    <div *ngIf="submitted && reg.confirmPassword.errors" class="invalid-feedback">
                                        <div *ngIf="reg.confirmPassword.errors.required">Confirm Password is required</div>
                                        <div *ngIf="reg.confirmPassword.errors.mustMatch">Passwords must match</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="row">
                                <div class="form-group">
                                    <div class="center">
                                        <button class="btn btn-sign" type="submit">Register</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
                <div class="signup-link">If you have an account login?<a (click)="showlogin()">Login</a></div>
                <div class="forgotPass"><a (click)="showforgot()">Forgot password?</a></div>
            </div>
        </div>
    </div>
    <div class="login-signup2-centered" id="registerformalert" style="display: none">
        <div class="log-sign-main2">
            <h2>Register</h2>
            <button class="btn log-sign-close" (click)="loginpopupclose()"><img src="assets/images/close.svg"></button>
            <div class="log-sign-form">
                <div id="regalert">Please check your email to activate the account</div>
            </div>
        </div>
    </div>
    <div class="login-signup2-centered" id="forgotFromdiv" style="display: none">
        <div class="log-sign-main2">
            <h2>Forgot Password</h2>
            <div id="popfogerror" style="text-align: center;color: red;padding-top: 20px;"></div>
            <button class="btn log-sign-close" (click)="loginpopupclose()"><img src="assets/images/close.svg"></button>
            <div class="log-sign-form">
                <form class="form" [formGroup]="forgotFrom" (ngSubmit)="onSubmitLogin()">
                    <div class="form-group">
                        <input class="form-control" (change)="emailchange()" type="email" id="fogemail" formControlName="email"
                               placeholder="Your Email"
                               [ngClass]="{ 'is-invalid': submitted && fog.email.errors }">
                        <div *ngIf="submitted && fog.email.errors" class="invalid-feedback">
                            <div *ngIf="fog.email.errors.required">Email is required</div>
                            <div *ngIf="fog.email.errors.email">Email must be a valid email address
                            </div>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-sign">Submit</button>
                </form>
                <div class="signup-link">If you have an account login?<a (click)="showlogin()" >Login</a></div>
            </div>
        </div>
    </div>