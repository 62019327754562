import {Component, OnDestroy, OnInit} from '@angular/core';
import * as $ from 'jquery';
import {NavigationEnd, Router, ActivatedRoute} from '@angular/router';
import {filter} from 'rxjs/internal/operators';
import {HttpClient} from '@angular/common/http';
import {DataService} from '@app/_services';
import {apiUrls} from '@app/shared/app.constants';
import {environment} from '@environments/environment';
import {HeaderService} from '../../shared/header/header.service';
import {MessageService} from '../../_services/message.service';
import {DestinationService} from '../../destinations/destinations.service';
import {FormGroup, FormBuilder, FormControl, Validators, FormArray} from '@angular/forms';

@Component({
    selector: 'app-listing',
    templateUrl: './listing.component.html',
    styleUrls: ['./listing.component.css']
})
export class ListingComponent implements OnInit {

    public contactForm: FormGroup;
    submitted: any;
    alertMessage: any;
    constructor(private http: HttpClient,
                private formBuilder: FormBuilder,
                private route: ActivatedRoute,
                private dataService: DataService,
                private headerService: HeaderService,
                private messageService: MessageService,
                private destinationService: DestinationService,
                private router: Router) {

    }

    ngOnInit(): void {
        this.contactForm = this.formBuilder.group({
            first_name: ['', Validators.required],
            last_name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            phone: ['', [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
            comments: ['', Validators.required],
        });
    }

    get f() {
        return this.contactForm.controls;
    }

    onSubmit() {
        this.submitted = true;
        if (this.contactForm.invalid) {
            return;
        } else {
            $('.loader').show();
            const subs = this.http.post<any>(`${environment.apiUrl}/${apiUrls.contactus}`,
                {
                    first_name: this.f.first_name.value,
                    last_name: this.f.last_name.value,
                    email: this.f.email.value,
                    phone: this.f.phone.value,
                    comments: this.f.comments.value
                }).subscribe(res => {
                subs.unsubscribe();
                if (res) {
                    $('.loader').hide();
                    //alert(res.message);
                    this.contactForm.reset();
                    //this.contactForm.resetForm();

                    this.showAlert('success');
                    //$('#contactusformsuccess').show();
                    //$('#contactusformdiv').hide();
                }
            }, (err) => {
                $('.loader').hide();
            }, () => {
            });
        }
    }

    showAlert(showtype:any) {
        $('.layer2').show();
        $('.alert2').show();
        $('#alerttitle2').html('Alert !!!');
        $('#alertContent2').html(this.alertMessage);
        if(showtype == 'success'){
            $('#alerttitle2').hide();
            $('#alertContent2').hide();
            $('#successcontent2').show();
        }else{
            $('#alerttitle2').show();
            $('#alertContent2').show();
            $('#successcontent2').hide();
        }
    }

    closeAlert() {
        $('.layer2').hide();
        $('.alert2').hide();
        $('#alerttitle2').html('');
        $('#alertContent2').html('');
        location.reload();
    }
}
