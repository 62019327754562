<section id="main">
    <div class="deals_list">
        <div class="container">
            <div class="deals_title">
                <span>Australia</span>
            </div>
            <div class="deal_1">
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <figure class="deal_img">
                            <span class="deal_offer">Hot Deal</span>
                            <img src="./assets/images/deal-1.jpg" alt="">
                        </figure>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <h3>Motor Home Time: Promo 30%</h3>
                        <div class="pickup_location">
                            <p><strong>Pick up location:</strong> any location <br><strong>Drop off location:</strong> any location</p>
                        </div>
                        <div class="pickup_date">
                            <p><strong>Pick up between:</strong> 01 May 2020 - 30 Sep 2020 <br><strong>Rental length:</strong> any duration</p>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                        <div class="star_rating">
                            <span><i class="fa fa-star" aria-hidden="true"></i></span>
                            <span><i class="fa fa-star" aria-hidden="true"></i></span>
                            <span><i class="fa fa-star" aria-hidden="true"></i></span>
                            <span class="start_empty"><i class="fa fa-star" aria-hidden="true"></i></span>
                            <span class="start_empty"><i class="fa fa-star" aria-hidden="true"></i></span>
                        </div>
                        <a href="#" class="btn">View Deal</a>
                    </div>
                </div>
            </div>
            <div class="deal_1">
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <figure class="deal_img">
                            <span class="deal_offer">Hot Deal</span>
                            <img src="./assets/images/deal-1.jpg" alt="">
                        </figure>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <h3>Motor Home Time: Promo 30%</h3>
                        <div class="pickup_location">
                            <p><strong>Pick up location:</strong> any location <br><strong>Drop off location:</strong> any location</p>
                        </div>
                        <div class="pickup_date">
                            <p><strong>Pick up between:</strong> 01 May 2020 - 30 Sep 2020 <br><strong>Rental length:</strong> any duration</p>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                        <div class="star_rating">
                            <span><i class="fa fa-star" aria-hidden="true"></i></span>
                            <span><i class="fa fa-star" aria-hidden="true"></i></span>
                            <span><i class="fa fa-star" aria-hidden="true"></i></span>
                            <span class="start_empty"><i class="fa fa-star" aria-hidden="true"></i></span>
                            <span class="start_empty"><i class="fa fa-star" aria-hidden="true"></i></span>
                        </div>
                        <a href="#" class="btn">View Deal</a>
                    </div>
                </div>
            </div>
            <div class="deals_title">
                <span>New Zealand</span>
            </div>
            <div class="deal_1">
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <figure class="deal_img">
                            <span class="deal_offer">Hot Deal</span>
                            <img src="./assets/images/deal-1.jpg" alt="">
                        </figure>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <h3>Motor Home Time: Promo 30%</h3>
                        <div class="pickup_location">
                            <p><strong>Pick up location:</strong> any location <br><strong>Drop off location:</strong> any location</p>
                        </div>
                        <div class="pickup_date">
                            <p><strong>Pick up between:</strong> 01 May 2020 - 30 Sep 2020 <br><strong>Rental length:</strong> any duration</p>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                        <div class="star_rating">
                            <span><i class="fa fa-star" aria-hidden="true"></i></span>
                            <span><i class="fa fa-star" aria-hidden="true"></i></span>
                            <span><i class="fa fa-star" aria-hidden="true"></i></span>
                            <span class="start_empty"><i class="fa fa-star" aria-hidden="true"></i></span>
                            <span class="start_empty"><i class="fa fa-star" aria-hidden="true"></i></span>
                        </div>
                        <a href="#" class="btn">View Deal</a>
                    </div>
                </div>
            </div>
            <div class="deal_1">
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <figure class="deal_img">
                            <span class="deal_offer">Hot Deal</span>
                            <img src="./assets/images/deal-1.jpg" alt="">
                        </figure>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <h3>Motor Home Time: Promo 30%</h3>
                        <div class="pickup_location">
                            <p><strong>Pick up location:</strong> any location <br><strong>Drop off location:</strong> any location</p>
                        </div>
                        <div class="pickup_date">
                            <p><strong>Pick up between:</strong> 01 May 2020 - 30 Sep 2020 <br><strong>Rental length:</strong> any duration</p>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                        <div class="star_rating">
                            <span><i class="fa fa-star" aria-hidden="true"></i></span>
                            <span><i class="fa fa-star" aria-hidden="true"></i></span>
                            <span><i class="fa fa-star" aria-hidden="true"></i></span>
                            <span class="start_empty"><i class="fa fa-star" aria-hidden="true"></i></span>
                            <span class="start_empty"><i class="fa fa-star" aria-hidden="true"></i></span>
                        </div>
                        <a href="#" class="btn">View Deal</a>
                    </div>
                </div>
            </div>
            <div class="deals_title">
                <span>United States</span>
            </div>
            <div class="deal_1">
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <figure class="deal_img">
                            <span class="deal_offer">Hot Deal</span>
                            <img src="./assets/images/deal-1.jpg" alt="">
                        </figure>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <h3>Motor Home Time: Promo 30%</h3>
                        <div class="pickup_location">
                            <p><strong>Pick up location:</strong> any location <br><strong>Drop off location:</strong> any location</p>
                        </div>
                        <div class="pickup_date">
                            <p><strong>Pick up between:</strong> 01 May 2020 - 30 Sep 2020 <br><strong>Rental length:</strong> any duration</p>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                        <div class="star_rating">
                            <span><i class="fa fa-star" aria-hidden="true"></i></span>
                            <span><i class="fa fa-star" aria-hidden="true"></i></span>
                            <span><i class="fa fa-star" aria-hidden="true"></i></span>
                            <span class="start_empty"><i class="fa fa-star" aria-hidden="true"></i></span>
                            <span class="start_empty"><i class="fa fa-star" aria-hidden="true"></i></span>
                        </div>
                        <a href="#" class="btn">View Deal</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>