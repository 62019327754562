import {
    Component,
    AfterViewInit,
    OnDestroy,
    ViewChild,
    ElementRef,
    ChangeDetectorRef
} from '@angular/core';

import {NgForm} from '@angular/forms';
import {AngularStripeService} from '@fireflysemantics/angular-stripe-service';

@Component({
    selector: 'app-stripe-payment',
    templateUrl: './stripe-payment.component.html',
    styleUrls: ['./stripe-payment.component.css']
})
export class StripePaymentComponent implements AfterViewInit, OnDestroy {

    @ViewChild('cardInfo', {static: false}) cardInfo: ElementRef;

    stripe;
    loading = false;
    confirmation;

    card: any;
    cardHandler = this.onChange.bind(this);
    error: string;

    constructor(private cd: ChangeDetectorRef,
                private stripeService: AngularStripeService) {
    }

    ngAfterViewInit() {
        this.stripeService.setPublishableKey('pk_test_51IBMBkFGsbvXqn8Lunj6ZdEvqGkgiCl8AYCSnfw1aflJfeqLv2wxFzBNZoqrbuY1l1jJA8TAsvU0AHzhVmPpQaSS00OXSRPwFh').then(
            stripe => {
                this.stripe = stripe;
                const elements = stripe.elements();
                this.card = elements.create('card');
                this.card.mount(this.cardInfo.nativeElement);
                this.card.addEventListener('change', this.cardHandler);
            });
    }

    ngOnDestroy() {
        this.card.removeEventListener('change', this.cardHandler);
        this.card.destroy();
    }

    onChange({error}) {
        if (error) {
            this.error = error.message;
        } else {
            this.error = null;
        }
        this.cd.detectChanges();
    }

    async onSubmit(form: NgForm) {
        const {token, error} = await this.stripe.createToken(this.card);

        if (error) {
            console.log('Error:', error);
        } else {
            this.tokenSubmit(token);
            console.log('Success!', token);
        }
    }

    async tokenSubmit(token: any) {
        console.log(token + 'Token function called');
    }
}