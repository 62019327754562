<section class="innerSection">
    <div class="container">
        <div class="contactUsWrap">
            <div class="innerJournySearch">
                <img src="assets/images/leftbanner2.jpg" class="hidden-xs">
            </div>
            <div class="contactUs">
                <h3>Partners</h3>
                <div id="contactusformdiv">
                    <p>Please use this form to contact us with any questions, problems or feedback. We will respond as
                        soon as we can.</p>
                    <div class="contactusForm">
                        <form class="form" #contact="ngForm" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                            <ul>
                                <li>

                                    <label>First name</label>
                                    <input class="" type="text" id="first_name"
                                           formControlName="first_name"
                                           [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }">

                                    <div *ngIf="submitted && f.first_name.errors" class="invalid-feedback">
                                        <div *ngIf="f.first_name.errors.required">First Name Is required
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <label>Last name</label>
                                    <input class="" type="text" id="last_name"
                                           formControlName="last_name"
                                           [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }">

                                    <div *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
                                        <div *ngIf="f.last_name.errors.required">Last Name Is required
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <label>Email</label>
                                    <input class="" type="email" id="email"
                                           formControlName="email"
                                           [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                        <div *ngIf="f.email.errors.required">Email is required</div>
                                        <div *ngIf="f.email.errors.email">Email must be a valid email
                                            address
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <label>Phone number</label>
                                    <input class="" type="text" id="phone"
                                           formControlName="phone" maxlength="10" 
                                           [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
                                    <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                                        <div *ngIf="f.phone.errors.required">phone number is required
                                        </div>
                                        <div *ngIf="f.phone.errors.pattern">Please enter 10 digit Mobile
                                            Number.
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <label>Comments</label>
                                    <textarea class="" id="comments"
                                              name="comments"
                                              formControlName="comments"
                                              [ngClass]="{ 'is-invalid': submitted && f.comments.errors }"></textarea>
                                    <div *ngIf="submitted && f.comments.errors" class="invalid-feedback">
                                        <div *ngIf="f.comments.errors.required">comments is required
                                        </div>

                                    </div>
                                </li>
                                <li>
                                    <button type="submit">Submit</button>
                                </li>
                            </ul>
                        </form>
                    </div>
                </div>
                <div id="contactusformsuccess" style="display: none">
                    Thanks for your enquiry our team will connect you soon !! Have a great day
                </div>
            </div>
            <div class="callUs">
                <h3>Call us</h3>
                <h4>Call our rental experts now<br>
                    Tel: <a href="tel:0345 9000 420">0345 9000 420</a> <br>
                    Email: <a href="mailto:info@affordablemotorhomeholidays.com">info@affordablemotorhomeholidays.com</a>
                </h4>
                <div class="compnyInfo">
                    <h3>Company Information</h3>
                    <address>
                        Broxbourne Business Centre Pindar Road <br> Hoddesdon, Hertfordshire EN11 0FJ <br> United
                        Kingdom
                    </address>
                </div>
                <div class="map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9876.934006388328!2d0.0035250883606119388!3d51.765338336026346!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4b95e9fff928b77c!2sAmbition%20Broxbourne%20Business%20Centre!5e0!3m2!1sen!2sin!4v1613582297364!5m2!1sen!2sin"
                            frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
                            tabindex="0"></iframe>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="layer2" style="display: none"></div>
<div class="alert2" style="display: none" >
    <h2 id="alerttitle2">Alert !!!</h2>
    <button class="btn alert-close" (click)="closeAlert()"><img src="assets/images/close.svg"></button>
    <p id="alertContent2"></p>
    <div id="successcontent2">
        <h2>Submitted Successfully</h2>
        <h3>Thank you for choosing</h3>
        <h4>Affordable Motorhome Holidays</h4>
        <p>we value your bussiness and look
            forward  to assisting you!</p>
    </div>
    <div class="alertbtns" style="margin-top: 15px">
        <button id="applyCoupon" class="red" (click)="closeAlert()">Okay</button>
    </div>
</div>