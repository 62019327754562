import {Component, OnInit} from '@angular/core';
import * as $ from 'jquery';
import {NavigationEnd, Router, ActivatedRoute} from '@angular/router';
import {filter} from 'rxjs/internal/operators';
import {HttpClient} from '@angular/common/http';
import {DataService, AuthenticationService} from '@app/_services';
import {apiUrls} from '@app/shared/app.constants';
import {environment} from '@environments/environment';
import {MessageService} from '../../_services/message.service';
import {HeaderService} from '@app/shared/header/header.service';
import {FormGroup, FormBuilder, FormControl, Validators, NgForm} from '@angular/forms';
import {GooglePlaceDirective} from 'ngx-google-places-autocomplete';
import { MustMatch } from '../../_helpers/must-match.validator';

@Component({
    selector: 'app-my-account',
    templateUrl: './my-account.component.html',
    styleUrls: ['./my-account.component.css']
})
export class MyAccountComponent implements OnInit {

    profileForm: FormGroup;
    passwordForm: FormGroup;
    passwordsubmitted: any;
    submitted: any;
    postalcodeValue: any;
    cityValue: any;
    countryValue: any;
    userdata: any;
    options = {
        types: [],
        componentRestrictions: {country: 'UK'}
    };

    constructor(private http: HttpClient,
                private route: ActivatedRoute,
                private dataService: DataService,
                private authService: AuthenticationService,
                private messageService: MessageService,
                private formBuilder: FormBuilder,
                private router: Router) {

    }

    ngOnInit(): void {
        this.profileForm = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            phone: ['', [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
            gender: [''],
            drivername: ['', Validators.required],
            driverdob: ['', Validators.required],
            d_licence_number: [''],
            d_licence_number_exp: [''],
            d_licence_issue: [''],
            address: ['', Validators.required],
            city: ['', Validators.required],
            country: ['', Validators.required],
            postalcode: ['', Validators.required],
            toggle: [],
            customCheck11: []
        });
        this.passwordForm = this.formBuilder.group({
            oldpassword: ['', Validators.required],
            password: ['', [Validators.required, Validators.minLength(6)]],
            confirmPassword: ['', Validators.required]
        }, {
            validator: MustMatch('password', 'confirmPassword')
        });
        if (!window.localStorage.getItem('token')) {
            this.router.navigate(['/login']);
        } else {
            this.getUser();
        }
    }

    getUser() {
        $('.loader').show();
        const subs = this.http.post<any>(`${environment.apiUrl}/${apiUrls.getUser}`,
            {
                'id': '',
            }).subscribe(res => {
            subs.unsubscribe();
            if (res) {
                $('.loader').hide();
                window.localStorage.setItem('userdata', JSON.stringify(res.user));
                this.profileForm.patchValue({
                    firstName: res.user.name,
                    lastName: res.user.last_name,
                    email: res.user.email,
                    phone: res.user.phone,
                    gender: res.user.gender,
                    toggle: res.user.iam_driver,
                    drivername: res.user.d_full_name,
                    driverdob: res.user.d_dob,
                    d_licence_number: res.user.d_licence_number,
                    d_licence_number_exp: res.user.d_licence_number_exp,
                    d_licence_issue: res.user.d_licence_issue,
                    customCheck11: res.user.get_alerts,
                    address: res.user.address,
                    city: res.user.city,
                    country: res.user.country,
                    postalcode: res.user.zip
                });
            }
        }, (err) => {
            $('.loader').hide();
        }, () => {
        });
    }

    onSubmit() {
        this.submitted = true;
        if (this.profileForm.invalid) {
            console.log(this.profileForm.errors);
            return;
        } else {
            $('.loader').show();
            const subs = this.http.post<any>(`${environment.apiUrl}/${apiUrls.profileUpdate}`,
                {
                    'name': this.f.firstName.value,
                    'last_name': this.f.lastName.value,
                    'email': this.f.email.value,
                    'phone': this.f.phone.value,
                    'gender': this.f.gender.value,
                    'iam_driver': this.f.toggle.value,
                    'd_full_name': this.f.drivername.value,
                    'd_dob': this.f.driverdob.value,
                    'd_licence_number': this.f.d_licence_number.value,
                    'd_licence_number_exp': this.f.d_licence_number_exp.value,
                    'd_licence_issue': this.f.d_licence_issue.value,
                    'get_alerts': this.f.customCheck11.value,
                    'address': this.f.address.value,
                    'city': this.f.city.value,
                    'country': this.f.country.value,
                    'zip': this.f.postalcode.value


                }).subscribe(res => {
                subs.unsubscribe();
                if (res) {
                    $('.loader').hide();
                    alert('Profile updated successfully');
                }
            }, (err) => {
                $('.loader').hide();
            }, () => {
            });
        }
    }

    get f() {
        return this.profileForm.controls;
    }

    get p() {
        return this.passwordForm.controls;
    }

    changepassword(){
        this.passwordsubmitted = true;
        if (this.passwordForm.invalid) {
            return;
        } else {
            $('.loader').show();
            const subs = this.http.post<any>(`${environment.apiUrl}/${apiUrls.changePassword}`,
                {
                    'oldpassword': this.p.oldpassword.value,
                    'newpassword': this.p.password.value
                }).subscribe(res => {
                subs.unsubscribe();
                if (res) {
                    $('.loader').hide();
                    $('#passworderror').html(res.message);
                    $('#passworderror').show();
                    $('#passworderror').delay(10000).fadeOut();
                    this.passwordForm.reset();
                }
            }, (err) => {
                $('.loader').hide();
            }, () => {
            });
        }
    }
    public handleAddressChange(address) {
        for (let p = address.address_components.length - 1; p >= 0; p--) {
            if (address.address_components[p].types.indexOf('postal_code') != -1) {
                this.postalcodeValue = address.address_components[p].long_name;
            }
            if (address.address_components[p].types.indexOf('postal_town') != -1) {
                this.cityValue = address.address_components[p].long_name;
            }
            if (address.address_components[p].types.indexOf('country') != -1) {
                this.countryValue = address.address_components[p].long_name;
            }
        }
        this.profileForm.patchValue({
            city: this.cityValue,
            postalcode: this.postalcodeValue,
            country: this.countryValue
        });
    }

    iamdriver() {
        if ($('#toggle').prop('checked') == true) {
            $('#drivername').val($('#firstName').val());
        } else {
            $('#drivername').val('');
        }
    }

    showTab(pageType: any) {
        if (pageType === 'profile') {
            $('#profiledive').show();
            $('#changepassworddiv').hide();
            $("#profiletab").addClass("active");
            $("#passwordtab").removeClass("active");
        } else {
            $('#profiledive').hide();
            $('#changepassworddiv').show();
            $("#passwordtab").addClass("active");
            $("#profiletab").removeClass("active");
        }
    }
}
