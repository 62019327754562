import { environment } from '../../environments/environment';

export const apiUrls = {
    'homeFilters': 'api/Search/home',
    'searchResults': 'api/Search/results',
    'searchView': 'api/Search/quoteView',
    'getCountriesInfo': 'api/Search/getCountriesInfo',
    'getCountries': 'api/Search/getCountries',
    'getTopCountries': 'api/Search/getTopCountries',
    'getCities': 'api/Search/getCities',
    'register': 'api/users/register',
    'login': 'api/users/token',
    'updateTraveller': 'api/Sale/updateTraveller',
    'sendQuoteEmail': 'api/Sale/sendQuoteEmail',
    'sendBooking': 'api/Sale/sendBooking',
    'getBookingsApi': 'api/Sale/bookings',
    'profileUpdate': 'api/users/updateProfile',
    'getUser': 'api/users/userDetails',
    'subscribe': 'api/Search/subscribe',
    'contactus': 'api/Search/contactus',
    'changePassword': 'api/users/changePassword',
    'forgotPassword': 'api/search/forgotPassword',
    'newPassword': 'api/search/newPassword',
    'activateAccount': 'api/users/activateAccount',
    'getFleetCountries': 'api/search/getFleetCountries',
    'getItenaries': 'api/search/getItenaries',
    'getItenariesList': 'api/search/getItenariesList',
    'getItenaryRoadtrips': 'api/search/getItenaryRoadtrips',
    'getFleets': 'api/search/getFleets',
    'getFleetsV2': 'api/search/getFleetsV2',
    'getTopItenariesList': 'api/search/getTopItenariesList',
    'sendQuoteEmailV2': 'api/Search/sendQuoteEmailV2',
    'getOrdersApi': 'api/Search/userOrders'
};
