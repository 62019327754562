import {Component, OnInit, ViewChild} from '@angular/core';
import * as $ from 'jquery';
import {NavigationEnd, Router, ActivatedRoute} from '@angular/router';
import {filter} from 'rxjs/internal/operators';
import {HttpClient} from '@angular/common/http';
import {DataService} from '@app/_services';
import {apiUrls} from '@app/shared/app.constants';
import {environment} from '@environments/environment';
import {FormGroup, FormBuilder, FormControl, Validators} from '@angular/forms';
import {PaymentsService} from '@app/proceed-payment/payments.service';
import {MustMatch} from '@app/_helpers/must-match.validator';
import {GooglePlaceDirective} from 'ngx-google-places-autocomplete';

declare var require: any;

@Component({
    selector: 'app-review-booking',
    templateUrl: './review-booking.component.html',
    styleUrls: ['./review-booking.component.css']
})

export class ReviewBookingComponent implements OnInit {
    @ViewChild('address') address: GooglePlaceDirective;
    userSelectedView: any;
    resultView: any;
    total: number;
    baseFare: number;
    prevTotal: number = 0;
    selectBoxTotal: number = 0;
    selectBoxArr: any = [];
    selectedCart = [];
    reviewForm: FormGroup;
    userCustomizations: string;
    messageService: any;
    saleID: any;
    quoteEmail: string;
    travellerDetails: any;
    travelerAddress: any;
    travellerClient: any;
    quoteId: any;
    optionsSelected: any;
    submitted: any;
    miniCart: any;
    checkItemExist: any;
    cityValue: any;
    regionValue: any;
    countryValue: any;
    postalcodeValue: any;
    miniCartData: any;
    userSearchSelected: any;
    options = {
        types: [],
        componentRestrictions: {country: 'UK'}
    };

    constructor(private http: HttpClient,
                private route: ActivatedRoute,
                private dataService: DataService,
                private paymentsService: PaymentsService,
                private formBuilder: FormBuilder,
                private router: Router) {
    }

    ngOnInit(): void {
        $.getScript('./assets/js/maps.js');
        this.getView();
        this.miniCartData = '';
        window.localStorage.removeItem('carttotal');
        window.localStorage.removeItem('miniCart');
        if (window.localStorage.getItem('userSearch')) {
            this.userSearchSelected = JSON.parse(window.localStorage.getItem('userSearch'));
        }
        /* if (window.localStorage.getItem('miniCart')) {
            this.miniCartData = JSON.parse(window.localStorage.getItem('miniCart'));
            this.total = Number(window.localStorage.getItem('carttotal'));
        } else {
            this.miniCartData = '';
            this.total = 0;
        }*/
    }

    bookingInfo() {
        window.localStorage.setItem('redirect', this.router.url);
        this.router.navigate(['/booking-info']);
    }

    addToCartCheckbox(e: any) {
        // console.log("key::", e.target.dataset.key)
        let key = e.target.dataset.key;
        let keyVal = e.target.dataset.keyvalue;
        let value = +e.target.value;
        // console.log("unchecked value:", e.target.value);
        if (e.target.checked) {
            //convert value from target to number bcz its a string 
            this.total = +e.target.value + this.total
            //for checkbox selection it's 1 always - bcz quantity
            this.selectedCart[key] = 1;
            if (window.localStorage.getItem('miniCart')) {
                const itemObj = {
                    itemKey: key,
                    itemLabel: keyVal,
                    itemQty: 1,
                    itemAmount: value
                };
                this.miniCart.push(itemObj);
            } else {
                this.miniCart = [{
                    itemKey: key,
                    itemLabel: keyVal,
                    itemQty: 1,
                    itemAmount: value
                }];
            }
        } else {
            //convert value from target to number bcz its a string 
            this.total = this.total - +e.target.value
            delete this.selectedCart[key];
            this.miniCart.forEach((item, index) => {
                if (item.itemKey === key) {
                    this.miniCart.splice(index, 1);
                }
            });
        }
        window.localStorage.setItem('userCustomizationsDetail', JSON.stringify(this.arrayToObject(this.selectedCart)));
        window.localStorage.setItem('miniCart', JSON.stringify(this.miniCart));

        window.localStorage.setItem('carttotal', this.total + '');
        this.miniCartData = JSON.parse(window.localStorage.getItem('miniCart'));
    }

    arrayToObject(arr: any) {
        var rv = {};
        for (var key in arr) {
            rv[key] = arr[key]
        }
        return rv;
    }

    updateQty(itemKey: any, itemCost: any, optType: any, keyValue: any) {
        if (optType === 'remove') {
            let qtyOgValue = $('.' + itemKey).val();
            let qtyValue = Number(qtyOgValue) - 1;
            if (qtyValue >= 0) {
                $('.' + itemKey).val(qtyValue);
                this.addToCartselectButton(itemKey, itemCost, qtyValue, keyValue);
            }
        } else {
            let qtyOgValue = $('.' + itemKey).val();
            let qtyValue = Number(qtyOgValue) + 1;
            if (qtyValue >= 1) {
                $('.' + itemKey).val(qtyValue);
                this.addToCartselectButton(itemKey, itemCost, qtyValue, keyValue);
            }
        }
    }

    addToCartselectButton(key: any, cost: any, value: any, keyValue: any) {
        if (value > 0) {
            this.selectedCart[key] = value;
            if (this.selectBoxArr[cost] !== undefined) {
                let prevSelectBoxTotal = (+this.selectBoxArr[cost]) * cost
                this.total = this.total - prevSelectBoxTotal;
                this.selectBoxArr[cost] = value;
                this.total = this.total + (value) * (+cost)
            } else {
                this.selectBoxTotal = (value) * (+cost)
                this.selectBoxArr[cost] = value
                this.total = this.total + this.selectBoxTotal
            }
            if (window.localStorage.getItem('miniCart')) {
                this.checkItemExist = 0;
                this.miniCart = JSON.parse(window.localStorage.getItem('miniCart'));
                this.miniCart.forEach((item, index) => {
                    if (item.itemKey === key) {
                        this.checkItemExist = 1;
                        item.itemAmount = cost;
                        item.itemQty = value;

                    }
                });
                if (this.checkItemExist === 0) {
                    const itemObj = {
                        itemKey: key,
                        itemLabel: keyValue,
                        itemQty: value,
                        itemAmount: cost
                    };
                    this.miniCart.push(itemObj);
                } else {
                    this.miniCart = [{
                        itemKey: key,
                        itemLabel: keyValue,
                        itemQty: value,
                        itemAmount: cost
                    }];
                }
            } else {
                this.miniCart = [{
                    itemKey: key,
                    itemLabel: keyValue,
                    itemQty: value,
                    itemAmount: cost
                }];
            }
        } else if (value == 0) {
            this.total = this.total - ((cost) * (+this.selectBoxArr[cost]))
            delete this.selectBoxArr[cost];
            delete this.selectedCart[key];
            this.miniCart.forEach((item, index) => {
                if (item.itemKey === key) {
                    this.miniCart.splice(index, 1);
                }
            });
        }
        window.localStorage.setItem('userCustomizationsDetail', JSON.stringify(this.arrayToObject(this.selectedCart)));
        window.localStorage.setItem('miniCart', JSON.stringify(this.miniCart));
        this.miniCartData = JSON.parse(window.localStorage.getItem('miniCart'));
    }

    addToCartselect(e: any) {
        console.log('key::', e.target.dataset.key)
        // console.log("value::", e.target.value)
        let cost = e.target.dataset.cost;
        let key = e.target.dataset.key;
        let label = e.target.dataset.keyvalue;
        let value = +e.target.value;
        if (value > 0) {

            this.selectedCart[key] = value;
            if (this.selectBoxArr[cost] !== undefined) {
                let prevSelectBoxTotal = (+this.selectBoxArr[cost]) * cost
                this.total = this.total - prevSelectBoxTotal;
                this.selectBoxArr[cost] = value;
                this.total = this.total + (value) * (+cost)
            } else {
                this.selectBoxTotal = (value) * (+cost)
                this.selectBoxArr[cost] = value
                this.total = this.total + this.selectBoxTotal
            }
            if (window.localStorage.getItem('miniCart')) {
                const itemObj = {
                    itemKey: key,
                    itemLabel: label,
                    itemQty: 1,
                    itemAmount: value
                };
                this.miniCart.push(itemObj);
            } else {
                this.miniCart = [{
                    itemKey: key,
                    itemLabel: label,
                    itemQty: 1,
                    itemAmount: value
                }];
            }
        } else if (value == 0) {
            this.total = this.total - ((cost) * (+this.selectBoxArr[cost]))
            delete this.selectBoxArr[cost];
            delete this.selectedCart[key];
            this.miniCart.forEach((item, index) => {
                if (item.itemKey === key) {
                    this.miniCart.splice(index, 1);
                }
            });
        }
        window.localStorage.setItem('userCustomizationsDetail', JSON.stringify(this.arrayToObject(this.selectedCart)));
        window.localStorage.setItem('miniCart', JSON.stringify(this.miniCart));
        // console.log("selectedCart::", this.selectedCart);
    }

    getView() {
        if (window.localStorage.getItem('userSearchDetail')) {
            $('.loader').show();
            this.userSelectedView = window.localStorage.getItem('userSearchDetail');
            const subs = this.http.get<any>(`${environment.apiUrl}/${apiUrls.searchView}?${this.userSelectedView}`).subscribe(res => {
                subs.unsubscribe();
                if (res) {
                    console.log(res);
                    this.resultView = res.response;
                    if (window.localStorage.getItem('carttotal')) {
                        this.total = Number(window.localStorage.getItem('carttotal'));
                    } else {
                        this.total = this.resultView.quote.model.components.base.salesPrice.value;
                    }
                    //this.resultView.quote.model.components.base.salesPrice.value;
                    this.prevTotal = this.total
                    this.baseFare = this.resultView.quote.model.components.base.salesPrice.value;
                    $.getScript('./assets/js/vehicleGallery.js');
                    $.getScript('./assets/js/slickcall.js');
                    //$.getScript('./assets/js/maps.js');
                    $('.loader').hide();
                }
            }, (err) => {
                $('.loader').hide();
            }, () => {
            });
        } else {
            this.router.navigate(['/search/results']);
        }

    }

    onKey(event) {
        this.quoteEmail = event.target.value;
    }

    sendQuote(quoteId: any) {
        $('.loader').show();
        this.optionsSelected = window.localStorage.getItem('userCustomizationsDetail');
        let firstname = $('#firstname').val();
        let lastname = $('#lastname').val();
        let uemail = $('#email').val();
        let uphone = $('#phone').val();
        let streetaddress = $('#streetaddress').val();
        let ucity = $('#city').val();
        let postalcode = $('#postalcode').val();
        let ucountry = $('#country').val();
        this.travellerClient = {
            gender: 'm',
            firstName: firstname,
            lastName: lastname
        };
        this.travelerAddress = {
            street: streetaddress,
            city: ucity,
            country: ucountry
        };
        this.travellerDetails = {
            driverAge: 32,
            passengers: 2,
            client: this.travellerClient,
            address: this.travelerAddress,
            email: uemail,
            phone: uphone,
            language: 'en',
            selected: this.optionsSelected
        };

        const subs = this.http.post<any>(`${environment.apiUrl}/${apiUrls.sendQuoteEmail}?quoteId=${quoteId}`, this.travellerDetails).subscribe(res => {
            subs.unsubscribe();
            if (res) {
                //this.sendBooking(quoteId);

            }
        }, (err) => {
            $('.loader').hide();
        }, () => {
        });
    }

    sendBooking(quoteId: any) {
        $('.loader').show();
        this.optionsSelected = window.localStorage.getItem('userCustomizationsDetail');
        let firstname = $('#firstname').val();
        let lastname = $('#lastname').val();
        let uemail = $('#email').val();
        let uphone = $('#phone').val();
        let streetaddress = $('#streetaddress').val();
        let ucity = $('#city').val();
        let postalcode = $('#postalcode').val();
        let ucountry = $('#country').val();
        this.travellerClient = {
            gender: 'm',
            firstName: firstname,
            lastName: lastname
        };
        this.travelerAddress = {
            street: streetaddress,
            city: ucity,
            country: ucountry
        };
        this.travellerDetails = {
            driverAge: 32,
            passengers: 2,
            client: this.travellerClient,
            address: this.travelerAddress,
            email: uemail,
            phone: uphone,
            language: 'en',
            selected: this.optionsSelected
        };

        const subs = this.http.post<any>(`${environment.apiUrl}/${apiUrls.sendBooking}?quoteId=${quoteId}`, this.travellerDetails).subscribe(res => {
            subs.unsubscribe();
            if (res) {
                this.router.navigate(['/payments/' + quoteId]);
            }
        }, (err) => {
            $('.loader').hide();
        }, () => {
        });
    }

    opentab(tabid: any, tabname: any) {
        $('#' + tabname + tabid + '').toggle();
        $('#dicon' + tabid + '').toggleClass("fa-minus");
        $('#' + tabname + tabid + '').parents('.vehicle-item').find('.vehicle-more-details-content').addClass('show');
    }

    closetab(tabid: any, tabname: any) {
        $('#' + tabname + tabid + '').toggle();
        $('#' + tabname + tabid + '').parents('.vehicle-item').find('.vehicle-more-details-content').removeClass('show');
    }
}
