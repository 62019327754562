import {Component, OnInit} from '@angular/core';
import * as $ from 'jquery';
import {NavigationEnd, Router, ActivatedRoute} from '@angular/router';
import {filter} from 'rxjs/internal/operators';
import {HttpClient} from '@angular/common/http';
import {DataService, AuthenticationService} from '@app/_services';
import {apiUrls} from '@app/shared/app.constants';
import {environment} from '@environments/environment';
import {MessageService} from '../../_services/message.service';
import {HeaderService} from '@app/shared/header/header.service';

@Component({
    selector: 'app-roadtrips',
    templateUrl: './roadtrips.component.html',
    styleUrls: ['./roadtrips.component.css']
})
export class RoadtripsComponent implements OnInit {

    itenary: any;
    roadtripsList: any;
    countryCode: any;
    selectedCountry: any;
    itenaryid: any;
    countryInfo: any;
    constructor(private http: HttpClient,
                private route: ActivatedRoute,
                private dataService: DataService,
                private authService: AuthenticationService,
                private messageService: MessageService,
                private router: Router) {
    }

    ngOnInit() {
        this.route.params.subscribe(routeParams => {
            this.countryCode = routeParams.destination;
            this.itenaryid = routeParams.itineraryid;
            this.getRoadtrips(this.countryCode, this.itenaryid);
        });
    }

    daytrip(tripid: any) {
        $('.itinerarie_tabs_content').hide();
        $('.daytabs').removeClass('active');
        $('#tripdiv_' + tripid + '').show();
        $('#triptab_' + tripid + '').addClass('active');
    }

    getRoadtrips(country: any, itenaryid: any) {
        $('.loader').show();
        const subs = this.http.post<any>(`${environment.apiUrl}/${apiUrls.getItenaryRoadtrips}`, {
            'country_id': country,
            'itenary_id': itenaryid
        }).subscribe(res => {
            subs.unsubscribe();
            if (res) {
                this.roadtripsList = res.roadtrips;
                this.itenary = res.itenary;
                this.selectedCountry = res.selectedCountry;
                this.countryInfo = res.countryInfo;
                $('.loader').hide();
            }
        }, (err) => {
            $('.loader').hide();
        }, () => {
        });
    }

    returnZero() {
        return 0;
    }
}
