<section class="account-wrap">
    <div class="mobile-sub-nav visible-sm visible-xs">
        <a href="#" class="go-back-btn"><img src="../assets/images/left-arrow-mobile.svg"></a>
        <h2>My Account</h2>
    </div>
    <div class="container">
        <div class="account-wrap-row">
            <div class="profile-details-column">
                <app-accountmenu></app-accountmenu>
            </div>
            <div class="account-content-column">
                <div class="account-content-main">
                    <div class="account-nav-tabs">
                        <ul>
                            <li><a id="profiletab" (click)="showTab('profile')" class="active">Edit Profile</a></li>
                            <li><a id="passwordtab" (click)="showTab('changepassword')">Change Password</a></li>
                        </ul>
                    </div>
                    <div id="profiledive" class="account-content-in">
                        <form class="form" #profile="ngForm" [formGroup]="profileForm" (ngSubmit)="onSubmit()">
                            <div class="account-form-fields">
                                <div class="row row-sm">
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <input class="form-control form-control-cust"
                                                   type="text" id="firstName"
                                                   formControlName="firstName"
                                                   placeholder="First Name *"
                                                   [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }">
                                            <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                                                <div *ngIf="f.firstName.errors.required">First Name Is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <input class="form-control form-control-cust"
                                                   type="text" id="lastName"
                                                   formControlName="lastName"
                                                   placeholder="Last Name *"
                                                   [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }">
                                            <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                                                <div *ngIf="f.lastName.errors.required">Last Name Is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <input class="form-control form-control-cust" type="email" id="email"
                                                   formControlName="email"
                                                   placeholder="Your Email *"
                                                   [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                <div *ngIf="f.email.errors.required">Email is required</div>
                                                <div *ngIf="f.email.errors.email">Email must be a valid email
                                                    address
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <input class="form-control form-control-cust" type="text" id="phone"
                                                   formControlName="phone"
                                                   placeholder="Your phone number *"
                                                   [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
                                            <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                                                <div *ngIf="f.phone.errors.required">phone number is required
                                                </div>
                                                <div *ngIf="f.phone.errors.pattern">Please, Enter 10 digit Mobile
                                                    Number.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="account-form-fields flex-row-cust">
                                <span class="checkbox-label">Gender?</span>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" formControlName="gender" value="male" id="Male" name="gender"
                                           checked class="custom-control-input">
                                    <label class="custom-control-label" for="Male">Male</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" formControlName="gender" value="female" id="Female"
                                           name="gender" class="custom-control-input">
                                    <label class="custom-control-label" for="Female">Female</label>
                                </div>
                            </div>

                            <div class="account-form-fields">
                                <div class="im-driving account-form-fields-head">
                                    <h3>I'm Driving</h3>
                                    <div class="switch-toggle right_toggle">
                                        <input type="checkbox" formControlName="toggle" id="toggle"
                                               (click)="iamdriver()"
                                               class="remove-from-screen">
                                        <label for="toggle" class="switch"></label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="singlerow">
                                        <div class="col-md-6 col-xs-12">
                                            <div class="form-field">
                                                <label>Driver full name<span class="red">*</span></label>

                                                <input class="form-control" type="text" id="drivername"
                                                       formControlName="drivername"
                                                       [ngClass]="{ 'is-invalid': submitted && f.drivername.errors }">
                                                <div *ngIf="submitted && f.drivername.errors"
                                                     class="invalid-feedback">
                                                    <div *ngIf="f.drivername.errors.required">Driver full name is
                                                        required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-xs-12">
                                            <div class="form-field">
                                                <label>Date of birth<span class="red">*</span></label>

                                                <input class="form-control" type="date" id="driverdob"
                                                       formControlName="driverdob"
                                                       [ngClass]="{ 'is-invalid': submitted && f.driverdob.errors }">
                                                <div *ngIf="submitted && f.driverdob.errors"
                                                     class="invalid-feedback">
                                                    <div *ngIf="f.driverdob.errors.required">Date of birth is
                                                        required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="singlerow">
                                        <div class="col-md-4 col-xs-12">
                                            <div class="form-field">
                                                <label>License number</label>
                                                <input type="text" formControlName="d_licence_number"
                                                       class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-xs-12">
                                            <div class="form-field">
                                                <label>Expiry date</label>
                                                <input type="date" formControlName="d_licence_number_exp"
                                                       class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-xs-12">
                                            <div class="form-field">
                                                <label>Country of issue</label>
                                                <input type="text" formControlName="d_licence_issue"
                                                       class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="account-form-fields">
                                <div class="account-form-fields-head">
                                    <h3>Address</h3>
                                </div>

                                <div class="row">
                                    <div class="singlerow">
                                        <div class="col-md-6 col-xs-12">

                                            <div class="form-field">
                                                <label>Your street address<span class="red">*</span></label>
                                                <input class="form-control" ngx-google-places-autocomplete
                                                       [options]='options' #address="ngx-places"
                                                       (onAddressChange)="handleAddressChange($event)"
                                                       formControlName="address" placeholder="Address"
                                                       [ngClass]="{ 'is-invalid': submitted && f.address.errors }">
                                                <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                                                    <div *ngIf="f.address.errors.required">Address Is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-xs-12">
                                            <label>City<span class="red">*</span></label>
                                            <input class="form-control" type="text" id="city" formControlName="city"
                                                   placeholder="City"
                                                   [ngClass]="{ 'is-invalid': submitted && f.city.errors }">
                                            <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                                                <div *ngIf="f.city.errors.required">City Is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="singlerow">
                                        <div class="col-md-6 col-xs-12">
                                            <label>Postcode<span class="red">*</span></label>
                                            <input class="form-control" type="text" id="postalcode"
                                                   formControlName="postalcode"
                                                   placeholder="postal code"
                                                   [ngClass]="{ 'is-invalid': submitted && f.postalcode.errors }">
                                            <div *ngIf="submitted && f.postalcode.errors" class="invalid-feedback">
                                                <div *ngIf="f.postalcode.errors.required">Postalcode Is required
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-md-6 col-xs-12">
                                            <label>Country<span class="red">*</span></label>
                                            <input class="form-control" type="text" id="country"
                                                   formControlName="country"
                                                   placeholder="Country"
                                                   [ngClass]="{ 'is-invalid': submitted && f.country.errors }">
                                            <div *ngIf="submitted && f.country.errors" class="invalid-feedback">
                                                <div *ngIf="f.country.errors.required">Country Is required</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="get-email-updates">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" formControlName="customCheck11" value="yes"
                                           class="custom-control-input" id="customCheck1123">
                                    <label class="custom-control-label" for="customCheck1123">Get Alerts on mobile & email
                                        for
                                        offers & discounts</label>
                                </div>
                            </div>

                            <div class="submit-btn-wrap">
                                <button type="submit" class="btn">Update</button>
                            </div>
                        </form>
                    </div>
                    <div style="display: none" id="changepassworddiv" class="account-content-in">
                        <form class="form" [formGroup]="passwordForm" (ngSubmit)="changepassword()">
                            <div id="passworderror"></div>
                        <div class="account-form-fields">
                            <div class="row row-sm">
                                <div class="col-12">
                                    <div class="form-group">
                                        <input type="password" placeholder="Old Password *" formControlName="oldpassword" class="form-control" [ngClass]="{ 'is-invalid': passwordsubmitted && p.oldpassword.errors }" />
                                        <div *ngIf="passwordsubmitted && p.oldpassword.errors" class="invalid-feedback">
                                            <div *ngIf="p.oldpassword.errors.required">Old password is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <input type="password" placeholder="New Password *" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': passwordsubmitted && p.password.errors }" />
                                        <div *ngIf="passwordsubmitted && p.password.errors" class="invalid-feedback">
                                            <div *ngIf="p.password.errors.required">Password is required</div>
                                            <div *ngIf="p.password.errors.minlength">Password must be at least 6 characters</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <input type="password" placeholder="Confirm Password *" formControlName="confirmPassword" class="form-control" [ngClass]="{ 'is-invalid': passwordsubmitted && p.confirmPassword.errors }" />
                                        <div *ngIf="passwordsubmitted && p.confirmPassword.errors" class="invalid-feedback">
                                            <div *ngIf="p.confirmPassword.errors.required">Confirm Password is required</div>
                                            <div *ngIf="p.confirmPassword.errors.mustMatch">Passwords must match</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 submit-btn-wrap">
                                    <button type="submit" class="btn">Change Password</button>
                                </div>
                            </div>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>
        