import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { RestfulApiService } from "../../_services/restful.service";

@Injectable({
    providedIn: "root"
})
export class HeaderService {
    constructor(private restful: RestfulApiService) { }
    getPassengers(): Observable<any> {
        return this.restful.sendRequest(
            `/api/Destinations/getPassengers`,
            "GET"
        );
    }
}
