import { Component, OnInit } from '@angular/core';
import {HeaderService} from '@app/shared/header/header.service';
import {MessageService} from '@app/_services/message.service';
import {HttpClient} from '@angular/common/http';
import {DataService} from '@app/_services';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '@environments/environment';
import {apiUrls} from '@app/shared/app.constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
    routerSubscription: any;
    countries: any;
    passengerList: {};
    constructor(private http: HttpClient,
                private route: ActivatedRoute,
                private dataService: DataService,
                private headerService: HeaderService,
                private messageService: MessageService,
                private router: Router) {
    }

    ngOnInit() {
        this.getCountries();
    }

    getCountries() {
        const subs = this.http.get<any>(`${environment.apiUrl}/${apiUrls.getTopCountries}`).subscribe(res => {
            subs.unsubscribe();
            if (res) {
                this.countries = res.countries;
            }
        }, (err) => {
        }, () => {
        });
    }


}
