<section id="main">
    <div class="banner">

        <div class="container">
            <div class="banner-wrap">
                <div class="journey-search-main">
                    <form [formGroup]="travelForm">
                        <div class="search-form-wrap">
                            <div class="search-field-country">
                                <label class="search-form-label">Country</label>
                                <div class="country_item">
                                    <select class="form-control" id="filterCountry" formControlName="filter_country" (change)="navigate($event.target.value)" title="Country" data-hide-disabled="true" data-actions-box="true">
                                        <option *ngFor="let country of countries | keyvalue"
                                                value="{{country.value.name}}">
                                            {{country.value.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="search-field-date">
                                <div class="field-fromto-date">
                                    <label class="search-form-label">Pick up date</label>
                                    <div class="input-group date">
                                        <input type="text" class="form-control field-fromto-date-control startDate" />
                                    </div>
                                </div>
                                <div class="form-calendar-icon">
                                    <div class="form-calendar-icon-img"><img src="./assets/images/calendar.svg" alt="calendar"></div>
                                </div>
                                <div class="field-fromto-date">
                                    <label class="search-form-label">Drop off date</label>
                                    <div class="input-group date">
                                        <input type="text" class="form-control field-fromto-date-control endDate" />
                                    </div>
                                </div>
                            </div>
                            <div class="search-field-pick-drop">
                                <div class="search-field-pick">
                                    <div class="pickup_slide dropup_slide">
                                        <label class="search-form-label">Use for Pick up & Drop</label>
                                        <div class="right_toggle">
                                            <input type="checkbox" id="toggle" class="remove-from-screen">
                                            <label for="toggle" class="switch"></label>
                                        </div>
                                    </div>
                                    <div class="select-box1" id="pickup_city_data">
                                        <select class="form-control" id="pickup_city" title="pickup" data-hide-disabled="true" data-actions-box="true">
                                            <option *ngFor="let city of cities | keyvalue"
                                                    value="{{city.key}}">
                                                {{city.value}}
                                            </option>
                                        </select>
                                    </div>
                                    <div id="dropoff_city_div">
                                        <div class="select-box1" id="dropoff_city_data">
                                            <select class="form-control" id="dropoff_city" title="dropoff" data-hide-disabled="true" data-actions-box="true">
                                                <option value="">Same as pickup</option>
                                                <option *ngFor="let city of cities | keyvalue"
                                                        value="{{city.key}}">
                                                    {{city.value}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="search-field-pass-age">
                                <div class="search-field-passengers">
                                    <label class="search-form-label search-form-label-drop">Passengers</label>
                                    <select class="form-control" id="filterPassengers" title="Passengers" data-hide-disabled="true" data-actions-box="true">
                                        <option *ngFor="let passenger of passengerList | keyvalue"
                                                value="{{passenger.key}}">
                                            {{passenger.value}}
                                        </option>
                                    </select>
                                </div>
                                <div class="search-field-age">
                                    <label class="search-form-label">Select Age</label>
                                    <input type="number" name="age" value="30" maxlength="2" class="form-control" id="filterAge">
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="journey-search-bottom">
                    <div class="search-form-submit">
                        <button class="btn" (click)="search()">Search <br>
                            <small>My MotorHome</small>
                        </button>
                    </div>
                    <div class="search-signup">
                        <h5>Sign Up & Get 10% Discount</h5>
                        <div class="signup-form">
                            <input type="email" name="email" placeholder="Enter email">
                            <button class="btn"><img src="./assets/images/right-arrow.svg" alt="right-arrow"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="banner-right-obj">
            <img src="./assets/images/caravan.png" alt="caravan">
        </div>
    </div>
    <div class="partner_slider">
    </div>
    <div class="deals">
        <div class="container">
            <div class="deals-wrap">
                <div class="deals-title">
                    <h3>Deals</h3>
                    <a href="#" class="more-deals">View More</a>
                </div>
                <div class="deal-slider">
                    <div class="deal-slider-js">
                        <section>
                            <div class="row">
                                <div class="col-lg-4 col-md-5 col-sm-6 deal-img">
                                    <img src="./assets/images/deal-1.jpg" alt="deal-1">
                                </div>
                                <div class="col-lg-4 col-md-5 col-sm-6">
                                    <div class="deal-content">
                                        <h3>Book Now, to Get Exciting Discount</h3>
                                        <p>Pre-book your holiday & get 20% Discount</p>
                                        <a href="#" class="btn">VIEW DEAL</a>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section>
                            <div class="row">
                                <div class="col-lg-4 col-md-5 col-sm-6 deal-img">
                                    <img src="./assets/images/deal-2.jpg" alt="deal-1">
                                </div>
                                <div class="col-lg-4 col-md-5 col-sm-6">
                                    <div class="deal-content">
                                        <h3>Book Now, to Get Exciting Discount</h3>
                                        <p>Pre-book your holiday & get 20% Discount</p>
                                        <a href="#" class="btn">VIEW DEAL</a>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section>
                            <div class="row">
                                <div class="col-lg-4 col-md-5 col-sm-6 deal-img">
                                    <img src="./assets/images/deal-1.jpg" alt="deal-1">
                                </div>
                                <div class="col-lg-4 col-md-5 col-sm-6">
                                    <div class="deal-content">
                                        <h3>Book Now, to Get Exciting Discount</h3>
                                        <p>Pre-book your holiday & get 20% Discount</p>
                                        <a href="#" class="btn">VIEW DEAL</a>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- popular destinations -->
    <div class="popular_destinations">
        <div class="container">
            <h3>Popular Destinations</h3>
            <ul class="destinations_list row">
                <li class="col-lg-3 col-md-3 col-sm-6 col-xs-6" *ngFor="let result of countryData.popularDestinations">
                    <div class="destination_item">
                        <a href="#">
                            <figure><img src="{{result.banner}}"></figure>
                            <span>{{result.name}}</span>
                        </a>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <!-- Destinations Content -->
    <div class="destination_content">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                    <ul class="tabs_1 nav-tabs">
                        <li class="active"><a data-toggle="tab" href="#destination_info">{{destination}} info</a></li>
                        <li><a data-toggle="tab" href="#destination_faq">FAQs</a></li>
                    </ul>
                    <div id="destination_info" class="tab_content tab-pane fade active in">
                        <div [innerHTML]="info"></div>
                    </div>
                    <div id="destination_faq" class="tab_content tab-pane fade">
                        <h3>FAQ's</h3>
                        <div [innerHTML]="faq"></div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div class="our_motorhome_fleet">
                        <h3>Our Motorhome fleet</h3>
                        <ul class="row">
                            <li class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <a href="#">
                                    <figure><img src="./assets/images/motorhome-img1.jpg"></figure>
                                </a>
                            </li>
                            <li class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <a href="#">
                                    <figure><img src="./assets/images/motorhome-img2.jpg"></figure>
                                </a>
                            </li>
                            <li class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <a href="#">
                                    <figure><img src="./assets/images/motorhome-img3.jpg"></figure>
                                </a>
                            </li>
                            <li class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <a href="#">
                                    <figure><img src="./assets/images/motorhome-img4.jpg"></figure>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>