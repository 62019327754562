import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Router, ActivatedRoute, RoutesRecognized, ParamMap} from '@angular/router';
import {environment} from '@environments/environment';
import {User} from '@app/_models';
import {apiUrls} from '@app/shared/app.constants';
import {skip} from 'rxjs/operators';

@Injectable({providedIn: 'root'})

export class AuthenticationService {

    public currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    private loggedIn = new BehaviorSubject<boolean>(false);
    windowTabId: any;
    sessionId: any;

    constructor(public http: HttpClient,
                public router: Router,
                public Activatedroute: ActivatedRoute) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('token')));
        this.currentUser = this.currentUserSubject.asObservable();
    }
    public get isLoggedIn() {
        return this.loggedIn.asObservable(); // {2}
      }
    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(username: string, password: string) {
        return this.http.post<any>(`${environment.apiUrl}/${apiUrls.login}`, {'username': username, 'password': password})
            .pipe(map(user => {
                if (user) {
                    this.loggedIn.next(true);
                    this.currentUserSubject.next(user);
                    location.reload();
                }
                return user;
            }));
    }

    logout() {
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('user');
        window.localStorage.removeItem('userdata');
        this.loggedIn.next(false);
        //this.currentUserSubject.next('');
        this.router.navigate(['/'])
            .then(() => {
                window.location.reload();
            });
        //this.router.navigate(['/']);

    }
}