import {Component, OnInit} from '@angular/core';
import * as $ from 'jquery';
import {NavigationEnd, Router, ActivatedRoute} from '@angular/router';
import {filter} from 'rxjs/internal/operators';
import {HttpClient} from '@angular/common/http';
import {DataService, AuthenticationService} from '@app/_services';
import {apiUrls} from '@app/shared/app.constants';
import {environment} from '@environments/environment';
import {MessageService} from '../_services/message.service';
import {HeaderService} from '@app/shared/header/header.service';


@Component({
    selector: 'app-fleet-details',
    templateUrl: './fleet-details.component.html',
    styleUrls: ['./fleet-details.component.css']
})
export class FleetDetailsComponent implements OnInit {
    fleetCountries: any;
    fleets: any;
    countryCode: any;
    selectedCountry: any;
    fleetsList: any;
    constructor(private http: HttpClient,
                private route: ActivatedRoute,
                private dataService: DataService,
                private authService: AuthenticationService,
                private messageService: MessageService,
                private router: Router) {
    }

    ngOnInit() {
        this.getFleetCountries();
        this.route.params.subscribe(routeParams => {
            this.countryCode = routeParams.countryCode;
            //this.getFleets(this.countryCode);
            this.getFleetsv2(this.countryCode);
        });

        $.getScript('./assets/js/nouislider.js');
        //$.getScript('./assets/js/filters.js');
        //$.getScript('./assets/js/vehicleGallery.js');
        //$.getScript('./assets/js/slickcall.js');
    }

    getFleetCountries() {
        $('.loader').show();
        const subs = this.http.get<any>(`${environment.apiUrl}/${apiUrls.getFleetCountries}`).subscribe(res => {
            subs.unsubscribe();
            if (res) {
                this.fleetCountries = res.countries;
                $('.loader').hide();
            }
        }, (err) => {
            $('.loader').hide();
        }, () => {
        });
    }

    getFleets(country: any) {
        $('.loader').show();
        const subs = this.http.post<any>(`${environment.apiUrl}/${apiUrls.getFleets}`, {
            'country_id': country
        }).subscribe(res => {
            subs.unsubscribe();
            if (res) {
                this.fleets = res.fleetsList;
                this.selectedCountry = res.selectedCountry;
                $('.loader').hide();
            }
        }, (err) => {
            $('.loader').hide();
        }, () => {
        });
    }
    getFleetsv2(country: any) {
        $('.loader').show();
        const subs = this.http.post<any>(`${environment.apiUrl}/${apiUrls.getFleetsV2}`, {
            'country_id': country
        }).subscribe(res => {
            subs.unsubscribe();
            if (res) {

                this.fleetsList = res.fleetsList;
                this.selectedCountry = res.selectedCountry;
                $('.loader').hide();
                $.getScript('./assets/js/vehicleGallery.js');
                $.getScript('./assets/js/slickcall.js');
            }
        }, (err) => {
            $('.loader').hide();
        }, () => {
        });
    }
    showmore(){
        $('.showall').show();
        $('.showless').show();
        $('.showmore').hide();
    }
    showless(){
        $('.showall').hide();
        $('.showless').hide();
        $('.showmore').show();
    }
}
