import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class RestfulApiService {
    public BASEURI: string;

    constructor(protected http: HttpClient) {
        this.BASEURI = environment.apiUrl;
    }
    /**
     * Builds Base url
     * @param uri string
     * @returns base url
     */
    buildRESTURL(uri: string) {
        return `${this.BASEURI}${uri}`;
    }

    /**
     * Sends request to a api. All the request are happened here
     * @param uri string
     * @param requestType GET, POST, PUT , DELETE
     * @param [body] data to be posted
     * @returns response
     */
    sendRequest(uri: string, requestType: string, body?: any): Observable<any> {
        switch (requestType) {
            case "GET": {
                return this.http.get(this.buildRESTURL(uri), body);
                break;
            }
            case "POST": {
                return this.http.post(this.buildRESTURL(uri), body);
                break;
            }
            case "PUT": {
                return this.http.put(this.buildRESTURL(uri), body);
                break;
            }
            case "DELETE": {
                return this.http.delete(this.buildRESTURL(uri), body);
                break;
            }
            default: {
                return this.http.get(this.buildRESTURL(uri), body);
                break;
            }
        }
    }

    /**
     * This methods used for downloading data as text format
     * @param uri  string
     * @returns binary data
     */
    downloadExcel(uri: string): Observable<any> {
        return this.http.get(this.buildRESTURL(uri), {
            responseType: "text",
            observe: "response",
            reportProgress: true
        });
    }

    /**
     * Sorts column
     * @param uri string
     * @param options header
     * @returns sorted data of the selected grid
     */
    sortColumn(uri: string, options: any): Observable<any> {
        return this.http.get(this.buildRESTURL(uri), { headers: options });
    }

    /**
     * This methods used for downloading data as zip format
     * @param uri  string
     * @returns binary data
     */
    downloadZip(uri: string): Observable<any> {
        return this.http.get(this.buildRESTURL(uri), {
            responseType: "arraybuffer",
            observe: "response",
            reportProgress: true
        });
    }
}
