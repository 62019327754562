import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/internal/operators';
import { HttpClient } from '@angular/common/http';
import { DataService,AuthenticationService } from '@app/_services';
import { apiUrls } from '@app/shared/app.constants';
import { environment } from '@environments/environment';
import { HeaderService } from './header.service';
import { MessageService } from '../../_services/message.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
    routerSubscription: any;
    countries: any;
    passengerList: {};
    userDetails: any;
    constructor(private http: HttpClient,
        private route: ActivatedRoute,
        private dataService: DataService,
        private authService: AuthenticationService,
        private headerService: HeaderService,
        private messageService: MessageService,
        private router: Router) {
    }

    ngOnInit() {
        this.getCountries();
        this.getNoOfPassengers();
        if (window.localStorage.getItem('token')) {
            //this.userDetails = window.localStorage.getItem('token');
            this.userDetails = JSON.parse(window.localStorage.getItem('userdata'));
        }else{
            this.userDetails = false;
        }
    }

    getCountries() {
        const subs = this.http.get<any>(`${environment.apiUrl}/${apiUrls.getCountries}`).subscribe(res => {
            subs.unsubscribe();
            if (res) {
                this.countries = res.countries;
            }
        }, (err) => {
        }, () => {
        });
    }

    getNoOfPassengers() {
        this.headerService.getPassengers().subscribe(
            result => {
                this.passengerList = result.response
                //console.log(result);
            },
            error => {
                //console.log(error)
                this.messageService.showErrorMessage(error);
            }
        );
    }
    logout(){
        window.localStorage.setItem('reload', '1');
        this.authService.logout();
    }
    openlogin(){
        $('.login-signup2').show();
    }

}
