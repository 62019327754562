import {Component, OnInit} from '@angular/core';
import * as $ from 'jquery';
import {NavigationEnd, Router, ActivatedRoute} from '@angular/router';
import {filter} from 'rxjs/internal/operators';
import {HttpClient} from '@angular/common/http';
import {DataService, AuthenticationService} from '@app/_services';
import {apiUrls} from '@app/shared/app.constants';
import {environment} from '@environments/environment';
import {MessageService} from '../../_services/message.service';
import {HeaderService} from '@app/shared/header/header.service';

@Component({
  selector: 'app-my-bookings',
  templateUrl: './my-bookings.component.html',
  styleUrls: ['./my-bookings.component.css']
})
export class MyBookingsComponent implements OnInit {
    userEmail: any;
    bookingsList: any;
    constructor(private http: HttpClient,
                private route: ActivatedRoute,
                private dataService: DataService,
                private authService: AuthenticationService,
                private messageService: MessageService,
                private router: Router) {
    }

    ngOnInit(): void {
        if (!window.localStorage.getItem('token')) {
            this.router.navigate(['/login']);
        }else{
            this.getBookings();
        }
    }

    getBookingsbck() {
        if (window.localStorage.getItem('token')) {
            $('.loader').show();
            const subs = this.http.get<any>(`${environment.apiUrl}/${apiUrls.getBookingsApi}`).subscribe(res => {
                subs.unsubscribe();
                if (res) {
                    this.bookingsList = res.response.quote.data;
                    $('.loader').hide();
                }
            }, (err) => {
                $('.loader').hide();
            }, () => {
            });
        } else {
            this.router.navigate(['/login']);
        }

    }
    getBookings() {
        if (window.localStorage.getItem('token')) {
            $('.loader').show();
            const subs = this.http.get<any>(`${environment.apiUrl}/${apiUrls.getOrdersApi}`).subscribe(res => {
                subs.unsubscribe();
                if (res) {
                    this.bookingsList = res.response;
                    $.getScript('./assets/js/vehicleGallery.js');
                    $.getScript('./assets/js/slickcall.js');
                    $('.loader').hide();
                }
            }, (err) => {
                $('.loader').hide();
            }, () => {
            });
        } else {
            this.router.navigate(['/login']);
        }

    }
    opentab(tabid: any, tabname: any) {
        $('#' + tabname + tabid + '').toggle();
        $('#dicon' + tabid + '').toggleClass('fa-minus');
        $('#' + tabname + tabid + '').parents('.vehicle-item').find('.vehicle-more-details-content').addClass('show');
    }

    closetab(tabid: any, tabname: any) {
        $('#' + tabname + tabid + '').toggle();
        $('#' + tabname + tabid + '').parents('.vehicle-item').find('.vehicle-more-details-content').removeClass('show');
    }
}
