<section id="main">
    <div class="itineraries_banner">
        <div class="container">
            <div class="itineraries_cont">
                <h2><span>Welcome to </span> Affordable Motorhome Holidays Destinations</h2>
            </div>
        </div>
    </div>

    <div class="itineraries_list fleetList">
        <div class="container">
            <h3>Destinations</h3>
            <p>The world really is your oyster with Affordable Motorhome Holidays. Whether you plan to embark on some of the world’s best known highways and byways, or aim to get off the beaten track, we have a range of destination profiles and sample itineraries to help you plan your next roadtrip.</p>
        </div>
    </div>
    <div class="destinationsList">
        <div class="container">
            <div class="destinationsListin itlist">
                <ul>
                    <li *ngFor="let countryItenary of itenaryCountries">
                        <div class="dLogo">
                            <img src="{{countryItenary.itenary.image}}" alt="d-canada">
                        </div>
                        <div class="itblock">
                            <div class="countryHead">
                                <span>{{countryItenary.country.name}}</span>
                                <span class="flag flag-{{countryItenary.country.country_code}} flag-24"></span>
                            </div>
                            <p [innerHTML]="truncateChar(countryItenary.itenary.short_description)"></p>
                            <h4>Top Itinerary</h4>
                            <ol>
                                <li>
                                    {{countryItenary.itenary.title}}
                                </li>
                            </ol>
                        </div>
                        <a [routerLinkActive]="['active']" routerLink="/destinations/{{countryItenary.country.name}}">view Itineraries</a>
                    </li>

                </ul>
            </div>
        </div>
    </div>

</section>
<!-- /main -->