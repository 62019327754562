<section id="main">
    <div class="banner">
        <div class="container">
            <div class="banner-wrap">
                <div class="journey-search-main">
                    <form [formGroup]="travelForm">
                        <div class="search-form-wrap">
                            <div class="search-field-country">
                                <label class="search-form-label">Country</label>
                                <div class="country_item">
                                    <select class="form-control" id="filterCountry" formControlName="filter_country" title="Country" data-hide-disabled="true" (change)="navigate($event.target.value)" data-actions-box="true">
                                        <option *ngFor="let country of countries | keyvalue : returnZero; index as i; let odd=odd;"
                                                value="{{country.value}}" [attr.data-country]="country.key">
                                            {{country.value}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="search-field-date">
                                <div class="field-fromto-date">
                                    <label class="search-form-label">Pick up date</label>
                                    <div class="input-group date">
                                        <input type="text" id="startDate" class="form-control field-fromto-date-control startDate" />
                                    </div>
                                </div>
                                <div class="form-calendar-icon">
                                    <div class="form-calendar-icon-img"><img src="./assets/images/calendar.svg" alt="calendar"></div>
                                </div>
                                <div class="field-fromto-date">
                                    <label class="search-form-label">Drop off date</label>
                                    <div class="input-group date">
                                        <input type="text" id="endDate" class="form-control field-fromto-date-control endDate" />
                                    </div>
                                </div>
                            </div>
                            <div class="search-field-pick-drop">
                                <div class="search-field-pick">
                                    <div class="pickup_slide dropup_slide">
                                        <label class="search-form-label">Use for Pick up & Drop</label>
                                        <div class="right_toggle">
                                            <input type="checkbox" name="dropoffcheck" (click)="changeToggle()" id="toggle" checked class="remove-from-screen dropoffcheck">
                                            <label for="toggle" class="switch"></label>
                                        </div>
                                    </div>
                                    <div class="select-box1" id="pickup_city_data">
                                        <select class="form-control" id="pickup_city" title="pickup" data-hide-disabled="true" data-actions-box="true">
                                            <option *ngFor="let city of cities | keyvalue"
                                                    value="{{city.key}}">
                                                {{city.value}}
                                            </option>
                                        </select>
                                    </div>
                                    <div id="dropoff_city_div">
                                        <div class="select-box1" id="dropoff_city_data">
                                            <select class="form-control" id="dropoff_city" title="dropoff" data-hide-disabled="true" data-actions-box="true">
                                                <option value="">Same as pickup</option>
                                                <option *ngFor="let city of cities | keyvalue"
                                                        value="{{city.key}}">
                                                    {{city.value}}
                                                </option>
                                            </select>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                            <div class="search-field-pass-age">
                                <div class="search-field-passengers">
                                    <label class="search-form-label search-form-label-drop">Passengers</label>
                                    <div class="passengers-no">
                                        <span class="s-text">Sleeps</span>
                                        <select class="form-control" id="filterPassengers" title="Passengers" data-hide-disabled="true" data-actions-box="true">
                                            <option *ngFor="let passenger of passengerList | keyvalue"
                                                    value="{{passenger.key}}">
                                                {{passenger.value}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="search-field-age">
                                    <label class="search-form-label">Select Age</label>
                                    <input type="number" name="age" value="30" maxlength="2" class="form-control" id="filterAge">
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="journey-search-bottom">
                    <div class="search-form-submit">
                        <button class="btn" (click)="search($event)">Search <br>
                            <small>My MotorHome</small>
                        </button>
                    </div>
                    <div class="search-signup">
                        <h5>Sign up receive our newsletter and offers</h5>
                        <div class="signup-form">
                            <input type="email" value="" name="email" id="newslettersignup" placeholder="Enter email">
                            <button class="btn" (click)="subscribe('newslettersignup')"><img src="./assets/images/right-arrow.svg" alt="right-arrow"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="banner-right-obj">
            <img src="./assets/images/caravan.png" alt="caravan">
        </div>
    </div>
    <div class="partner_slider">
    </div>
    <!-- <div class="deals">
        <div class="container">
            <div class="deals-wrap">
                <div class="deals-title">
                    <h3>Deals</h3>
                    <a [routerLinkActive]="['active']" routerLink="/deals" class="more-deals">View More</a>
                </div>
                <div class="deal-slider">
                    <div class="deal-slider-js">
                        <section>
                            <div class="row">
                                <div class="col-lg-4 col-md-5 col-sm-6 deal-img">
                                    <img src="./assets/images/deal-1.jpg" alt="deal-1">
                                </div>
                                <div class="col-lg-4 col-md-5 col-sm-6">
                                    <div class="deal-content">
                                        <h3>Book Now, to Get Exciting Discount</h3>
                                        <p>Pre-book your holiday & get 20% Discount</p>
                                        <a href="#" class="btn">VIEW DEAL</a>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section>
                            <div class="row">
                                <div class="col-lg-4 col-md-5 col-sm-6 deal-img">
                                    <img src="./assets/images/deal-2.jpg" alt="deal-1">
                                </div>
                                <div class="col-lg-4 col-md-5 col-sm-6">
                                    <div class="deal-content">
                                        <h3>Book Now, to Get Exciting Discount</h3>
                                        <p>Pre-book your holiday & get 20% Discount</p>
                                        <a href="#" class="btn">VIEW DEAL</a>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section>
                            <div class="row">
                                <div class="col-lg-4 col-md-5 col-sm-6 deal-img">
                                    <img src="./assets/images/deal-1.jpg" alt="deal-1">
                                </div>
                                <div class="col-lg-4 col-md-5 col-sm-6">
                                    <div class="deal-content">
                                        <h3>Book Now, to Get Exciting Discount</h3>
                                        <p>Pre-book your holiday & get 20% Discount</p>
                                        <a href="#" class="btn">VIEW DEAL</a>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="we_offer">
        <div class="container">
            <h3>Why Choose Us</h3>
            <div class="we_offer_in">
                <ul>
                    <li>
                        <img src="./assets/images/hand.svg" alt="hand">
                        <h5>Simple Pricing</h5>
                    </li>
                    <li>
                        <img src="./assets/images/money.svg" alt="money">
                        <h5>No hidden extras</h5>
                    </li>
                    <li>
                        <img src="./assets/images/taxi-driver.svg" alt="taxi-driver">
                        <h5>Driver cover</h5>
                    </li>

                </ul>
            </div>
        </div>
    </div>

    <div class="popular-destinations">
        <div class="container">
            <div class="popular-dstn-wrap deals-wrap">
                <div class="deals-title">
                <h3>Popular Destinations</h3>
                <a [routerLinkActive]="['active']" routerLink="/destinations" class="more-deals">View All</a>
                </div>
                <div class="destinationsList">
                    <div class="container">
                        <div class="destinationsListin itlist">
                            <div class="popular-slider">
                                <div class="popular-slider-js">
                                    <section *ngFor="let countryItenary of itenaryCountries">
                                        <a [routerLinkActive]="['active']" routerLink="/destinations/{{countryItenary.country.name}}" class="popular-item">
                                            <figure><img src="{{countryItenary.itenary.image}}" alt="image">
                                                <figcaption>{{countryItenary.country.name}}</figcaption>
                                            </figure>
                                        </a>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="popular-slider">
                    <div class="popular-slider-js">
                        <section *ngFor="let result of countryData.popularDestinations">
                            <a href="javascript:void(0)" class="popular-item">
                                <figure><img src="{{result.banner}}" alt="image">
                                    <figcaption>{{result.name}}</figcaption>
                                </figure>
                            </a>
                        </section>
                    </div>
                </div> -->
            </div>
        </div>
    </div>

    <!-- Sign Up & Get 10% Discount -->
    <section class="discount-signup">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="search-signup">
                        <h5>SIGN UP RECEIVE OUR NEWSLETTER AND OFFERS</h5>
                        <div class="signup-form">
                            <input type="email" name="email" id="newslettersignup2" placeholder="Enter email">
                            <button (click)="subscribe('newslettersignup2')" class="btn"><img src="./assets/images/right-arrow.svg" alt="right-arrow"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Our Motorhome fleet -->

    <div class="motorhome-block">
        <div class="container">
            <h3>Our Motorhome fleet</h3>
            <div class="motorhome-slider">
                <div class="motorhome-slide">
                    <figure><img src="./assets/images/motorhome-img1.jpg"></figure>
                </div>
                <div class="motorhome-slide">
                    <figure><img src="./assets/images/motorhome-img2.jpg"></figure>
                </div>
                <div class="motorhome-slide">
                    <figure><img src="./assets/images/motorhome-img3.jpg"></figure>
                </div>
                <div class="motorhome-slide">
                    <figure><img src="./assets/images/motorhome-img4.jpg"></figure>
                </div>
                <div class="motorhome-slide">
                    <figure><img src="./assets/images/motorhome-img1.jpg"></figure>
                </div>
                <div class="motorhome-slide">
                    <figure><img src="./assets/images/motorhome-img2.jpg"></figure>
                </div>
                <div class="motorhome-slide">
                    <figure><img src="./assets/images/motorhome-img3.jpg"></figure>
                </div>
            </div>
            <a routerLink="/fleet" class="viewall-link">View all</a>
        </div>
    </div>

    <!-- AboutUs -->
    <!-- <div class="about_us">
        <div class="container">
            <h2>Hire in the UK through us?</h2>
            <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-12">
                    <figure><img src="https://via.placeholder.com/400x280/efefef/bebebe%20?Text=IMAGE" alt="image">
                    </figure>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-12">
                    <div class="content_about">

                        <p>Support – we’ll be here to support you at all stages of your Motorhome rental in the UK, RV Rental in the USA & Canada and wherever else you decide to enjoy your motorhome hire holiday Experience – our team have X years combined
                            experience in arranging Motorhome Rental in the UK, RV rentals in the USA & Canada and motorhome holidays around the world. Real-Time Pricing – our easy to use search function allows you to choose the motorhome that is right
                            for you, and the price you see is the price you pay. Choice – our partners offer a wide range of Motorhomes and RV vehicles. From 2 berth campervans to luxury 6 berth motorhomes and larger, we can offer the best selection in
                            “real-time”
                        </p>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="about_us">
        <div class="container">
            <h2>ABOUT US</h2>
            <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-12">
                    <figure><img src="https://via.placeholder.com/400x280/efefef/bebebe%20?Text=IMAGE" alt="image">
                    </figure>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-12">
                    <div class="content_about">
                        <p>
                            There’s nothing quite like the call of the open road. The freedom to explore a new region at your own pace, travelling in your own hotel on wheels. Whether your dream RV trip is Route 66 in the USA, a Motorhome Safari in Africa, a campervan trip of Ireland’s
                            country roads or attending a Music Festival in a motorhome, we’ve got you covered and will support you every step of the way. <br>We work with some of the largest motorhome companies in the world,
                            meaning you get the best offers available. <br> Motorhomes, RV’s and Campervans come in numerous shapes, sizes and configurations. Our easy guide will help you select the most sensible option for your needs – taking the hassle
                            out of your holiday booking. Whether you are looking for a basic 2-berth campervan rental, a luxury motorhome break or an RV Rental for your next holiday, our guide to the vehicles will help you choose which motorhome suits
                            your requirements. <br> We work with some of the biggest motorhome rental companies in UK, USA, Canada and Australia. CruiseAmerica, Just Go, Apollo add more in here
                        </p>
                    </div>

                </div>
            </div>
        </div>
    </div> -->

    <div class="our_partner">
        <div class="container">
            <div class="our_partner_in">
                <h3>Our partners</h3>
                <div class="partner_slider">
                    <div class="partner_sld_sng">
                        <img src="./assets/images/partner-1.jpg" alt="partner-1">
                    </div>
                    <div class="partner_sld_sng">
                        <img src="./assets/images/partner-2.jpg" alt="partner-2">
                    </div>
                    <div class="partner_sld_sng">
                        <img src="./assets/images/partner-3.jpg" alt="partner-3">
                    </div>
                    <div class="partner_sld_sng">
                        <img src="./assets/images/partner-4.jpg" alt="partner-4">
                    </div>
                    <div class="partner_sld_sng">
                        <img src="./assets/images/partner-5.jpg" alt="partner-5">
                    </div>
                    <div class="partner_sld_sng">
                        <img src="./assets/images/partner-6.jpg" alt="partner-6">
                    </div>
                    <div class="partner_sld_sng">
                        <img src="./assets/images/partner-7.jpg" alt="partner-7">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="layer" style="display: none"></div>
<div class="alert" style="display: none">
    <h2 id="alerttitle">Alert !!!</h2>
    <button class="btn alert-close" (click)="closeAlert()"><img src="assets/images/close.svg"></button>
    <p id="alertContent"></p>
    <div class="alertbtns" style="margin-top: 15px">
        <button id="applyCoupon" class="red" (click)="closeAlert()">Okay</button>
        <button id="loginbutton" style="display: none" class="red" (click)="openlogin()">Okay</button>
    </div>
</div>