import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { HomeComponent } from './home/home.component';
import { AppRoutingModule } from './app-routing.module';
import { DestinationsComponent } from './destinations/destinations.component';
import { DealsComponent } from './deals/deals.component';
import { ListingComponent } from './itineraries/listing/listing.component';
import { DetailsComponent } from './itineraries/details/details.component';
import { DetailsOverviewComponent } from './itineraries/details-overview/details-overview.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ToastrModule } from "ngx-toastr";
import { LoginComponent } from './user/login/login.component';
import { MyAccountComponent } from './user/my-account/my-account.component';
import { MyBookingsComponent } from './user/my-bookings/my-bookings.component';
import { MyFavoritesComponent } from './user/my-favorites/my-favorites.component';
import { MyReviewsComponent } from './user/my-reviews/my-reviews.component';
import { ResultsComponent } from './search/results/results.component';
import { ReviewBookingComponent } from './review-booking/review-booking.component';
import { BookingSuccessComponent } from './booking-success/booking-success.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { ProceedPaymentComponent } from './proceed-payment/proceed-payment.component';
import { RegisterComponent } from './user/register/register.component';
import { DashboardmenuComponent } from './shared/dashboardmenu/dashboardmenu.component';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { BookingInfoComponent } from './booking-info/booking-info.component';
import { StripePaymentComponent } from './stripe-payment/stripe-payment.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { GdprStatementComponent} from '@app/pages/gdpr-statement/gdpr-statement.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { VehiclesComponent } from './vehicles/vehicles.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ForgotPasswordComponent } from './user/forgot-password/forgot-password.component';
import { RetrievePasswordComponent } from './user/retrieve-password/retrieve-password.component';
import { ActivateAccountComponent } from './user/activate-account/activate-account.component';
import { FleetDetailsComponent } from './fleet-details/fleet-details.component';
import { RoadtripsComponent } from './itineraries/roadtrips/roadtrips.component';
import { PopuploginComponent } from './shared/popuplogin/popuplogin.component';
import { AccountmenuComponent } from './shared/accountmenu/accountmenu.component';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        HomeComponent,
        DestinationsComponent,
        DealsComponent,
        ListingComponent,
        DetailsComponent,
        DetailsOverviewComponent,
        LoginComponent,
        MyAccountComponent,
        MyBookingsComponent,
        MyFavoritesComponent,
        MyReviewsComponent,
        ResultsComponent,
        ReviewBookingComponent,
        BookingSuccessComponent,
        NotfoundComponent,
        ProceedPaymentComponent,
        RegisterComponent,
        DashboardmenuComponent,
        BookingInfoComponent,
        StripePaymentComponent,
        TermsAndConditionsComponent,
        GdprStatementComponent,
        PrivacyPolicyComponent,
        ContactUsComponent,
        VehiclesComponent,
        AboutUsComponent,
        ForgotPasswordComponent,
        RetrievePasswordComponent,
        ActivateAccountComponent,
        FleetDetailsComponent,
        RoadtripsComponent,
        PopuploginComponent,
        AccountmenuComponent
    ],
    imports: [
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        GooglePlaceModule,
        BrowserModule,
        HttpClientModule,
        ToastrModule.forRoot({ timeOut: 10000 }),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        BrowserAnimationsModule,

    ],
    providers: [{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }],
    bootstrap: [AppComponent]
})
export class AppModule {
}
