import {Component, OnInit} from '@angular/core';
import * as $ from 'jquery';
import {NavigationEnd, Router, ActivatedRoute} from '@angular/router';
import {filter} from 'rxjs/internal/operators';
import {HttpClient} from '@angular/common/http';
import {DataService, AuthenticationService} from '@app/_services';
import {apiUrls} from '@app/shared/app.constants';
import {environment} from '@environments/environment';
import {MessageService} from '../../_services/message.service';
import {HeaderService} from '@app/shared/header/header.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DestinationService} from '@app/destinations/destinations.service';

@Component({
    selector: 'app-dashboardmenu',
    templateUrl: './dashboardmenu.component.html',
    styleUrls: ['./dashboardmenu.component.css']
})
export class DashboardmenuComponent implements OnInit {
    homeResponse: any;
    countries: {};
    passengerList: {};
    cities: {};
    public travelForm: FormGroup;
    countryData: any;
    destination: any;
    filterCountryValue: any;
    startDate: any;
    endDate: any;
    pickupCity: any;
    dropoffCity: any;
    filterPassengers: any;
    filterAge: any;
    dropoff_city: any;
    pickup_city: any;
    no_of_passengers: any;
    driverAge: any;
    formsubmitted: any;
    alertMessage: any;
    routerValue: any;
    showMobileSearch: boolean = true;
    constructor(private http: HttpClient,
                private fb: FormBuilder,
                private route: ActivatedRoute,
                private dataService: DataService,
                private authService: AuthenticationService,
                private messageService: MessageService,
                private headerService: HeaderService,
                private destinationService: DestinationService,
                private router: Router) {

    }

    ngOnInit() {
        //console.log(this.router.url);
        this.routerValue = this.router.url;
        $.getScript('./assets/js/datepicker.js');
        this.getFilters();
        this.getNoOfPassengers();
        this.travelForm = new FormGroup({
            filter_country: new FormControl('', [Validators.required]),
            pickup_city: new FormControl('', [Validators.required]),
            dropoff_city: new FormControl(''),
            filterPassengers: new FormControl('', [Validators.required]),
            driverAge: new FormControl('', [Validators.required]),
        });

        //this.travelForm.controls['filter_country'].setValue('United Kingdom');

        this.updateFilters();
    }

    logout() {
        this.authService.logout();
    }

    updateFilters() {
        let usersearchdata: any = JSON.parse(window.localStorage.getItem('userSearch'));
        //this.dropoff_city = usersearchdata.dropoff_city;
        //this.pickup_city = usersearchdata.pickup_city;
        this.startDate = usersearchdata.pickup_date;
        this.endDate = usersearchdata.dropoff_date;
        this.no_of_passengers = usersearchdata.no_of_passengers;
        this.driverAge = usersearchdata.driver_age;
        if (usersearchdata.countryName) {
            this.navigate(usersearchdata.countryName);
        } else {
            this.travelForm.controls['filter_country'].setValue('United Kingdom');
            this.navigate('United Kingdom');
        }

        this.travelForm.controls['filter_country'].setValue(usersearchdata.countryName);
        this.travelForm.controls['dropoff_city'].setValue(usersearchdata.dropoff_city);
        this.travelForm.controls['pickup_city'].setValue(usersearchdata.pickup_city);
        this.travelForm.controls['filterPassengers'].setValue(usersearchdata.no_of_passengers);
        this.travelForm.controls['driverAge'].setValue(usersearchdata.driver_age);
    }

    getFilters() {
        $('.loader').show();
        const subs = this.http.get<any>(`${environment.apiUrl}/${apiUrls.homeFilters}`).subscribe(res => {
            subs.unsubscribe();
            if (res) {
                this.homeResponse = res.response;
                if (this.homeResponse.countries) {
                    this.dataService.saveCountries(this.homeResponse.countries);
                    this.countries = this.homeResponse.countries
                }
                if (this.homeResponse.cities) {
                    this.cities = this.homeResponse.cities;
                }
                $('.loader').hide();
                //this.filterCountryValue = 46;
                //this.updateFilters();
            }
        }, (err) => {
            $('.loader').hide();
        }, () => {
            $('.loader').hide();
        });
    }

    showSearch(){
this.showMobileSearch = false;
    }

    getNoOfPassengers() {
        this.headerService.getPassengers().subscribe(
            result => {
                this.passengerList = result.response
                //console.log(result);
                //this.updateFilters();

            },
            error => {
                //console.log(error)
                this.messageService.showErrorMessage(error);
            }
        );
        //this.updateFilters();
    }

    navigate(destination: string) {
        this.getCities(destination);
        //this.router.navigate(['/destinations/' + `${destination}`]);
    }

    returnZero() {
        return 0;
    }

    changeToggle() {
        $('#dropoff_city_div').toggle();
    }

    getCities(destination: string) {
        $('.loader').show();
        this.destinationService.getCitiesByDestination(destination).subscribe(
            result => {
                this.cities = result.response
                //console.log(result.response);
                $('.loader').hide();
                //this.updateFilters();
            },
            error => {
                $('.loader').hide();
                //console.log(error)
                this.messageService.showErrorMessage(error);
            }
        );
    }

    getCountriesData(destination: any) {
        $('.loader').show();
        this.destinationService.getCountryInfo(destination).subscribe(
            result => {
                this.countryData = result;
                $('.loader').hide();
            },
            error => {
                $('.loader').hide();
                this.messageService.showErrorMessage(error);
            }
        );
    }

    search(event) {
        this.formsubmitted = true;
        if (this.travelForm.invalid) {
            alert('All fields are mandatory');
            return;
        } else {
            this.filterCountryValue = this.dataService.getKeyByValue(window.localStorage.getItem('countries'), $('#filterCountry').val());
            this.startDate = $('#startDate').val();
            this.endDate = $('#endDate').val();
            this.pickupCity = $('#pickup_city').val();
            this.dropoffCity = $('#dropoff_city').val();
            this.filterPassengers = $('#filterPassengers').val();
            this.filterAge = $('#filterAge').val();
            if (this.filterAge <= 29) {
                alert('Age should be greater than 30');
            } else {
                const userSearchObj = {
                    country: this.filterCountryValue,
                    countryName: $('#filterCountry').val(),
                    pickup_date: this.startDate,
                    dropoff_date: this.endDate,
                    dropoffcheck: 'on',
                    pickup_city: this.pickupCity,
                    dropoff_city: this.dropoffCity,
                    no_of_passengers: this.filterPassengers,
                    driver_age: this.filterAge

                };
                this.dataService.saveUserSearch(userSearchObj);
                if (this.router.url === '/search/results') {
                    location.reload();
                } else {
                    this.router.navigate(['/search/results']);
                }
            }
        }
    }

    subscribe(formid: any) {
        //$('.loader').show();
        const emailVal = $('#' + formid + '').val();
        const pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i
        if (!pattern.test(emailVal)) {
            //alert('not a valid e-mail address');
            this.alertMessage = 'Invalid e-mail address';
            this.showAlert();
        }​else
        {
            $('.loader').show();
            const subs = this.http.post<any>(`${environment.apiUrl}/${apiUrls.subscribe}`,
                {
                    email: emailVal
                }).subscribe(res => {
                subs.unsubscribe();
                if (res) {
                    $('.loader').hide();
                    this.alertMessage = res.message;
                    this.showAlert();
                }
            }, (err) => {
                $('.loader').hide();
            }, () => {
                $('.loader').hide();
            });
        }

    }

    showAlert() {
        $('.layer').show();
        $('.alert').show();
        $('#alerttitle').html('Alert !!!');
        $('#alertContent').html(this.alertMessage);
    }

    closeAlert() {
        $('.layer').hide();
        $('.alert').hide();
        $('#alerttitle').html('');
        $('#alertContent').html('');
    }
}
