import {Component, OnInit} from '@angular/core';
import * as $ from 'jquery';
import {NavigationEnd, Router, ActivatedRoute} from '@angular/router';
import {filter} from 'rxjs/internal/operators';
import {HttpClient} from '@angular/common/http';
import {DataService, AuthenticationService} from '@app/_services';
import {apiUrls} from '@app/shared/app.constants';
import {environment} from '@environments/environment';
import {MessageService} from '../_services/message.service';
import {HeaderService} from '@app/shared/header/header.service';


@Component({
    selector: 'app-vehicles',
    templateUrl: './vehicles.component.html',
    styleUrls: ['./vehicles.component.css']
})
export class VehiclesComponent implements OnInit {

    fleetCountries: any;

    constructor(private http: HttpClient,
                private route: ActivatedRoute,
                private dataService: DataService,
                private authService: AuthenticationService,
                private messageService: MessageService,
                private router: Router) {
    }

    ngOnInit() {
        this.getFleetCountries();
    }

    getFleetCountries() {
        $('.loader').show();
        const subs = this.http.get<any>(`${environment.apiUrl}/${apiUrls.getFleetCountries}`).subscribe(res => {
            subs.unsubscribe();
            if (res) {
                this.fleetCountries = res.countries;
                $('.loader').hide();
            }
        }, (err) => {
            $('.loader').hide();
        }, () => {
        });
    }
}
