import {
    Component, OnInit, ViewChild, AfterViewInit,
    OnDestroy, ElementRef, ChangeDetectorRef
} from '@angular/core';
import * as $ from 'jquery';
import {NavigationEnd, Router, ActivatedRoute} from '@angular/router';
import {filter} from 'rxjs/internal/operators';
import {HttpClient} from '@angular/common/http';
import {DataService} from '@app/_services';
import {apiUrls} from '@app/shared/app.constants';
import {environment} from '@environments/environment';
import {FormGroup, FormBuilder, FormControl, Validators, NgForm} from '@angular/forms';
import {PaymentsService} from '@app/proceed-payment/payments.service';
import {MustMatch} from '@app/_helpers/must-match.validator';
import {GooglePlaceDirective} from 'ngx-google-places-autocomplete';
import {AngularStripeService} from '@fireflysemantics/angular-stripe-service';
import {AgeValidator} from '@app/_helpers';

declare var require: any;

@Component({
    selector: 'app-booking-info',
    templateUrl: './booking-info.component.html',
    styleUrls: ['./booking-info.component.css']
})
export class BookingInfoComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('cardInfo', {static: false}) cardInfo: ElementRef;

    stripe;
    loading = false;
    confirmation;

    card: any;
    cardHandler = this.onChange.bind(this);
    error: string;

    @ViewChild('address') address: GooglePlaceDirective;
    userSelectedView: any;
    resultView: any;
    total: number;
    baseFare: number;
    prevTotal: number = 0;
    selectBoxTotal: number = 0;
    selectBoxArr: any = [];
    selectedCart = [];
    formProcess: any;
    message: any;
    reviewForm: FormGroup;
    userCustomizations: any;
    messageService: any;
    saleID: any;
    quoteEmail: string;
    travellerDetails: any;
    travelerAddress: any;
    travellerClient: any;
    quoteId: any;
    optionsSelected: any;
    bookingForm: FormGroup;
    submitted: any;
    miniCart: any;
    checkItemExist: any;
    cityValue: any;
    regionValue: any;
    countryValue: any;
    postalcodeValue: any;
    miniCartData: any;
    userSearchSelected: any;
    quoteDetails: any;
    alertMessage: any;
    bookingformdata: any;
    isMobile: boolean = false;
    options = {
        types: [],
        componentRestrictions: {country: 'UK'}
    };
    handler: any = null;


    constructor(private http: HttpClient,
                private route: ActivatedRoute,
                private dataService: DataService,
                private paymentsService: PaymentsService,
                private formBuilder: FormBuilder,
                private router: Router,
                private cd: ChangeDetectorRef,
                private stripeService: AngularStripeService) {
    }

    ngOnInit(): void {
        $.getScript('./assets/js/maps.js');
        this.getView();
        if (!window.localStorage.getItem('token')) {

        } else {
            this.getUser();
        }
        this.bookingForm = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastname: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            mobile: ['', [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
            address: ['', Validators.required],
            city: ['', Validators.required],
            postalcode: ['', Validators.required],
            country: ['', Validators.required],
            drivername: ['', Validators.required],
            driverdob: ['', Validators.required],
            drivername2: [],
            driverdob2: [],
            dlicencenumber: [],
            licenseexpirydate: [],
            d_licence_issue: []
        });
        if (window.localStorage.getItem('userSearch')) {
            this.userSearchSelected = JSON.parse(window.localStorage.getItem('userSearch'));
        }
        if (window.localStorage.getItem('miniCart')) {
            this.miniCartData = JSON.parse(window.localStorage.getItem('miniCart'));
            this.total = Number(window.localStorage.getItem('carttotal'));

        } else {
            this.miniCartData = '';
        }

        //this.loadStripe();
        if ($(window).width() <991) {
           this.isMobile = true;
           console.log("Mobile ", this.isMobile);
        }
        else {
            this.isMobile = false;
        }
        console.log(this.isMobile);
    }


    ngAfterViewInit() {
        this.stripeService.setPublishableKey('pk_test_51IBMBkFGsbvXqn8Lunj6ZdEvqGkgiCl8AYCSnfw1aflJfeqLv2wxFzBNZoqrbuY1l1jJA8TAsvU0AHzhVmPpQaSS00OXSRPwFh').then(
            stripe => {
                this.stripe = stripe;
                const elements = stripe.elements();
                this.card = elements.create('card');
                this.card.mount(this.cardInfo.nativeElement);
                this.card.addEventListener('change', this.cardHandler);
            });
    }

    ngOnDestroy() {
        this.card.removeEventListener('change', this.cardHandler);
        this.card.destroy();
    }

    onChange({error}) {
        if (error) {
            this.error = error.message;
        } else {
            this.error = null;
        }
        this.cd.detectChanges();
    }

    getUser() {
        $('.loader').show();
        const subs = this.http.post<any>(`${environment.apiUrl}/${apiUrls.getUser}`,
            {
                'id': '',
            }).subscribe(res => {
            subs.unsubscribe();
            if (res) {
                $('.loader').hide();
                window.localStorage.setItem('userdata', JSON.stringify(res.user));
                this.bookingForm.patchValue({
                    firstName: res.user.name,
                    lastname: res.user.last_name,
                    email: res.user.email,
                    mobile: res.user.phone,
                    address: res.user.address,
                    city: res.user.city,
                    country: res.user.country,
                    postalcode: res.user.zip,
                    drivername: res.user.d_full_name,
                    driverdob: res.user.d_dob,
                    dlicencenumber: res.user.d_licence_number,
                    licenseexpirydate: res.user.d_licence_number_exp,
                    d_licence_issue: res.user.d_licence_issue
                });
            }
        }, (err) => {
            $('.loader').hide();
        }, () => {
        });
    }

    proceesToPayment(token: any, bookingData: any) {
        /* if (!window.localStorage.getItem('token')) {
            window.localStorage.setItem('redirect', this.router.url);
            this.router.navigate(['/login']);
        } else { */
        var check = $('#termschecbox').prop('checked');
        if (check) {
            this.userCustomizations = {
                quoteInfo: JSON.parse(window.localStorage.getItem('miniCart')),
                userSelectedView: window.localStorage.getItem('userSearchDetail'),
                bookingUserInfo: bookingData,
                vehicleDetails: this.resultView
            };
            console.log(this.userCustomizations);
            /* if (this.userCustomizations = window.localStorage.getItem('userCustomizationsDetail')) {

             } else {
                 this.userCustomizations = '';
             } */
            if (window.localStorage.getItem('userSearchDetail')) {
                $('.loader').show();
                this.userSelectedView = window.localStorage.getItem('userSearchDetail');
                this.paymentsService.createCart(this.userSelectedView, this.userCustomizations).subscribe(
                    result => {
                        this.saleID = result.response.uuid;
                        this.quoteId = result.response.code;
                        console.log('result::', result.response.uuid);
                        window.localStorage.setItem('responseid',result.response.uuid);
                        $('.loader').hide();
                        this.updateTravellereDetails(this.saleID, this.quoteId, token);
                    },
                    error => {
                        $('.loader').hide();
                        console.log(error)
                        this.messageService.showErrorMessage(error);
                    }
                );
            } else {
                $('.loader').hide();
                $('#alertmsg').html('Oops!! some thing went wrong');
                return false;
            }
        } else {
            $('.loader').hide();
            $('#alertmsg').html('Please accept terms and conditions');
            return false;
        }
        // }

    }

    updateTravellereDetails(saleID: any, quoteId: any, token: any) {
        $('.loader').show();
        let firstname = $('#firstName').val();
        let lastname = $('#lastname').val();
        let uemail = $('#email').val();
        let uphone = $('#mobile').val();
        let streetaddress = $('#streetaddress').val();
        let ucity = $('#city').val();
        let postalcode = $('#postalcode').val();
        let ucountry = $('#country').val();
        this.travellerClient = {
            gender: 'm',
            firstName: firstname,
            lastName: lastname
        };
        this.travelerAddress = {
            street: streetaddress,
            city: ucity,
            country: ucountry
        };
        this.travellerDetails = {
            driverAge: 32,
            passengers: 2,
            client: this.travellerClient,
            address: this.travelerAddress,
            email: uemail,
            phone: uphone,
            language: 'en'
        };

        const subs = this.http.post<any>(`${environment.apiUrl}/${apiUrls.updateTraveller}?quoteId=${this.quoteId}`, this.travellerDetails).subscribe(res => {
            subs.unsubscribe();
            if (res) {
                this.sendBooking(quoteId, token);
                //this.pay(this.total, quoteId);

            }
        }, (err) => {
            $('.loader').hide();
        }, () => {
        });


    }

    checkterms() {
        var check = $('#termschecbox').prop('checked');
        if (check) {
            $('#alertmsg').html('');
        }
    }

    // checktermsMobile(){
    //     var check = $('#termschecboxMobile').prop('checked');
    //     if (check) {
    //         $('#alertmsg').html('');
    //     } 
    // }

    addToCartCheckbox(e: any) {
        // console.log("key::", e.target.dataset.key)
        let key = e.target.dataset.key;
        let keyVal = e.target.dataset.keyvalue;
        let value = +e.target.value;
        // console.log("unchecked value:", e.target.value);
        if (e.target.checked) {
            //convert value from target to number bcz its a string
            this.total = +e.target.value + this.total
            //for checkbox selection it's 1 always - bcz quantity
            this.selectedCart[key] = 1;
            if (window.localStorage.getItem('miniCart')) {
                const itemObj = {
                    itemKey: key,
                    itemLabel: keyVal,
                    itemQty: 1,
                    itemAmount: value
                };
                this.miniCart.push(itemObj);
            } else {
                this.miniCart = [{
                    itemKey: key,
                    itemLabel: keyVal,
                    itemQty: 1,
                    itemAmount: value
                }];
            }
        } else {
            //convert value from target to number bcz its a string
            this.total = this.total - +e.target.value
            delete this.selectedCart[key];
            this.miniCart.forEach((item, index) => {
                if (item.itemKey === key) {
                    this.miniCart.splice(index, 1);
                }
            });
        }
        window.localStorage.setItem('userCustomizationsDetail', JSON.stringify(this.arrayToObject(this.selectedCart)));
        window.localStorage.setItem('miniCart', JSON.stringify(this.miniCart));
        this.miniCartData = JSON.parse(window.localStorage.getItem('miniCart'));
    }

    arrayToObject(arr: any) {
        var rv = {};
        for (var key in arr) {
            rv[key] = arr[key]
        }
        return rv;
    }

    updateQty(itemKey: any, itemCost: any, optType: any, keyValue: any) {
        if (optType === 'remove') {
            let qtyOgValue = $('.' + itemKey).val();
            let qtyValue = Number(qtyOgValue) - 1;
            if (qtyValue >= 0) {
                $('.' + itemKey).val(qtyValue);
                this.addToCartselectButton(itemKey, itemCost, qtyValue, keyValue);
            }
        } else {
            let qtyOgValue = $('.' + itemKey).val();
            let qtyValue = Number(qtyOgValue) + 1;
            if (qtyValue >= 1) {
                $('.' + itemKey).val(qtyValue);
                this.addToCartselectButton(itemKey, itemCost, qtyValue, keyValue);
            }
        }
    }

    addToCartselectButton(key: any, cost: any, value: any, keyValue: any) {
        if (value > 0) {
            this.selectedCart[key] = value;
            if (this.selectBoxArr[cost] !== undefined) {
                let prevSelectBoxTotal = (+this.selectBoxArr[cost]) * cost
                this.total = this.total - prevSelectBoxTotal;
                this.selectBoxArr[cost] = value;
                this.total = this.total + (value) * (+cost)
            } else {
                this.selectBoxTotal = (value) * (+cost)
                this.selectBoxArr[cost] = value
                this.total = this.total + this.selectBoxTotal
            }
            if (window.localStorage.getItem('miniCart')) {
                this.checkItemExist = 0;
                this.miniCart = JSON.parse(window.localStorage.getItem('miniCart'));
                this.miniCart.forEach((item, index) => {
                    if (item.itemKey === key) {
                        this.checkItemExist = 1;
                        item.itemAmount = cost;
                        item.itemQty = value;

                    }
                });
                if (this.checkItemExist === 0) {
                    const itemObj = {
                        itemKey: key,
                        itemLabel: keyValue,
                        itemQty: value,
                        itemAmount: cost
                    };
                    this.miniCart.push(itemObj);
                } else {
                    this.miniCart = [{
                        itemKey: key,
                        itemLabel: keyValue,
                        itemQty: value,
                        itemAmount: cost
                    }];
                }
            } else {
                this.miniCart = [{
                    itemKey: key,
                    itemLabel: keyValue,
                    itemQty: value,
                    itemAmount: cost
                }];
            }
        } else if (value == 0) {
            this.total = this.total - ((cost) * (+this.selectBoxArr[cost]))
            delete this.selectBoxArr[cost];
            delete this.selectedCart[key];
            this.miniCart.forEach((item, index) => {
                if (item.itemKey === key) {
                    this.miniCart.splice(index, 1);
                }
            });
        }
        window.localStorage.setItem('userCustomizationsDetail', JSON.stringify(this.arrayToObject(this.selectedCart)));
        window.localStorage.setItem('miniCart', JSON.stringify(this.miniCart));
        window.localStorage.setItem('carttotal', this.total + '');
        this.miniCartData = JSON.parse(window.localStorage.getItem('miniCart'));
    }

    addToCartselect(e: any) {
        console.log('key::', e.target.dataset.key)
        // console.log("value::", e.target.value)
        let cost = e.target.dataset.cost;
        let key = e.target.dataset.key;
        let label = e.target.dataset.keyvalue;
        let value = +e.target.value;
        if (value > 0) {

            this.selectedCart[key] = value;
            if (this.selectBoxArr[cost] !== undefined) {
                let prevSelectBoxTotal = (+this.selectBoxArr[cost]) * cost
                this.total = this.total - prevSelectBoxTotal;
                this.selectBoxArr[cost] = value;
                this.total = this.total + (value) * (+cost)
            } else {
                this.selectBoxTotal = (value) * (+cost)
                this.selectBoxArr[cost] = value
                this.total = this.total + this.selectBoxTotal
            }
            if (window.localStorage.getItem('miniCart')) {
                const itemObj = {
                    itemKey: key,
                    itemLabel: label,
                    itemQty: 1,
                    itemAmount: value
                };
                this.miniCart.push(itemObj);
            } else {
                this.miniCart = [{
                    itemKey: key,
                    itemLabel: label,
                    itemQty: 1,
                    itemAmount: value
                }];
            }
        } else if (value == 0) {
            this.total = this.total - ((cost) * (+this.selectBoxArr[cost]))
            delete this.selectBoxArr[cost];
            delete this.selectedCart[key];
            this.miniCart.forEach((item, index) => {
                if (item.itemKey === key) {
                    this.miniCart.splice(index, 1);
                }
            });
        }
        window.localStorage.setItem('userCustomizationsDetail', JSON.stringify(this.arrayToObject(this.selectedCart)));
        window.localStorage.setItem('miniCart', JSON.stringify(this.miniCart));
        // console.log("selectedCart::", this.selectedCart);
    }

    getView() {
        if (window.localStorage.getItem('userSearchDetail')) {
            $('.loader').show();
            this.userSelectedView = window.localStorage.getItem('userSearchDetail');
            const subs = this.http.get<any>(`${environment.apiUrl}/${apiUrls.searchView}?${this.userSelectedView}`).subscribe(res => {
                subs.unsubscribe();
                if (res) {
                    this.resultView = res.response;
                    window.localStorage.setItem('quoteView', JSON.stringify(this.resultView));
                    if (window.localStorage.getItem('carttotal')) {
                        this.total = Number(window.localStorage.getItem('carttotal'));
                    } else {
                        this.total = this.resultView.quote.model.components.base.salesPrice.value;
                    }
                    //this.total = Number(window.localStorage.getItem('carttotal')); //this.resultView.quote.model.components.base.salesPrice.value;
                    this.prevTotal = this.total
                    this.baseFare = this.resultView.quote.model.components.base.salesPrice.value;
                    $.getScript('./assets/js/vehicleGallery.js');
                    $.getScript('./assets/js/slickcall.js');
                    //$.getScript('./assets/js/maps.js');
                    $('.loader').hide();
                }
            }, (err) => {
                $('.loader').hide();
            }, () => {
            });
        } else {
            this.router.navigate(['/search/results']);
        }

    }

    onKey(event) {
        this.quoteEmail = event.target.value;
    }

    sendQuote(quoteId: any) {
        $('.loader').show();
        this.optionsSelected = window.localStorage.getItem('userCustomizationsDetail');
        let firstname = $('#firstname').val();
        let lastname = $('#lastname').val();
        let uemail = $('#email').val();
        let uphone = $('#phone').val();
        let streetaddress = $('#streetaddress').val();
        let ucity = $('#city').val();
        let postalcode = $('#postalcode').val();
        let ucountry = $('#country').val();
        this.travellerClient = {
            gender: 'm',
            firstName: firstname,
            lastName: lastname
        };
        this.travelerAddress = {
            street: streetaddress,
            city: ucity,
            country: ucountry
        };
        this.travellerDetails = {
            driverAge: 32,
            passengers: 2,
            client: this.travellerClient,
            address: this.travelerAddress,
            email: uemail,
            phone: uphone,
            language: 'en',
            selected: this.optionsSelected
        };

        const subs = this.http.post<any>(`${environment.apiUrl}/${apiUrls.sendQuoteEmail}?quoteId=${quoteId}`, this.travellerDetails).subscribe(res => {
            subs.unsubscribe();
            if (res) {
                //this.sendBooking(quoteId);

            }
        }, (err) => {
            $('.loader').hide();
        }, () => {
        });
    }

    sendBooking(quoteId: any, token: any) {
        $('.loader').show();
        this.optionsSelected = window.localStorage.getItem('userCustomizationsDetail');
        let firstname = $('#firstname').val();
        let lastname = $('#lastname').val();
        let uemail = $('#email').val();
        let uphone = $('#phone').val();
        let streetaddress = $('#streetaddress').val();
        let ucity = $('#city').val();
        let postalcode = $('#postalcode').val();
        let ucountry = $('#country').val();
        this.travellerClient = {
            gender: 'm',
            firstName: firstname,
            lastName: lastname
        };
        this.travelerAddress = {
            street: streetaddress,
            city: ucity,
            country: ucountry
        };
        this.travellerDetails = {
            driverAge: 32,
            passengers: 2,
            client: this.travellerClient,
            address: this.travelerAddress,
            email: uemail,
            phone: uphone,
            language: 'en',
            selected: this.optionsSelected,
            paymentinfo: token,
            quoteInfo: JSON.parse(window.localStorage.getItem('miniCart')),
            userSelectedView: window.localStorage.getItem('userSearchDetail'),
            amount: this.total
        };

        const subs = this.http.post<any>(`${environment.apiUrl}/${apiUrls.sendBooking}?quoteId=${quoteId}`, this.travellerDetails).subscribe(res => {
            subs.unsubscribe();
            if (res) {
                $('.loader').hide();
                this.router.navigate(['/payment/success']);
            }
        }, (err) => {
            $('.loader').hide();
        }, () => {
        });
    }

    get f() {
        return this.bookingForm.controls;
    }


    async onSubmit(checkout: any) {
        //console.log($('#licenseexpirydate').val());


        this.submitted = true;
        // stop here if form is invalid
        if (this.bookingForm.invalid) {
            console.log(this.bookingForm.errors);
            return;
        } else {
            $('#alertmsg').html('');
            const driverage = $('#driverage').val();
            if (driverage <= 29) {
                alert('Minimum driver age is 30 years');
            }/* else if($('#licenseexpirydate').val()){
                var selectedText = $('#licenseexpirydate').val();
                var selectedDate = new Date(selectedText);
                var now = new Date();
                if (selectedDate < now) {
                    alert("Please enter valid license expiry date"+selectedDate);
                }
            } */ else {
                const {token, error} = await this.stripe.createToken(this.card);
                if (error) {
                    $('#alertmsg').html('Please enter payment details');
                } else {
                    $('#alertmsg').html('');

                    this.bookingformdata = {
                        firstName: this.f.firstName.value,
                        lastname: this.f.lastname.value,
                        email: this.f.email.value,
                        mobile: this.f.mobile.value,
                        address: this.f.address.value,
                        city: this.f.city.value,
                        postalcode: this.f.postalcode.value,
                        country: this.f.country.value,
                        drivername: this.f.drivername.value,
                        driverdob: this.f.driverdob.value,
                        drivername2: this.f.drivername2.value,
                        driverdob2: this.f.driverdob2.value,
                        dlicencenumber: this.f.dlicencenumber.value,
                        licenseexpirydate: this.f.licenseexpirydate.value,
                        d_licence_issue: this.f.d_licence_issue.value
                    };

                    this.proceesToPayment(token, this.bookingformdata);
                }
            }
        }
    }

    public handleAddressChange(address) {
        for (let p = address.address_components.length - 1; p >= 0; p--) {
            if (address.address_components[p].types.indexOf('postal_code') != -1) {
                this.postalcodeValue = address.address_components[p].long_name;
            }
            if (address.address_components[p].types.indexOf('postal_town') != -1) {
                this.cityValue = address.address_components[p].long_name;
            }
            if (address.address_components[p].types.indexOf('country') != -1) {
                this.countryValue = address.address_components[p].long_name;
            }
        }
        this.bookingForm.patchValue({
            city: this.cityValue,
            postalcode: this.postalcodeValue,
            country: this.countryValue
        });
    }

    anotherDriver(typeOp: any) {
        if (typeOp === 'add') {
            $('#addAnotherDriver').hide();
            $('#removeAnotherDriver').show();
            $('#addAnotherDriverDiv').show();
            this.bookingForm.get('drivername2').setValidators(Validators.required);
            this.bookingForm.get('driverdob2').setValidators(Validators.required);
        } else {
            $('#addAnotherDriver').show();
            $('#removeAnotherDriver').hide();
            $('#addAnotherDriverDiv').hide();
            this.bookingForm.get('drivername2').clearValidators();
            this.bookingForm.get('driverdob2').clearValidators();
        }
        this.bookingForm.get('drivername2').updateValueAndValidity();
        this.bookingForm.get('driverdob2').updateValueAndValidity();
    }

    loadStripe() {
        if (!window.document.getElementById('stripe-script')) {
            var s = window.document.createElement('script');
            s.id = 'stripe-script';
            s.type = 'text/javascript';
            s.src = 'https://checkout.stripe.com/checkout.js';
            s.onload = () => {
                this.handler = (<any>window).StripeCheckout.configure({
                    key: 'pk_test_51IBMBkFGsbvXqn8Lunj6ZdEvqGkgiCl8AYCSnfw1aflJfeqLv2wxFzBNZoqrbuY1l1jJA8TAsvU0AHzhVmPpQaSS00OXSRPwFh',
                    locale: 'auto',
                    token: function (token: any) {
                        // You can access the token ID with `token.id`.
                        // Get the token ID to your server-side code for use.
                        console.log(token)
                        this.sendBooking(this.quoteId);
                        //alert('Payment Success!!');
                    }
                });
            }

            window.document.body.appendChild(s);
        }
    }

    pay(amount: any, quoteId: any) {

        var handler = (<any>window).StripeCheckout.configure({
            key: 'pk_test_51IBMBkFGsbvXqn8Lunj6ZdEvqGkgiCl8AYCSnfw1aflJfeqLv2wxFzBNZoqrbuY1l1jJA8TAsvU0AHzhVmPpQaSS00OXSRPwFh',
            locale: 'auto',
            token: function(token: any) {
                // You can access the token ID with `token.id`.
                // Get the token ID to your server-side code for use.
                this.capturePayment(token);


            }
        });

        handler.open({
            name: 'Affordable',
            description: 'Motor Home Holidays',
            amount: amount * 100
        });

    }

    capturePayment(token: any) {
        console.log(token + 'Token Received');
    }

    chooseDrivers(driverCount: any) {
        alert(driverCount);
    }

    checkSelfDriver() {
        if ($('#toggle').prop('checked') == true) {
            $('#drivername').val($('#firstName').val());
        } else {
            $('#drivername').val('');
        }
    }

    iamdriver() {
        if ($('#toggle').prop('checked') == true) {
            $('#drivername').val($('#firstName').val());
        } else {
            $('#drivername').val('');
        }
    }

    sendQuoteEmail() {

        if ($('#sendmeemail').val()) {
            $('#sendquoteeerro').html('');
            let userinput = $('#sendmeemail').val();
            let pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i

            if (!pattern.test(userinput)) {
                $('#sendquoteeerro').html('Enter valid email');
            }​else
            {
                $('.loader').show();
                this.quoteDetails = {
                    email: $('#sendmeemail').val(),
                    quoteInfo: JSON.parse(window.localStorage.getItem('miniCart')),
                    userSelectedView: window.localStorage.getItem('userSearchDetail')
                };
                const subs = this.http.post<any>(`${environment.apiUrl}/${apiUrls.sendQuoteEmailV2}`, this.quoteDetails).subscribe(res => {
                    subs.unsubscribe();
                    if (res) {
                        $('.loader').hide();
                        $('#sendmeemail').val('');
                        $('.loader').hide();
                        this.showAlert('success');
                    }
                }, (err) => {
                    $('.loader').hide();
                }, () => {
                });

            }
        } else {
            $('#sendquoteeerro').html('Enter valid email');
        }
    }

    showAlert(showtype: any) {
        $('.layer2').show();
        $('.alert2').show();
        $('#alerttitle2').html('Alert !!!');
        $('#alertContent2').html(this.alertMessage);
        if (showtype == 'success') {
            $('#alerttitle2').hide();
            $('#alertContent2').hide();
            $('#successcontent2').show();
        } else {
            $('#alerttitle2').show();
            $('#alertContent2').show();
            $('#successcontent2').hide();
        }
    }

    closeAlert() {
        $('.layer2').hide();
        $('.alert2').hide();
        $('#alerttitle2').html('');
        $('#alertContent2').html('');
        //location.reload();
    }

    opentab(tabid: any, tabname: any) {
        $('#' + tabname + tabid + '').toggle();
        $('#dicon' + tabid + '').toggleClass('fa-minus');
        $('#' + tabname + tabid + '').parents('.vehicle-item').find('.vehicle-more-details-content').addClass('show');
    }

    closetab(tabid: any, tabname: any) {
        $('#' + tabname + tabid + '').toggle();
        $('#' + tabname + tabid + '').parents('.vehicle-item').find('.vehicle-more-details-content').removeClass('show');
    }
}
