import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { RestfulApiService } from "../_services/restful.service";

@Injectable({
    providedIn: "root"
})
export class PaymentsService {
    constructor(private restful: RestfulApiService) { }

    createCart(quote: string, body: string): Observable<any> {
        return this.restful.sendRequest(
            `/api/Sale/create?${quote}`,
            "POST",
            body
        );
    }

    sendQuote(body, param) {
        return this.restful.sendRequest(
            `/api/Sale/sendQuoteEmail?${param}`,
            "POST",
            body
        );
    }
}
