<!-- Login -->
<div class="login-signup show">
    <div class="login-signup-centered" id="signupformdiv">
        <div class="log-sign-main">
            <h2>Register</h2>
            <div class="log-sign-form">
                <form class="form appointment-form" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                    <fieldset>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 select-section">
                            <div class="row">
                                <div class="form-group">
                                    <input class="form-control" type="text" id="firstName" formControlName="firstName"
                                           placeholder="First Name"
                                           [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }">
                                    <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                                        <div *ngIf="f.firstName.errors.required">First Name Is required</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="row">
                                <div class="form-group">
                                    <input class="form-control" type="text" id="mobile" formControlName="mobile"
                                           placeholder="Your mobile number"
                                           [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }">
                                    <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                                        <div *ngIf="f.mobile.errors.required">Mobile number is required</div>
                                        <div *ngIf="f.mobile.errors.pattern">Please, Enter 10 digit Mobile Number.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="row">
                                <div class="form-group">
                                    <input class="form-control" type="email" id="email" formControlName="email"
                                           placeholder="Your Email"
                                           [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                        <div *ngIf="f.email.errors.required">Email is required</div>
                                        <div *ngIf="f.email.errors.email">Email must be a valid email address
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="row">
                                <div class="form-group">
                                    <input type="password" formControlName="password" class="form-control" placeholder="Enter Password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                        <div *ngIf="f.password.errors.required">Password is required</div>
                                        <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <input type="password" formControlName="confirmPassword" class="form-control" placeholder="Confirm Password" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" />
                                    <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                                        <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
                                        <div *ngIf="f.confirmPassword.errors.mustMatch">Passwords must match</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="row">
                                <div class="form-group">
                                    <div class="center">
                                        <button class="btn btn-sign" type="submit">Register</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
                <div class="signup-link">If you have an account login?<a [routerLinkActive]="['active']" routerLink="/login">Login</a></div>
            </div>
        </div>
    </div>
</div>
<div class="layer" style="display: none"></div>
<div class="alert" style="display: none">
    <h2 id="alerttitle">Alert !!!</h2>
    <button class="btn alert-close" (click)="closeAlert()"><img src="assets/images/close.svg"></button>
    <p id="alertContent"></p>
    <div class="alertbtns" style="margin-top: 15px">
        <button id="applyCoupon" class="red" (click)="closeAlert()">Okay</button>
    </div>
</div>