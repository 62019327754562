<section class="sub-search-wrap">
    <div class="sub-search-fields" style="display: none">
        <div class="container">
            <form [formGroup]="travelFormSearch">
                <div class="sub-search-row">
                    <span class="logo-sm"><img src="./assets/images/red-logo.png" alt="red-logo"></span>
                    <div class="sub-pick-drop-date">
                        <div class="sub-search-filed">
                            <span class="sub-search-label">Pick up Date</span>
                            <input type="text" id="pickup-date" class="form-control field-fromto-date-control startDate"
                                   value="{{startDate}}" name="startDate"/>
                        </div>
                        <span class="sub-s-cal"><i class="fa fa-calendar-o" aria-hidden="true"></i></span>
                        <div class="sub-search-filed sub-search-drop-filed">
                            <span class="sub-search-label">Drop Off Date</span>
                            <input type="text" class="form-control endDate" id="dropoff-date" value="{{endDate}}">
                        </div>
                    </div>

                    <div class="sub-pick-drop-location">
                        <div class="sub-search-filed">
                            <span class="sub-search-label">Pick up</span>
                            <div class="select-box1">
                                <select class="select-dropdown" id="pickup-city">
                                    <option>Select pick up</option>
                                    <option *ngFor="let city of cities | keyvalue">{{city.value}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="sub-search-filed">
                            <span class="sub-search-label">Drop Off</span>
                            <div class="select-box1">
                                <select class="select-dropdown" id="dropoff-city">
                                    <option>Select drop off</option>
                                    <option *ngFor="let city of cities | keyvalue"
                                            value="{{city.key}}">
                                        {{city.value}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="sub-passengers">
                        <div class="sub-search-filed">
                            <span class="sub-search-label">Passengers</span>
                            <div class="select-box1">
                                <select id="no_of_passengers" class="select-dropdown">
                                    <option>Sleeps 2</option>
                                    <option>Sleeps 3</option>
                                    <option>Sleeps 4</option>
                                    <option>Sleeps 5</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="sub-search-btn search-form-submit">
                        <button class="btn" (click)="search()">Search <br>
                            <small>My MotorHome</small>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="price-bar">
        <div class="container">
            <div class="price-bar-row">
                <div class="price-slider" style="opacity: 0">
                    <div class="price-slide-item">
                        <a href="#" class="price-slide-btn"><span class="day-name">Sun.24 May</span><span
                                class="day-price">$158.80</span></a>
                    </div>
                    <div class="price-slide-item">
                        <a href="#" class="price-slide-btn"><span class="day-name">Sun.24 May</span><span
                                class="day-price">$158.80</span></a>
                    </div>
                    <div class="price-slide-item">
                        <a href="#" class="price-slide-btn"><span class="day-name">Sun.24 May</span><span
                                class="day-price">$158.80</span></a>
                    </div>
                    <div class="price-slide-item">
                        <a href="#" class="price-slide-btn"><span class="day-name">Sun.24 May</span><span
                                class="day-price">$158.80</span></a>
                    </div>
                    <div class="price-slide-item">
                        <a href="#" class="price-slide-btn"><span class="day-name">Sun.24 May</span><span
                                class="day-price">$158.80</span></a>
                    </div>
                    <div class="price-slide-item">
                        <a href="#" class="price-slide-btn price-slide-btn-active"><span
                                class="day-name">Sun.24 May</span><span class="day-price">$158.80</span></a>
                    </div>
                    <div class="price-slide-item">
                        <a href="#" class="price-slide-btn"><span class="day-name">Sun.24 May</span><span
                                class="day-price">$158.80</span></a>
                    </div>
                    <div class="price-slide-item">
                        <a href="#" class="price-slide-btn"><span class="day-name">Sun.24 May</span><span
                                class="day-price">$158.80</span></a>
                    </div>
                    <div class="price-slide-item">
                        <a href="#" class="price-slide-btn"><span class="day-name">Sun.24 May</span><span
                                class="day-price">$158.80</span></a>
                    </div>
                    <div class="price-slide-item">
                        <a href="#" class="price-slide-btn"><span class="day-name">Sun.24 May</span><span
                                class="day-price">$158.80</span></a>
                    </div>
                    <div class="price-slide-item">
                        <a href="#" class="price-slide-btn"><span class="day-name">Sun.24 May</span><span
                                class="day-price">$158.80</span></a>
                    </div>
                </div>
                <div class="price-bar-right hidden-sm hidden-xs">
                    <div class="filter-open-lg" style="opacity: 0"><a href="javascript:void(0)" (click)="flitersToggle()"
                                                   class="open-filters">Filters <i class="fa fa-angle-down"
                                                                                   aria-hidden="true"></i></a>
                    </div>
                    <div class="price-sort">
                        <select class="selectpicker sortbyprice" (change)='sort($event)'>
                            <option>Sort By</option>
                            <option value="asc" [selected]="sortValue == 'asc'">Lowest Price</option>
                            <option value="desc" [selected]="sortValue == 'desc'">Highest Price</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <!-- Filters -->
        <section class="filters-wrap" id="filtersWrap" style="display:none">
            <div class="filters-actions">
                <div class="container">
                    <div class="text-right">
                        <button class="btn btn-sm btn-sm-cust hide-filters">Cancel</button>
                        <button class="btn btn-sm ml-2 btn-primary btn-sm-cust" (click)="applyFilters()">Apply</button>
                    </div>
                </div>
            </div>
            <div class="filters-main">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-3">
                            <div class="filters-block border-right">
                                <h4>Vehicle Specifications</h4>
                                <div class="checkbox-list">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck1">
                                        <label class="custom-control-label" for="customCheck1">Automatic</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck2">
                                        <label class="custom-control-label" for="customCheck2">Manual</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck3">
                                        <label class="custom-control-label" for="customCheck3">Petrol</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck4">
                                        <label class="custom-control-label" for="customCheck4">Diesel</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck5">
                                        <label class="custom-control-label" for="customCheck5">Toilet</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck6">
                                        <label class="custom-control-label" for="customCheck6">Shower</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="filters-block border-right">
                                <h4>Vehicle Age</h4>
                                <div class="checkbox-list">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck11">
                                        <label class="custom-control-label" for="customCheck11">0 - 2 years old</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck12">
                                        <label class="custom-control-label" for="customCheck12">3 - 4 years old</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck13">
                                        <label class="custom-control-label" for="customCheck13">5 - 6 years old</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck14">
                                        <label class="custom-control-label" for="customCheck14">7 - 9 years old</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck15">
                                        <label class="custom-control-label" for="customCheck15">10+ years old</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="filters-block">
                                <h4>Price Range</h4>
                                <div class="range-slider">
                                    <div class="slider" id="moneyRange"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</section>

<section id="main" class="search-results-wrap">
    <div class="container">

        <!-- Desktop  vehicle-items-wrap-->
        <section class="">
            <div *ngIf="!showLoader" class="contactUsWrap">

                        <app-dashboardmenu></app-dashboardmenu>

                <div  *ngIf="!noSearchResultsFound" class="tcContent vehicle-items-wrap">
                    <div *ngFor="let vehicle of vehiclesData; let i=index" class="vehicle-item">
                        <div class="clearfix">
                            <div class="vehicle-images-gallery">
                                <div class="vehicle-gallery-min">
                                    <div *ngFor="let images of vehicle.version.vehicle.moreImages"
                                         class="v-gallery-min-item">
                                        <figure><img data-toggle="modal" data-target="#GallerylightBox"
                                                     (click)="openGallery(vehicle.version.vehicle.moreImages)"
                                                     src="{{images.image}}" alt="vehicle"></figure>
                                    </div>

                                </div>
                                <div class="v-gallery-min-num"></div>
                                <a (click)="openGallery(vehicle.version.vehicle.moreImages)" class="gallery-fullmode"
                                   data-toggle="modal" data-target="#GallerylightBox"><img
                                        src="../assets/images/full-screen.svg"></a>
                            </div>

                            <div class="vehicle-details">
                                <div class="vehicle-title">
                                    <h3><a [routerLinkActive]="[ 'active']" routerLink="/review-booking">{{vehicle.version.vehicle.name}}</a>
                                    </h3>
                                    <div style="display: none" class="vehicle-rating"><img
                                            src="./assets/images/rating.png"></div>
                                    <div style="display: none" class="vehicle-overall-rating"><span>Good, 72%</span> |
                                        500+ Reviews
                                    </div>
                                </div>

                                <div class="vehicle-features" >
                                    <ul>
                                        <li *ngFor="let mainproperty of vehicle.version.vehicle.mainproperties">
                                                    <span class="v-feature-icon"><img
                                                            src="assets/images/{{mainproperty.image}}"></span>
                                            <span class="v-feature-name" [ngClass]="{'stikeout' : !mainproperty.status}">{{mainproperty.label}} <strong *ngIf="mainproperty.value"> {{mainproperty.value}}</strong></span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="vehicle-benifits" style="display: none">
                                    <ul>
                                        <li><span class="v-benefits-label">Vehicle Properties :</span></li>
                                        <li *ngFor="let property of vehicle.version.vehicle.vehicleProperties; let i=index">
                                            <span class="v-benefit-icon"><img src="./assets/images/check.svg"></span>
                                            <span class="v-feature-name">{{property.text}}</span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="vehicle-more-details">
                                    <ul class="">
                                        <li class="view-more-btn full-specs-btn-sm"><a (click)="opentab(i+1,'FullSpecs')">View Full Details <i
                                                class="fa fa-plus" id="dicon{{i+1}}"></i></a>
                                        </li>
                                        <li style="display: none"><a data-toggle="tab"
                                                                     href="#Insurance{{i+1}}">Capacity</a></li>
                                        <li style="display: none"><a data-toggle="tab"
                                                                     href="#Features{{i+1}}">Features</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div class="vehicle-rent-amt">
                                <div class="vehicle-rent">
                                    <span class="final-amt">{{vehicle.summary.customprices.currency}} {{vehicle.summary.customprices.value}}</span>
                                </div>
                                <button class="btn vehicle-select-btn"
                                        (click)="reviewBooking(vehicle.version.queryString)">Select
                                </button>
                            </div>

                            <div class="vehicle-item-options">
                                <span style="display: none" class="vehicle-distance">2 Miles away</span>
                                <a href="javascript:void(0)" class="like-vehicle"><i class="fa fa-heart"
                                                                                     aria-hidden="true"></i></a>
                                <a href="javascript:void(0)" class="share-vehicle"><i class="fa fa-share-alt"
                                                                                      aria-hidden="true"></i></a>
                            </div>
                        </div>

                        <div class="vehicle-more-details-content">
                            <div class="tab-content">
                                <div id="FullSpecs{{i+1}}" class="tab-pane">
                                    <div class="v-details-wrap">
                                        <button class="btn log-sign-close" (click)="closetab(i+1,'FullSpecs')"><img src="assets/images/close.svg"></button>
                                        <div class="v-details-row viewVehicleSpec">
                                            <ul>
                                                <li *ngFor="let customproperty of vehicle.version.vehicle.customproperties">
                                                    <span class="v-feature-icon" *ngIf="customproperty.image"><img
                                                            src="assets/images/{{customproperty.image}}"></span>
                                                    <span class="v-feature-name" [ngClass]="{'stikeout' : !customproperty.status}">{{customproperty.label}} <strong *ngIf="customproperty.value"> {{customproperty.value}}</strong></span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div id="Insurance{{i+1}}" class="tab-pane fade" style="display: none">
                                    <div class="v-details-wrap">
                                        <div class="v-details-row">
                                            <div class="v-details-block">
                                                <h4>Capacity</h4>
                                                <ul>
                                                    <li *ngFor="let property of vehicle.version.vehicle.capacity | keyvalue">
                                                        <span>{{property.key}}</span><span>{{property.value}}</span>
                                                    </li>
                                                </ul>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="Features{{i+1}}" class="tab-pane fade">
                                    <div class="v-details-wrap">
                                        <div class="v-details-row">
                                            <div class="v-details-block">
                                                Not available
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a style="display: none" href="#" class="add-to-compare">Add to compare</a>
                    </div>
                </div>
                <div id="contentloader" *ngIf="showLoader"><img src="./assets/images/loader.gif" alt="">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="tcContent pageNotFound" *ngIf="noSearchResultsFound">
                    <div class="" *ngIf="!showLoader">
                        <div class="pageNotFoundIn">
                            <img src="assets/images/no-page-banner.svg" alt="no-page-banner">
                            <h6>We couldn't find any matches!</h6>
                            <h5>Suggestions for improving your results:</h5>
                            <ul>
                                <li>Try a larger search area</li>
                                <li>Try a different location</li>
                                <li>Check the spelling or try alternate spellings</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>

</section>
<!-- /main -->

<!-- GallerylightBox -->
<div class="modal modal-lightbox fade" id="GallerylightBox" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" (click)="closeGallary()" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span></button>
                <h4 class="modal-title" style="display: none">Awesome Classic Camper (2-5 persons) <span
                        id="showSlideCount"></span></h4>
            </div>
            <div class="modal-body">
                <div class="gallary-lightbox-slider gallary-lightbox-view">
                    <div *ngFor="let images of vehicleGalleries" class="gallary-lightbox-item">
                        <figure><img src="{{images.image}}" alt="vehicle"></figure>
                    </div>
                </div>
                <div class="gallary-lightbox-slider-nav gallary-lightbox-view">
                    <div *ngFor="let images of vehicleGalleries" class="gallary-lightbox-nav-item">
                        <figure><img src="{{images.image}}" alt="vehicle"></figure>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>