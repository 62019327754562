<!-- Login -->
<div class="login-signup show">
    <div class="login-signup2-centered" id="loginformdiv">
        <div class="log-sign-main2">
            <h2>Change Password</h2>
            <div class="log-sign-form">
                <form class="form" [formGroup]="passwordForm" (ngSubmit)="changepassword()">
                    <div id="passworderror"></div>
                    <div class="account-form-fields">
                        <div class="row row-sm">
                            <div class="col-12">
                                <div class="form-group">
                                    <input class="form-control" type="email" id="email" formControlName="email"
                                           placeholder="Your Email"
                                           [ngClass]="{ 'is-invalid': passwordsubmitted && p.email.errors }">
                                    <div *ngIf="passwordsubmitted && p.email.errors" class="invalid-feedback">
                                        <div *ngIf="p.email.errors.required">Email is required</div>
                                        <div *ngIf="p.email.errors.email">Email must be a valid email address
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <input type="password" placeholder="New Password *" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': passwordsubmitted && p.password.errors }" />
                                    <div *ngIf="passwordsubmitted && p.password.errors" class="invalid-feedback">
                                        <div *ngIf="p.password.errors.required">Password is required</div>
                                        <div *ngIf="p.password.errors.minlength">Password must be at least 6 characters</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <input type="password" placeholder="Confirm Password *" formControlName="confirmPassword" class="form-control" [ngClass]="{ 'is-invalid': passwordsubmitted && p.confirmPassword.errors }" />
                                    <div *ngIf="passwordsubmitted && p.confirmPassword.errors" class="invalid-feedback">
                                        <div *ngIf="p.confirmPassword.errors.required">Confirm Password is required</div>
                                        <div *ngIf="p.confirmPassword.errors.mustMatch">Passwords must match</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 submit-btn-wrap">
                                <button type="submit" class="btn btn-sign">Change Password</button>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="signup-link">Don't have an account Create?<a [routerLinkActive]="['active']" routerLink="/register" >Signup</a></div>
            </div>
        </div>
    </div>

</div>
<div class="layer" style="display: none"></div>
<div class="alert" style="display: none">
    <h2 id="alerttitle">Alert !!!</h2>
    <button class="btn alert-close" (click)="closeAlert()"><img src="assets/images/close.svg"></button>
    <p id="alertContent"></p>
    <div class="alertbtns" style="margin-top: 15px">
        <button id="applyCoupon" class="red" (click)="closeAlert()">Okay</button>
    </div>
</div>