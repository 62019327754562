<section id="main">
    <div class="itineraries_d_banner" id="banner_fixed" style="background:url({{countryInfo.itenaries_banner}})">
        <div class="container">
            <div class="itineraries_d_cont">
                <h2>{{selectedCountry.name}}</h2>
            </div>
        </div>
    </div>

    

    <div class="signItineriesMain">
        <div class="container">
            <div class="contactUsWrap">
                <div class="innerJournySearch">
                    <app-dashboardmenu></app-dashboardmenu>
                </div>
                <div class="sing_Itineraries_list_content">
                    <div class="sing_Itineraries_list">
                        <div>
                            <div class="sing_Itineraries">
                                <div class="">
                                    <div class="sing_Itineraries_in">
                                        <div class="lft" style="width: 100%">
                                            <h3>Welcome to {{selectedCountry.name}}</h3>
                                            <div [innerHTML]="countryInfo.itenaries_short_desc"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="sing_Itineraries_list_in">
                                <div class="sing_Itineraries_in">
                                    <div class="lft" style="width: 100%">
                                        <h2 class="myfont">Our Affordable Itineraries</h2>
                                    </div>
                                </div>
                                <ul>
                                    <li *ngFor="let itenary of itenariesList">
                                        <div class="dLogo">
                                            <img src="{{itenary.image}}" alt="d-canada">
                                        </div>
                                        <div class="dst_lst_main">
                                            <div class="lft">
                                                <h2>{{itenary.title}}</h2>
                                                 <div class="dst_tms">
                                                    <div class="distnc">
                                                        <img src="assets/images/distance.svg" alt="distance">
                                                        <div class="distnc_dtl">
                                                            <h5>Distance</h5>
                                                            <h6>{{itenary.distance}}</h6>
                                                        </div>
                                                    </div>
                                                    <div class="est_time" style="display: none">
                                                        <img src="assets/images/time.svg" alt="time">
                                                        <div class="distnc_dtl">
                                                            <h5>Est Driving Time </h5>
                                                            <h6>14-28 days</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="trp_hlt">
                                                    <h3>Road Trip Highlights</h3>
                                                    <ul class="roadLoca">
                
                                                        <li *ngFor="let location of itenary.locations">
                                                            <span class="fromloc">{{location.fromLoc}}</span>
                                                            <span class="toloc">{{location.toLoc}}</span>
                                                        </li>
                
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="rgt" >
                                                <a routerLink="/destinations/{{selectedCountry.name}}/{{itenary.title}}/{{itenary.id}}">View Itinerary</a>
                                                <div class="map_details">
                                                    <img src="assets/images/map-1.png" alt="map-1">
                                                    <a href="#">View Map</a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="sing_Itineraries_in">
                                <div class="lft" style="width: 100%">
                                    <h2 class="myfont">More Affordable tips about {{selectedCountry.name}}</h2>
                                    <div [innerHTML]="countryInfo.itenaries_long_desc"></div>
                                </div>
                            </div>
                
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    

</section>
<!-- /main -->