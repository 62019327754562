import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { RestfulApiService } from "../../_services/restful.service";

@Injectable({
    providedIn: "root"
})
export class ResultsService {
    constructor(private restful: RestfulApiService) { }
    getSearchResults(data: {}, queryParams: any): Observable<any> {
        let url = (queryParams) ? `/api/Search/results?${queryParams}` : `/api/Search/results`
        return this.restful.sendRequest(
            url,
            "POST",
            data
        );
    }
}
