<div class="innerJournySearch">
    <div class="text-right edit-search-btn visible-xs" [ngClass]="{'hidden-xs': !showMobileSearch}">
        <button class="btn btn-sm searchBtn" (click)="showSearch()"  [ngClass]="{'searchBtnResults': routerValue == '/search/results'}">Edit Search</button>
    </div>
    <div class="journey-search-main journey-search-main-mobile" [ngClass]="{'hidden-xs': showMobileSearch}">
        <form [formGroup]="travelForm">
            <div class="search-form-wrap">
                <div class="search-field-country">
                    <label class="search-form-label">Country</label>
                    <div class="country_item">
                        <div class="select-box1">
                            <select class="form-control" id="filterCountry" formControlName="filter_country" title="Country" data-hide-disabled="true" (change)="navigate($event.target.value)" data-actions-box="true">
                                <option *ngFor="let country of countries | keyvalue : returnZero; index as i; let odd=odd;"
                                        value="{{country.value}}" [attr.data-country]="country.key">
                                    {{country.value}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="search-field-date">
                    <div class="field-fromto-date">
                        <label class="search-form-label">Pick up date</label>
                        <div class="input-group date">
                            <input type="text" id="startDate" value="{{startDate}}" class="form-control field-fromto-date-control startDate" />
                        </div>
                    </div>
                    <div class="form-calendar-icon">
                        <div class="form-calendar-icon-img"><img src="./assets/images/calendar.svg" alt="calendar"></div>
                    </div>
                    <div class="field-fromto-date">
                        <label class="search-form-label">Drop off date</label>
                        <div class="input-group date">
                            <input type="text" id="endDate" value="{{endDate}}" class="form-control field-fromto-date-control endDate" />
                        </div>
                    </div>
                </div>
                <div class="search-field-pick-drop">
                    <div class="search-field-pick">
                        <div class="pickup_slide dropup_slide">
                            <label class="search-form-label">Use for Pick up &amp; Drop</label>
                            <div class="right_toggle">
                                <input type="checkbox" name="dropoffcheck" id="toggle" checked="" class="remove-from-screen dropoffcheck" />
                                <label for="toggle" class="switch"></label>
                            </div>
                        </div>
                        <div class="select-box1" id="pickup_city_data">
                            <select class="form-control" id="pickup_city" formControlName="pickup_city" title="pickup" data-hide-disabled="true" data-actions-box="true">
                                <option *ngFor="let city of cities | keyvalue"
                                        value="{{city.key}}">
                                    {{city.value}}
                                </option>
                            </select>
                        </div>
                        <div id="dropoff_city_div">
                            <div class="select-box1" id="dropoff_city_data">
                                <select class="form-control" id="dropoff_city" formControlName="dropoff_city" title="dropoff" data-hide-disabled="true" data-actions-box="true">
                                    <option value="">Same as pickup</option>
                                    <option *ngFor="let city of cities | keyvalue"
                                            value="{{city.key}}">
                                        {{city.value}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="search-field-pass-age">
                    <div class="search-field-passengers">
                        <label class="search-form-label">Passengers</label>
                        <div class="select-box1">
                            <select class="form-control" id="filterPassengers" formControlName="filterPassengers" title="Passengers" data-hide-disabled="true" data-actions-box="true">
                                <option *ngFor="let passenger of passengerList | keyvalue"
                                        value="{{passenger.key}}">
                                    {{passenger.value}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="search-field-age">
                        <label class="search-form-label">Age</label>
                        <input type="number" name="driver_age" class="form-control" formControlName="driverAge" required="required" id="filterAge" value="{{driverAge}}" />
                    </div>
                </div>
            </div>
            <div class="search-form-submit">
                <button class="btn searchBtn" (click)="search($event)">Search <br> <small>My MotorHome</small></button>
            </div>
        </form>
    </div>
        
    <div class="search-signup hidden-xs" *ngIf="routerValue == '/search/results'">
        <h5>Sign Up &amp; Get 10% Discount</h5>
        <div class="signup-form">
            <input type="email" name="email" id="searchbottom" placeholder="Enter email">
            <button (click)="subscribe('searchbottom')" class="btn"><img src="./assets/images/right-arrow.svg" alt="right-arrow"></button>
        </div>
    </div>

    <div class="search-signup hidden-xs" *ngIf="routerValue != '/search/results'">
        <img alt="" class="feefo-badge__image" src="./assets/images/feefo_service.png">
    </div>

    <div class="we_offer hidden-xs" *ngIf="routerValue != '/search/results'">
        <div class=" fifo_Offer">
            <h2>Why Choose Us</h2>
            <div class="we_offer_in">
                <ul>
                    <li>
                        <img src="./assets/images/hand.svg" alt="hand">
                        <h5>Simple Pricing</h5>
                    </li>
                    <li>
                        <img src="./assets/images/money.svg" alt="money">
                        <h5>No hidden extras</h5>
                    </li>
                    <li>
                        <img src="./assets/images/taxi-driver.svg" alt="taxi-driver">
                        <h5>Driver cover</h5>
                    </li>

                </ul>
            </div>
        </div>
    </div>

</div>
<div class="layer" style="display: none"></div>
<div class="alert" style="display: none">
    <h2 id="alerttitle">Alert !!!</h2>
    <button class="btn alert-close" (click)="closeAlert()"><img src="assets/images/close.svg"></button>
    <p id="alertContent"></p>
    <div class="alertbtns" style="margin-top: 15px">
        <button id="applyCoupon" class="red" (click)="closeAlert()">Okay</button>
    </div>
</div>