<div class="itineraries_banner">
    <div class="container">
        <div class="itineraries_cont">
            <h2><span>Welcome to </span> Affordable Motorhome Holidays Itineraries</h2>

        </div>
    </div>
</div>
<div class="country_list fleetCountryList fleetdetail">
    <div class="container">
        <div class="country_list_in">
            <ul class="clist">
                <li *ngFor="let country of fleetCountries | slice:0:6; let i=index">
                    <a [routerLinkActive]="['active']" routerLink="/fleet-details/{{country.name}}">
                    <div class="dLogo">
                        <img src="{{country.banner}}" alt="{{country.name}}">
                    </div>
                    <a [routerLinkActive]="['active']" routerLink="/fleet-details/{{country.name}}"><span>{{country.name}}</span></a>
                    <span class="flag flag-{{country.country_code}} flag-24"></span>
                    </a>
                </li>
                <li class="showmore" (click)="showmore()">
                    <span>See more</span>
                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                </li>
                <li class="showall" style="display: none" *ngFor="let country of fleetCountries | slice:8:100; let i=index">
                    <a [routerLinkActive]="['active']" routerLink="/fleet-details/{{country.name}}">
                    <div class="dLogo">
                        <img src="{{country.banner}}" alt="{{country.name}}">
                    </div>
                    <a [routerLinkActive]="['active']" routerLink="/fleet-details/{{country.name}}"><span>{{country.name}}</span></a>
                    <span class="flag flag-{{country.country_code}} flag-24"></span>
                    </a>
                </li>
                <li class="showless" (click)="showless()" style="display: none">
                    <span>See more</span>
                    <i class="fa fa-angle-up" aria-hidden="true"></i>
                </li>
            </ul>
        </div>
    </div>
</div>
<section class="fleetSection">
    <div class="container">
        <div class="fleetDetails">
            <div class="fleetDetailsTop">
                <div class="fleetDetailsCont">
                    <h2>Motorhomes in {{selectedCountry}} </h2>
                    <h3 style="display: none">Campervans; Motorhomes and RV’s</h3>
                </div>
            </div>
            <div class="fleetTab fleetTabNew">


                <div class="tab-content">
                    <div id="home" class="tab-pane fade in active">

                        <!-- div class="camperSuggest" style="display: none">
                            <ul>
                                <li *ngFor="let fleet of fleets">
                                    <h5>{{fleet.fleet_title}}</h5>
                                    <img src="https://amhstgapi.niyams.com/uploads/fleets/{{fleet.images}}"
                                         alt="campervan-1">
                                    <div class="optn" *ngIf="fleet.seating_capacity"><h6>Capacity:</h6><span>{{fleet.seating_capacity}}</span></div>
                                    <div class="optn"><h6>Category: </h6><span>{{fleet.category}}</span></div>
                                    <div class="optn"><h6>Model Age:</h6> <span>{{fleet.model_age_year}}</span></div>
                                    <div class="optn"><h6>Length:</h6> <span>{{fleet.length}}</span></div>
                                    <div class="optn"><h6>Transmission:</h6> <span> {{fleet.transmission}}</span></div>
                                    <div class="optn"><h6>Power Source:</h6> <span>{{fleet.power_source}}</span></div>
                                </li>

                            </ul>
                        </div -->


                        <!-- Desktop -->
                        <section class="vehicle-items-wrap fleetwrap">
                            <div class="row">
                                <div class="col-sm-6 col-xs-12" *ngFor="let fleet of fleetsList;let i=index">
                                    <div class="vehicle-item clearfix">
                                        <div class="clearfix">
                                            <div class="vehicle-images-gallery">
                                                <div class="vehicle-gallery-min">
                                                    <div class="v-gallery-min-item">
                                                        <figure><img src="{{fleet.image}}" alt="vehicle"></figure>
                                                    </div>
                                                    <div class="v-gallery-min-item" *ngFor="let images of fleet.moreImages">
                                                        <figure><img src="{{images}}" alt="vehicle"></figure>
                                                    </div>
        
                                                </div>
                                                <div class="v-gallery-min-num"></div>
                                                <a href="#" class="gallery-fullmode" data-toggle="modal" data-target="#GallerylightBox"><img src="assets/images/full-screen.svg"></a>
                                            </div>
        
                                        </div>
                                        <div class="fleetdesc">
                                            <h2>{{fleet.title}}</h2>
                                            <span class="descitem">Category : {{fleet.Category}}</span>
                                            <span class="descitem">ModelAge : {{fleet.ModelAge}}</span>
                                            <div class="fleetSpec">
        
                                                    <div class="fleetspecitem" *ngFor="let customproperty of fleet.customProperties">
                                                            <span class="v-feature-icon"><img
                                                                    src="assets/images/{{customproperty.image}}"></span>
                                                        <span class="v-feature-name" [ngClass]="{'stikeout' : !customproperty.status}">{{customproperty.label}} <strong *ngIf="customproperty.value"> {{customproperty.value}}</strong></span>
                                                    </div>
        
                                            </div>
        
                                        </div>
                                    </div>
                                </div>
                            </div>
                            

                        </section>



                    </div>

                </div>
            </div>
        </div>
    </div>
</section>
<!-- GallerylightBox -->
<div class="modal modal-lightbox fade" id="GallerylightBox" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span></button>
            </div>
             <div class="modal-body">
                <div class="gallary-lightbox-slider gallary-lightbox-view">
                    <div class="gallary-lightbox-item">
                        <figure><img src="https://c.imallcdn.net/tmbr/-RHgV2Qtlq9miHIXcFlpQpIAuwI=/650x433/c.imallcdn.net/_vd/images/orig/8/4/2709804_1501632450.jpg" alt="vehicle"></figure>
                    </div>
                    <div class="gallary-lightbox-item">
                        <figure><img src="https://c.imallcdn.net/tmbr/KMI6UUUAnaHTtEfoowPFMGpuiNI=/650x433/c.imallcdn.net/_vd/images/orig/8/4/2709804_1589517409.jpg" alt="vehicle"></figure>
                    </div>
                    <div class="gallary-lightbox-item">
                        <figure><img src="https://c.imallcdn.net/tmbr/bk2V9Mq80qf0pPOtbFrUG0TGj_E=/650x433/c.imallcdn.net/_vd/images/orig/8/4/2709804_1501632433.jpg" alt="vehicle"></figure>
                    </div>
                    <div class="gallary-lightbox-item">
                        <figure><img src="https://c.imallcdn.net/tmbr/3vJWI1pzs5gjucKoeE0mlbjouVM=/650x433/c.imallcdn.net/_vd/images/orig/8/4/2709804_1501632434.jpg" alt="vehicle"></figure>
                    </div>
                </div>
                <div class="gallary-lightbox-slider-nav gallary-lightbox-view">
                    <div class="gallary-lightbox-nav-item">
                        <figure><img src="https://c.imallcdn.net/tmbr/-RHgV2Qtlq9miHIXcFlpQpIAuwI=/650x433/c.imallcdn.net/_vd/images/orig/8/4/2709804_1501632450.jpg" alt="vehicle"></figure>
                    </div>
                    <div class="gallary-lightbox-nav-item">
                        <figure><img src="https://c.imallcdn.net/tmbr/KMI6UUUAnaHTtEfoowPFMGpuiNI=/650x433/c.imallcdn.net/_vd/images/orig/8/4/2709804_1589517409.jpg" alt="vehicle"></figure>
                    </div>
                    <div class="gallary-lightbox-nav-item">
                        <figure><img src="https://c.imallcdn.net/tmbr/bk2V9Mq80qf0pPOtbFrUG0TGj_E=/650x433/c.imallcdn.net/_vd/images/orig/8/4/2709804_1501632433.jpg" alt="vehicle"></figure>
                    </div>
                    <div class="gallary-lightbox-nav-item">
                        <figure><img src="https://c.imallcdn.net/tmbr/3vJWI1pzs5gjucKoeE0mlbjouVM=/650x433/c.imallcdn.net/_vd/images/orig/8/4/2709804_1501632434.jpg" alt="vehicle"></figure>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>