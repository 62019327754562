import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {DataService, AlertService} from '@app/_services';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    userId: string;
    user: any;
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (window.localStorage.getItem('token')) {
            this.user = JSON.parse(window.localStorage.getItem('token'));
            request = request.clone({
                setHeaders: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + this.user.user.token
                }
            });
        } else {
            request = request.clone({
                setHeaders: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });
        }
        return next.handle(request);
    }
}
