<!-- Login -->
<div class="login-signup show">
    <div class="login-signup-centered" id="loginformdiv">
        <div class="log-sign-main">
            <h2>Login</h2>
            <div class="log-sign-form">
                <form class="form" [formGroup]="loginForm" (ngSubmit)="onSubmitLogin()">
                    <div class="form-field">
                        <input type="text" class="form-control" id="username" formControlName="username" placeholder="Your Username" [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
                        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                            <div *ngIf="f.username.errors.required">Username is required</div>
                        </div>
                        <span class="log-sign-icon"><img src="../assets/images/user-icon.svg"></span>
                    </div>
                    <div class="form-field">
                        <input type="password" class="form-control"  formControlName="password" placeholder="Your Password" id="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                            <div *ngIf="f.password.errors.required">Password is required</div>
                            <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                        </div>
                        <span class="log-sign-icon"><img src="../assets/images/privacy.svg"></span>
                    </div>
                    <div class="row">
                        <div class="col-xs-6">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="customCheck11">
                                <label class="custom-control-label" for="customCheck11">Remember me</label>
                            </div>
                        </div>
                        <div class="col-xs-6 text-right">
                            <a [routerLinkActive]="['active']" routerLink="/forgot-password" class="forgot-pass-btn">Forgot password?</a>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-sign">Submit</button>
                </form>
                <div class="signup-link">Don't have an account Create?<a [routerLinkActive]="['active']" routerLink="/register" >Signup</a></div>
            </div>
        </div>
    </div>

</div>