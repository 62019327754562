<section id="main" class="payment-main-wrap gray-bg">
    <div class="mobile-sub-nav visible-sm visible-xs">
        <a href="#" class="go-back-btn"><img src="./assets/images/left-short-arrow-mobile.svg"></a>
        <h2>Payment</h2>
    </div>
    <div class="payment-progress">
        <div class="container">
            <div class="payment-progress-step">
                <span class="step-number">1</span>
                <span class="step-name">Review Booking</span>
            </div>
            <div class="payment-progress-step active">
                <span class="step-number">2</span>
                <span class="step-name">Booking Info</span>
            </div>
            <div class="payment-progress-step">
                <span class="step-number">3</span>
                <span class="step-name">Booking Success</span>
            </div>
        </div>
    </div>
    <section class="payment-info-wrap clearfix">

        <form class="form" #checkout="ngForm" [formGroup]="bookingForm" (ngSubmit)="onSubmit(checkout)">
            <div class="container">
                <div class="row">
                    <div class="col-sm-9 fare-details-wrap-col">
                        <div *ngIf="resultView" class="vehicle-item vehicle-item-plain clearfix">
                            <div class="clearfix">
                                <div class="vehicle-images-gallery hidden-sm hidden-xs" *ngIf="resultView">
                                    <div class="vehicle-gallery-min">
                                        <div *ngFor="let images of resultView.quote.model.version.vehicle.moreImages"
                                             class="v-gallery-min-item" data-toggle="modal" data-target="#GallerylightBox">
                                            <figure><img src="{{images.image}}" alt="vehicle"></figure>
                                        </div>
                                    </div>
                                    <div class="v-gallery-min-num"></div>
                                    <a href="javascript:void(0)" class="gallery-fullmode" data-toggle="modal"
                                       data-target="#GallerylightBox"><img src="./assets/images/full-screen.svg"></a>
                                </div>

                                <div class="vehicle-details">
                                    <div class="vehicle-title">
                                        <h3 *ngIf="resultView">{{resultView.quote.model.version.vehicle.name}}</h3>
                                        <!-- <div class="vehicle-rating hidden-sm hidden-xs"><img
                                                src="./assets/images/rating.png">
                                        </div> -->
                                        <div style="display: none" class="vehicle-overall-rating hidden-sm hidden-xs">
                                            <span>Good, 72%</span>
                                            | 500+ Reviews
                                        </div>
                                    </div>
                                    <div style="display: none" class="veh-confirm-avail">
                                        <span class="confirm-badge">24h confirmation</span>
                                        <span class="avail-num">Available : 3</span>
                                    </div>
                                    <div class="vehicle-features">
                                        <ul>
                                            <li>
                                                <span class="v-feature-icon"><img src="./assets/images/bed.svg"></span>
                                                <span class="v-feature-name" *ngIf="resultView">{{resultView.quote.model.version.vehicle.capacity.bedCapacity}}</span>
                                            </li>
                                            <li>
                                                <span class="v-feature-icon"><img
                                                        src="./assets/images/toilet.svg"></span>
                                                <span class="v-feature-name">Toilet</span>
                                            </li>
                                            <li>
                                                <span class="v-feature-icon"><img
                                                        src="./assets/images/shower.svg"></span>
                                                <span class="v-feature-name">Shower</span>
                                            </li>
                                            <li>
                                            <span class="v-feature-icon"><img
                                                    src="./assets/images/manual-transmission.svg"></span>
                                                <span class="v-feature-name">Manual</span>
                                            </li>
                                            <li>
                                                <span class="v-feature-icon"><img
                                                        src="./assets/images/caravan.svg"></span>
                                                <span class="v-feature-name" *ngIf="resultView">{{resultView.quote.model.version.vehicle.vehicleAge.text}}</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <!-- <div class="vehicle-benifits hidden-sm hidden-xs">
                                        <ul>
                                            <li><span class="v-benefits-label">Benefits :</span></li>
                                            <li><span class="v-benefit-icon"><img
                                                    src="./assets/images/check.svg"></span><span class="v-benefit-name">Equipped</span>
                                            </li>
                                            <li><span class="v-benefit-icon"><img
                                                    src="./assets/images/check.svg"></span><span
                                                    class="v-benefit-name">Esky</span></li>
                                            <li><span class="v-benefit-icon"><img
                                                    src="./assets/images/check.svg"></span><span class="v-benefit-name">Outdoor table/chairs</span>
                                            </li>
                                            <li><span class="v-benefit-icon"><img
                                                    src="./assets/images/check.svg"></span><span class="v-benefit-name">Portable</span>
                                            </li>
                                            <li><span class="v-benefit-icon"><img
                                                    src="./assets/images/check.svg"></span><span class="v-benefit-name">Outdoor table/chairs</span>
                                            </li>
                                        </ul>
                                    </div> -->
                                    <a class="full-specs-btn-sm visible-xs visible-sm" href="#"><i class="fa fa-plus"
                                                                                                   aria-hidden="true"></i>
                                        View Full Details</a>
                                    <div class="vehicle-more-details hidden-sm hidden-xs">

                                        <ul class="">
                                            <li class="view-more-btn full-specs-btn-sm"><a (click)="opentab(1,'FullSpecs')">View Full Details <i
                                                    class="fa fa-plus" id="dicon1"></i></a>

                                            </li>
                                            <li style="display: none"><a data-toggle="tab"
                                                                         href="#Insurance1">Capacity</a></li>
                                            <li style="display: none"><a data-toggle="tab"
                                                                         href="#Features1">Features</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="vehicle-more-details-content">
                                <div class="tab-content">
                                    <div id="FullSpecs1" class="tab-pane">
                                        <div class="v-details-wrap">
                                            <button class="btn log-sign-close" (click)="closetab(1,'FullSpecs')"><img src="assets/images/close.svg"></button>
                                            <div class="v-details-row viewVehicleSpec">
                                                <ul>
                                                    <li *ngFor="let customproperty of resultView.quote.model.version.vehicle.customproperties">
                                                    <span class="v-feature-icon" *ngIf="customproperty.image"><img
                                                            src="assets/images/{{customproperty.image}}"></span>
                                                        <span class="v-feature-name" [ngClass]="{'stikeout' : !customproperty.status}">{{customproperty.label}} <strong *ngIf="customproperty.value"> {{customproperty.value}}</strong></span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="Insurance1" class="tab-pane fade" style="display: none">
                                        <div class="v-details-wrap">
                                            <div class="v-details-row">
                                                <div class="v-details-block">
                                                    <h4>Capacity</h4>
                                                    <ul>
                                                        <li *ngFor="let property of resultView.quote.model.version.vehicle.capacity | keyvalue">
                                                            <span>{{property.key}}</span><span>{{property.value}}</span>
                                                        </li>
                                                    </ul>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="Features1" class="tab-pane fade">
                                        <div class="v-details-wrap">
                                            <div class="v-details-row">
                                                <div class="v-details-block">
                                                    Not available
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="location-details">
                                <ul>
                                    <li>
                                    <span class="location-d-icon"><img
                                            src="./assets/images/localtion_van_gray.svg"></span>
                                        <div class="location-details-txt" *ngIf="resultView">
                                            <span class="location-details-label">Pick up</span>
                                            <span class="location-details-point">{{resultView.quote.model.version.pickUpLocation.text}}</span>
                                            <span class="location-details-extra">
                                            {{resultView.quote.model.version.pickUpLocation.address.companyName}},<br>
                                            {{resultView.quote.model.version.pickUpLocation.address.street}},<br>
                                            {{resultView.quote.model.version.pickUpLocation.address.cityName}},<br>
                                            {{resultView.quote.model.version.pickUpLocation.address.postalCode}},<br>
                                            {{resultView.quote.model.version.pickUpLocation.address.phoneFixnet}}
                                        </span>
                                        </div>
                                    </li>
                                    <li>
                                    <span class="location-d-icon"><img
                                            src="./assets/images/localtion_van_gray.svg"></span>
                                        <div class="location-details-txt">
                                            <span class="location-details-label">Drop Off</span>
                                            <span class="location-details-point"
                                                  *ngIf="resultView.quote.model.version.dropOffLocation">
                                            {{resultView.quote.model.version.dropOffLocation.text}}
                                        </span>
                                            <span class="location-details-point"
                                                  *ngIf="!resultView.quote.model.version.dropOffLocation">
                                            {{resultView.quote.model.version.pickUpLocation.text}}
                                        </span>
                                            <span class="location-details-extra"  *ngIf="resultView.quote.model.version.dropOffLocation">
                                            {{resultView.quote.model.version.dropOffLocation.address.companyName}},<br>
                                            {{resultView.quote.model.version.dropOffLocation.address.street}},<br>
                                            {{resultView.quote.model.version.dropOffLocation.address.cityName}},<br>
                                            {{resultView.quote.model.version.dropOffLocation.address.postalCode}},<br>
                                            {{resultView.quote.model.version.dropOffLocation.address.phoneFixnet}}
                                        </span>
                                            <span class="location-details-extra"  *ngIf="!resultView.quote.model.version.dropOffLocation">
                                            {{resultView.quote.model.version.pickUpLocation.address.companyName}},<br>
                                            {{resultView.quote.model.version.pickUpLocation.address.street}},<br>
                                            {{resultView.quote.model.version.pickUpLocation.address.cityName}},<br>
                                            {{resultView.quote.model.version.pickUpLocation.address.postalCode}},<br>
                                            {{resultView.quote.model.version.pickUpLocation.address.phoneFixnet}}
                                        </span>
                                        </div>
                                    </li>
                                    <li>
                                        <span class="location-d-icon"><img src="./assets/images/passenger.svg"></span>
                                        <div class="location-details-txt">
                                            <span class="location-details-label location-details-passenger">Passengers</span>
                                            <span class="location-details-point">{{userSearchSelected.no_of_passengers}}</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div class="selected-date-time">
                                <div class="sele-date-item">
                                    <span class="location-d-icon"><img src="./assets/images/clock.svg"></span>
                                    <div class="location-details-txt">
                                        <span class="location-details-label">Pick up date</span>
                                        <span class="location-details-point">{{resultView.dates.pickupDate}}</span>
                                    </div>
                                    <div class="sele-date">
                                        <select class="">
                                            <option *ngFor="let pickup of resultView.dates.pickupHours | keyvalue"
                                                    value="{{pickup.key}}">{{pickup.value}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="sele-date-item">
                                    <span class="location-d-icon"><img src="./assets/images/clock.svg"></span>
                                    <div class="location-details-txt">
                                        <span class="location-details-label">Drop off date</span>
                                        <span class="location-details-point">{{resultView.dates.dropoffDate}}</span>
                                    </div>
                                    <div class="sele-date">
                                        <select class="">
                                            <option *ngFor="let dropOff of resultView.dates.dropOffHours | keyvalue"
                                                    value="{{dropOff.key}}">{{dropOff.value}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="resultView" class="rental-details">
                            <h3 class="sub-heading-3">Rental details</h3>
                            <div class="booking-form">
                                <div class="booking-form-block booking-for-others border-bottom">
                                    <div class="row">
                                        <div class="singlerow">
                                            <div class="col-md-6 col-xs-6">
                                                <label>First Name<span class="red">*</span></label>
                                                <input class="form-control" (change)="checkSelfDriver()" type="text" id="firstName"
                                                       formControlName="firstName"
                                                       placeholder="First Name"
                                                       [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }">
                                                <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                                                    <div *ngIf="f.firstName.errors.required">First Name Is required
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-xs-6">
                                                <label>Last Name<span class="red">*</span></label>
                                                <input class="form-control" type="text" id="lastname"
                                                       formControlName="lastname"
                                                       placeholder="Last Name"
                                                       [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }">
                                                <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                                                    <div *ngIf="f.lastname.errors.required">Last Name Is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="singlerow">
                                            <div class="col-md-6 col-xs-6">
                                                <label>Email<span class="red">*</span></label>
                                                <input class="form-control" type="email" id="email"
                                                       formControlName="email"
                                                       placeholder="Your Email"
                                                       [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                    <div *ngIf="f.email.errors.required">Email is required</div>
                                                    <div *ngIf="f.email.errors.email">Email must be a valid email
                                                        address
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-xs-6">
                                                <label>Your mobile number<span class="red">*</span></label>
                                                <input class="form-control" type="text" id="mobile"
                                                       formControlName="mobile"
                                                       placeholder="Your mobile number"
                                                       [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }">
                                                <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                                                    <div *ngIf="f.mobile.errors.required">Mobile number is required
                                                    </div>
                                                    <div *ngIf="f.mobile.errors.pattern">Please, Enter 10 digit Mobile
                                                        Number.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="booking-form-block">
                                    <div class="row">
                                        <div class="singlerow">
                                            <div class="col-md-6 col-xs-12">

                                                <div class="form-field">
                                                    <label>Your street address<span class="red">*</span></label>
                                                    <input class="form-control" ngx-google-places-autocomplete
                                                           [options]='options' #address="ngx-places"
                                                           (onAddressChange)="handleAddressChange($event)"
                                                           formControlName="address" placeholder="Address"
                                                           [ngClass]="{ 'is-invalid': submitted && f.address.errors }">
                                                    <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                                                        <div *ngIf="f.address.errors.required">Address Is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-xs-12">
                                                <label>City<span class="red">*</span></label>
                                                <input class="form-control" type="text" id="city" formControlName="city"
                                                       placeholder="City"
                                                       [ngClass]="{ 'is-invalid': submitted && f.city.errors }">
                                                <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                                                    <div *ngIf="f.city.errors.required">City Is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="singlerow">
                                            <div class="col-md-6 col-xs-12">
                                                <label>Postcode<span class="red">*</span></label>
                                                <input class="form-control" type="text" id="postalcode"
                                                       formControlName="postalcode"
                                                       placeholder="postal code"
                                                       [ngClass]="{ 'is-invalid': submitted && f.postalcode.errors }">
                                                <div *ngIf="submitted && f.postalcode.errors" class="invalid-feedback">
                                                    <div *ngIf="f.postalcode.errors.required">Postalcode Is required
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="col-md-6 col-xs-12">
                                                <label>Country<span class="red">*</span></label>
                                                <input class="form-control" type="text" id="country"
                                                       formControlName="country"
                                                       placeholder="Country"
                                                       [ngClass]="{ 'is-invalid': submitted && f.country.errors }">
                                                <div *ngIf="submitted && f.country.errors" class="invalid-feedback">
                                                    <div *ngIf="f.country.errors.required">Country Is required</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="drive-details">
                            <h3 class="sub-heading-3">Driver and passenger details</h3>
                            <div class="im-driving">
                                <span>I'm Driving</span>
                                <div class="switch-toggle right_toggle">
                                    <input type="checkbox" id="toggle" (click)="iamdriver()" class="remove-from-screen">
                                    <label for="toggle" class="switch"></label>
                                </div>
                            </div>
                            <div class="booking-form">
                                <div class="booking-form-block border-bottom">
                                    <div class="row">
                                        <div class="singlerow">
                                            <div class="col-md-6 col-xs-12">
                                                <div class="form-field">
                                                    <label>Driver full name<span class="red">*</span></label>

                                                    <input class="form-control" type="text" id="drivername"
                                                           formControlName="drivername"
                                                           [ngClass]="{ 'is-invalid': submitted && f.drivername.errors }">
                                                    <div *ngIf="submitted && f.drivername.errors"
                                                         class="invalid-feedback">
                                                        <div *ngIf="f.drivername.errors.required">Driver full name is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-xs-12">
                                                <div class="form-field">
                                                    <label>Date of birth<span class="red">*</span></label>

                                                    <input class="form-control" type="date" id="driverdob"
                                                           formControlName="driverdob"
                                                           [ngClass]="{ 'is-invalid': submitted && f.driverdob.errors }">
                                                    <div *ngIf="submitted && f.driverdob.errors"
                                                         class="invalid-feedback">
                                                        <div *ngIf="f.driverdob.errors.required">Age should not be more than 18 years
                                                        </div>
                                                        <div *ngIf="f.driverdob.errors?.age">Age should not be more than 18 years
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="singlerow">
                                            <div class="col-md-4 col-xs-12">
                                                <div class="form-field">
                                                    <label>License number</label>
                                                    <input type="text" class="form-control" id="dlicencenumber"
                                                           formControlName="dlicencenumber" [ngClass]="{ 'is-invalid': submitted && f.dlicencenumber.errors }">
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-xs-12">
                                                <div class="form-field">
                                                    <label>Expiry date</label>
                                                    <input type="date" id="licenseexpirydate" class="form-control"
                                                           formControlName="licenseexpirydate">
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-xs-12">
                                                <div class="form-field">
                                                    <label>Country of issue</label>
                                                    <input type="text" class="form-control" id="d_licence_issue"
                                                           formControlName="d_licence_issue">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <span class="add-another-driver-btn" id="addAnotherDriver"><a
                                            (click)="anotherDriver('add')">+ Add another driver</a></span>
                                    <span style="display: none" class="add-another-driver-btn" id="removeAnotherDriver"><a
                                            (click)="anotherDriver('remove')">- Remove another driver</a></span>
                                    <div class="row" id="addAnotherDriverDiv" style="display: none">
                                        <div class="singlerow">
                                            <div class="col-md-6 col-xs-12">
                                                <div class="form-field">
                                                    <label>Driver full name</label>
                                                    <input class="form-control" type="text" id="drivername2"
                                                           formControlName="drivername2"
                                                           [ngClass]="{ 'is-invalid': submitted && f.drivername2.errors }">
                                                    <div *ngIf="submitted && f.drivername2.errors"
                                                         class="invalid-feedback">
                                                        <div *ngIf="f.drivername2.errors.required">Driver full name is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-xs-12">
                                                <div class="form-field">
                                                    <label>Date of birth</label>
                                                    <input class="form-control" type="date" id="driverdob2"
                                                           formControlName="driverdob2"
                                                           [ngClass]="{ 'is-invalid': submitted && f.driverdob2.errors }">
                                                    <div *ngIf="submitted && f.driverdob2.errors"
                                                         class="invalid-feedback">
                                                        <div *ngIf="f.driverdob2.errors.required">Date of birth is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="singlerow">
                                            <div class="col-md-4 col-xs-12">
                                                <div class="form-field">
                                                    <label>License number</label>
                                                    <input type="text" class="form-control">
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-xs-12">
                                                <div class="form-field">
                                                    <label>Expiry date</label>
                                                    <input type="date" class="form-control">
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-xs-12">
                                                <div class="form-field">
                                                    <label>Country of issue</label>
                                                    <input type="text" class="form-control">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="booking-form-block" style="display: none">
                                    <div class="row row-flex">
                                        <div class="col-md-3 col-lg-3 col-xs-6">
                                            <div class="form-field">
                                                <label>Number of Drivers<span class="red">*</span></label>
                                                <span class="">
                                                    <div class="sele-date">
                                                        <select #noofdrivers class="form-control" (change)="chooseDrivers(noofdrivers.value)">
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option>
                                                            <option value="6">6</option>
                                                            <option value="7">7</option>
                                                        </select>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-md-3 col-lg-3 col-xs-6">
                                            <div class="form-field">
                                                <label>Drivers Age<span class="red">*</span></label>
                                                <span class="">
                                                    <input type="number" value="36" id="driverage" class="form-control">
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-md-3 col-lg-3 col-xs-6">
                                            <div class="form-field">
                                                <label>Drivers 2 Age<span class="red">*</span></label>
                                                <span class="">
                                                    <input type="number" value="36" class="form-control">
                                                </span>
                                            </div>
                                        </div>

                                    </div>

                                </div>


                            </div>
                        </div>
                        <div class="drive-details" style="margin-top: 20px">
                            <h3 class="sub-heading-3">Payment details</h3>
                            <div class="booking-form">
                                <div class="booking-form-block">
                                    <label for="card-info">Card Info</label>
                                    <div id="card-info" #cardInfo></div>

                                    <div id="card-errors" role="alert" *ngIf="error">{{ error }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="terms-checkbox" *ngIf="!isMobile">
                            <div class="custom-control custom-checkbox">
                                <input (click)="checkterms()" type="checkbox" name="agree_box" required class="customcheckbox custom-control-input" id="termschecbox" >
                                <label class="custom-control-label" for="termschecbox">I agree to this vehicle’s <a
                                        [routerLinkActive]="['active']" routerLink="/terms-and-conditions" target="_blank"><strong>Terms & Conditions</strong></a> and Payments & Cancellations
                                    Policy
                                    * Read these now You are agreeing to our <a [routerLinkActive]="['active']" routerLink="/gdpr-statement" target="_blank">Privacy Policy</a></label>
                            </div>
                        </div>

                    </div>
                    <div *ngIf="resultView" class="col-sm-3 fare-summary-wrap-col">
                        <div class="fare-summary-wrap">
                            <div class="fare-summary-sm">
                                <h3>Fare Summary</h3>
                                <div class="fare-block">
                                    <h4>Base Fare</h4>
                                    <ul>
                                        <li>
                                            <span class="pay-cat pay-cat-fees">{{resultView.quote.model.components.base.salesPrice.currency}} {{resultView.quote.model.components.base.salesPrice.value}}</span>
                                            <span class="pay-amount">{{resultView.quote.model.components.base.salesPrice.currency}} {{baseFare}}</span>
                                        </li>
                                        <li>
                                            <ul *ngIf="miniCartData" class="minicartItems">
                                                <li *ngFor="let cartItem of miniCartData">
                                                    <span class="itemText">{{cartItem.itemLabel}}</span>
                                                    <span class="itemQty">x {{cartItem.itemQty}}</span>
                                                    <span class="itemCost">{{cartItem.itemAmount}}</span>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="total-row">
                                            <span class="pay-cat">Total</span>
                                            <span class="pay-amount">{{resultView.quote.model.components.base.salesPrice.currency}} {{total}}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="coupon-block">
                                <h5>COUPON CODE</h5>
                                <div class="form-field">
                                    <input type="text" class="form-control">
                                    <!-- <span class="coupon-applied">Applied</span> -->
                                </div>
                            </div>
                            <div class="terms-checkbox terms-checkbox-mobile" *ngIf="isMobile">
                                <div class="custom-control custom-checkbox">
                                    <input (click)="checkterms()" type="checkbox" name="agree_box" required class="customcheckbox custom-control-input" id="termschecbox" >
                                    <label class="custom-control-label" for="termschecbox">I agree to this vehicle’s <a
                                            [routerLinkActive]="['active']" routerLink="/terms-and-conditions" target="_blank"><strong>Terms & Conditions</strong></a> and Payments & Cancellations
                                        Policy
                                        * Read these now You are agreeing to our <a [routerLinkActive]="['active']" routerLink="/gdpr-statement" target="_blank">Privacy Policy</a></label>
                                </div>
                            </div>
                            <!-- <div class="terms-checkbox terms-checkbox-mobile visible-sm visible-xs">
                                <div class="custom-control custom-checkbox">
                                    <input (click)="checktermsMobile()" type="checkbox" name="agree_box_mobile" required class="customcheckbox custom-control-input" id="termschecboxMobile" >
                                    <label class="custom-control-label" for="termschecboxMobile">I agree to this vehicle’s <a
                                            [routerLinkActive]="['active']" routerLink="/terms-and-conditions" target="_blank"><strong>Terms & Conditions</strong></a> and Payments & Cancellations
                                        Policy
                                        * Read these now You are agreeing to our <a [routerLinkActive]="['active']" routerLink="/gdpr-statement" target="_blank">Privacy Policy</a></label>
                                </div>
                            </div> -->

                            <div class="send-quote">
                                <h3><span><img src="./assets/images/share-quote.svg"></span> Send me Quote</h3>
                                <div class="send-quote-fields">
                                    <input type="email" id="sendmeemail" class="form-control" placeholder="youremail@gmail.com">
                                    <span id="sendquoteeerro" style="color: red;font-size: 9px"></span>
                                    <button type="button" class="btn" (click)="sendQuoteEmail()">SEND</button>
                                </div>
                            </div>

                            <button class="btn btn-make-payment hidden-sm hidden-xs btn-make-payment-primary"
                                    id="bookingFormButton" type="submit">Proceed To payment
                            </button>
                            <div id="alertmsg">

                            </div>
                            <div class="mobile-payment-foot visible-sm visible-xs">
                                <div class="mobile-total-amount" id="openFareSummaryMobile">
                                    <span><small>{{resultView.quote.model.components.base.salesPrice.currency}}</small><strong>{{total}}</strong></span>
                                </div>
                                <button class="btn btn-make-payment btn-make-payment-primary">Continue</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </section>
</section>

<!-- GallerylightBox -->
<div class="modal modal-lightbox fade" id="GallerylightBox" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span>
                </button>
                <h4 class="modal-title">Awesome Classic Camper (2-5 persons) <span id="showSlideCount"></span></h4>
            </div>
            <div class="modal-body">
                <div class="gallary-lightbox-slider gallary-lightbox-view" *ngIf="resultView">
                    <div *ngFor="let images of resultView.quote.model.version.vehicle.moreImages"
                         class="gallary-lightbox-item">
                        <figure><img src="{{images.image}}" alt="vehicle"></figure>
                    </div>
                </div>
                <div class="gallary-lightbox-slider-nav gallary-lightbox-view" *ngIf="resultView">
                    <div *ngFor="let images of resultView.quote.model.version.vehicle.moreImages"
                         class="gallary-lightbox-nav-item">
                        <figure><img src="{{images.image}}" alt="vehicle"></figure>
                    </div>
                </div>
                <div class="text-center" style="display: none">
                    <button class="btn btn-cust btn-primary">Select</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="layer2" style="display: none"></div>
<div class="alert2" style="display: none" >
    <h2 id="alerttitle2"></h2>
    <button class="btn alert-close" (click)="closeAlert()"><img src="assets/images/close.svg"></button>
    <p id="alertContent2"></p>
    <div id="successcontent2">
        <h2>Submitted Successfully</h2>
        <h3>Thank you for choosing</h3>
        <h4>Affordable Motorhome Holidays</h4>
        <p>we value your bussiness and look
            forward  to assisting you!</p>
    </div>
    <div class="alertbtns" style="margin-top: 15px">
        <button id="applyCoupon" class="red" (click)="closeAlert()">Okay</button>
    </div>
</div>