import {Component, OnDestroy, OnInit} from '@angular/core';
import * as $ from 'jquery';
import {NavigationEnd, Router, ActivatedRoute} from '@angular/router';
import {filter} from 'rxjs/internal/operators';
import {HttpClient} from '@angular/common/http';
import {DataService, AlertService, AuthenticationService} from '@app/_services';
import {apiUrls} from '@app/shared/app.constants';
import {environment} from '@environments/environment';
import {FormGroup, FormBuilder, FormControl, Validators, FormArray} from '@angular/forms';
import {MessageService} from '@app/_services/message.service';
import {HeaderService} from '@app/shared/header/header.service';
import {DestinationService} from '@app/destinations/destinations.service';
import {MustMatch} from '@app/_helpers/must-match.validator';
import {first} from 'rxjs/operators';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
    registerForm: FormGroup;
    submitted = false;
    submitted2 = false;
    loginForm: FormGroup;
    redirectTo: any;
    alertMessage: any;
    constructor(private http: HttpClient,
                private fb: FormBuilder,
                private authenticationService: AuthenticationService,
                private route: ActivatedRoute,
                private dataService: DataService,
                private headerService: HeaderService,
                private messageService: MessageService,
                private destinationService: DestinationService,
                private router: Router,
                private formBuilder: FormBuilder) {

    }

    ngOnInit(): void {
        this.registerForm = this.formBuilder.group({
            firstName: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            mobile: ['', [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
            password: ['', [Validators.required, Validators.minLength(6)]],
            confirmPassword: ['', Validators.required],
        }, {
            validator: MustMatch('password', 'confirmPassword')
        });
        if (window.localStorage.getItem('redirect')) {
            this.redirectTo = window.localStorage.getItem('redirect');
        } else {
            this.redirectTo = '';
        }
    }

    get f() {
        return this.registerForm.controls;
    }


    onSubmit() {
        //console.log(apiUrls);
        this.submitted = true;
        // stop here if form is invalid
        if (this.registerForm.invalid) {
            return;
        }

        const subs = this.http.post<any>(`${environment.apiUrl}/${apiUrls.register}`,
            {
                'email': this.f.email.value,
                'firstname': this.f.firstName.value,
                'phone': this.f.mobile.value,
                'password': this.f.password.value
            }).subscribe(res => {
            subs.unsubscribe();
            this.registerForm.reset();
            this.alertMessage = res.message;
            this.showAlert();
            //this.login(this.f.email.value, this.f.password.value);
        }, (err) => {

            this.alertMessage = err;
            this.showAlert();
        }, () => {
        });
    }

    login(username: any, password: any) {
        this.authenticationService.login(username, password)
            .pipe(first())
            .subscribe(
                data => {
                    console.log(data);
                    this.dataService.saveUserToken(data);
                    if (this.redirectTo !== '' && this.redirectTo !== this.router.url) {
                        this.router.navigate([this.redirectTo]);
                    } else {
                        this.router.navigate(['/my-account']);
                    }
                },
                (err) => {

                });
    }
    showAlert(){
        $('.layer').show();
        $('.alert').show();
        $('#alerttitle').html("Alert !!!");
        $('#alertContent').html(this.alertMessage);
    }
    closeAlert(){
        $('.layer').hide();
        $('.alert').hide();
        $('#alerttitle').html("");
        $('#alertContent').html("");
    }
}
