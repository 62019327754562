<header class="header">
    <div class="container">
        <div class="header_in">
            <div class="logo">
                <a routerLink=""><img src="./assets/images/logo.png" class="visible-md visible-lg" alt="Affordable Motorhome Holidays"> <img class="visible-sm visible-xs" src="assets/images/logo-icon.png" alt="Affordable Motorhome Holidays"></a>
            </div>
            <div class="nav">
                <div class="mobile-menu-wrap" id="mainMenu">
                    <div class="mobile-menu-in">
                        <ul class="primary_nav">
                            <li style="display: none" class="dropdown destinations-dropdown">
                                <a id="destinations" data-target="#" href="#" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Destinations <i class="fa fa-angle-down" aria-hidden="true"></i></a>
                                <div class="dropdown-menu dropdown-menu-right dropdown-cust dropdown-destinations" aria-labelledby="destinations">
                                    <ul>
                                        <li *ngFor="let country of countries">
                                            <a [routerLinkActive]="['active']" routerLink="/destinations/{{country.name}}">
                                                <span class="country-name">{{country.name}}</span>
                                                <span class="flag flag-{{country.country_code}} flag-24"></span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li><a [routerLinkActive]="['active']" routerLink="/destinations">Destinations</a> </li>
                            <li><a [routerLinkActive]="['active']" routerLink="/partners">Partners</a> </li>
                            <li><a [routerLinkActive]="['active']" routerLink="/fleet">Fleet</a></li>
                            <li><a [routerLinkActive]="['active']" routerLink="/about-us">About Us</a></li>
                            <li><a [routerLinkActive]="['active']" routerLink="/contact-us">Contact Us</a></li>

                        </ul>
                    </div>
                    <div class="nav-backdrop">&nbsp;</div>
                </div>
                <div class="mobile-menu-wrap my-account-menu-wrap hidden-lg hidden-md" id="myAccountMenu">
                    <div class="mobile-menu-in dropdown-MyAccount">
                        <div *ngIf="userDetails" class="user-welcome-mobile">Hi, {{userDetails.name}}&nbsp;{{userDetails.last_name}}</div>
                        <ul class="">
                            <li>
                                <a *ngIf="userDetails" [routerLinkActive]="['active']" routerLink="/my-account">
                                    <img src="assets/images/user-acc-1.svg" alt="Menu">
                                    <span>My Account</span>
                                </a>
                                <a *ngIf="!userDetails" (click)="openlogin()">
                                    <img src="assets/images/user-acc-1.svg" alt="Menu">
                                    <span>My Account</span>
                                </a>
                            </li>
                            <li>
                                <a *ngIf="userDetails" [routerLinkActive]="['active']" routerLink="/my-bookings">
                                    <img src="assets/images/user-acc-2.svg" alt="Menu">
                                    <span> My Bookings</span>
                                </a>
                                <a *ngIf="!userDetails" (click)="openlogin()">
                                    <img src="assets/images/user-acc-2.svg" alt="Menu">
                                    <span> My Bookings</span>
                                </a>
                            </li>
                            <!-- <li *ngIf="userDetails">
                                <a href="javascript:void(0)" class="logout_btn" (click)="logout()">Logout</a>
                            </li> -->
                            <li><a class="logout_btn" (click)="openlogin()">Login</a></li>
                        </ul>
                    </div>
                    <div class="nav-backdrop">&nbsp;</div>
                </div>
                <ul class="primary_nav">
                    <!-- <li class="dropdown">
                        <a id="language" data-target="#" href="#" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><span class="language-selected"><img src="assets/images/country-1.png" alt="country-1"></span></a>
                        <ul class="dropdown-menu dropdown-menu-right dropdown-cust dropdown-language" aria-labelledby="language">
                            <li title="中文"><a href="#"><span class="con-flag flag-cn"></span><span class="con-name">中文</span></a></li>
                            <li title="Français"><a href="#"><span class="con-flag flag-fr"></span><span class="con-name">Français</span></a></li>
                            <li title="Deutsch"><a href="#"><span class="con-flag flag-de"></span><span class="con-name">Deutsch</span></a></li>
                            <li title="Español"><a href="#"><span class="con-flag flag-es"></span><span class="con-name">Español</span></a></li>
                            <li title="Italiano"><a href="#"><span class="con-flag flag-it"></span><span class="con-name">Italiano</span></a></li>
                            <li title="Português"><a href="#"><span class="con-flag flag-pt"></span><span class="con-name">Português</span></a></li>
                            <li title="Nederlandse"><a href="#"><span class="con-flag flag-nl"></span><span class="con-name">Nederlandse</span></a></li>
                            <li title="한국어 [韓國語]"><a href="#"><span class="con-flag flag-ko"></span><span class="con-name">한국어 [韓國語]</span></a></li>
                        </ul>
                    </li> -->
                    <li class="dropdown">
                        <a id="currencies" data-target="#" href="#" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">$₤€</a>
                        <ul class="dropdown-menu dropdown-menu-right dropdown-cust" aria-labelledby="currencies">
                            <li>
                                <h5>Top currencies</h5>
                            </li>
                            <li role="separator" class="divider"></li>
                            <li><a href="#" class="text-bold"><span>My home currency</span><strong>INR</strong></a></li>
                            <li><a href="#" class="text-bold"><span>Supplier’s currency</span><strong>$€₤</strong></a></li>
                            <li><a href="#"><span>Australian Dollar</span><strong>AUD</strong></a></li>
                            <li><a href="#"><span>US Dollar</span><strong>USD</strong></a></li>
                            <li><a href="#"><span>Euro</span><strong>EUR</strong></a></li>
                            <li><a href="#"><span>New Zealand Dollar</span><strong>NZD</strong></a></li>
                            <li><a href="#"><span>UK Pound</span><strong>GBP</strong></a></li>
                            <li><a href="#"><span>Canadian Dollar</span><strong>CAD</strong></a></li>
                            <li><a href="#"><span>Renminbi</span><strong>CNY</strong></a></li>
                        </ul>
                    </li>
                    <li class="visible-xs visible-sm">
                        <a href="#" id="m-account-menu-toggle"><span class="user-p-pic"><img src="assets/images/mobile-user.png"></span></a>
                        <!-- <a href="#"><span class="signin-btn">Sign In</span></a> -->
                    </li>
                    <li class="mobile-menu-btn">
                        <a href="#" id="m-menu-toggle"><img src="assets/images/menu.svg" alt="Menu"></a>
                    </li>
                    <li class="dropdown hidden-xs hidden-sm dropdown-MyAccount">
                        <a *ngIf="userDetails" id="MyAccount" data-target="#" href="#" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">{{userDetails.name}}&nbsp;{{userDetails.last_name}} <i class="fa fa-angle-down" aria-hidden="true"></i></a>
                        <a *ngIf="!userDetails" (click)="openlogin()" role="button" aria-haspopup="true" aria-expanded="false">My Account <i class="fa fa-angle-down" aria-hidden="true"></i></a>
                        <ul *ngIf="userDetails" class="dropdown-menu dropdown-cust dropdown-menu-right" aria-labelledby="MyAccount">
                            <li>
                                <a *ngIf="userDetails" [routerLinkActive]="['active']" routerLink="/my-account">
                                    <img src="assets/images/user-acc-1.svg" alt="Menu">
                                    <span>My Account</span>
                                </a>
                                <a *ngIf="!userDetails" (click)="openlogin()">
                                    <img src="assets/images/user-acc-1.svg" alt="Menu">
                                    <span>My Account</span>
                                </a>
                            </li>
                            <li>
                                <a *ngIf="userDetails" [routerLinkActive]="['active']" routerLink="/my-bookings">
                                    <img src="assets/images/user-acc-2.svg" alt="Menu">
                                    <span> My Bookings</span>
                                </a>
                                <a *ngIf="!userDetails" (click)="openlogin()">
                                    <img src="assets/images/user-acc-2.svg" alt="Menu">
                                    <span> My Bookings</span>
                                </a>
                            </li>
                            <!-- <li>
                                <a *ngIf="userDetails" [routerLinkActive]="['active']" routerLink="/my-favourates">
                                    <img src="assets/images/user-acc-3.svg" alt="Menu">
                                    <span>My Favorites</span>
                                </a>
                                <a *ngIf="!userDetails" (click)="openlogin()">
                                    <img src="assets/images/user-acc-3.svg" alt="Menu">
                                    <span>My Favorites</span>
                                </a>
                            </li>
                            <li>
                                <a *ngIf="userDetails" [routerLinkActive]="['active']" routerLink="/my-reviews">
                                    <img src="assets/images/user-acc-4.svg" alt="Menu">
                                    <span>My Reviews</span>
                                </a>
                                <a *ngIf="!userDetails" (click)="openlogin()">
                                    <img src="assets/images/user-acc-4.svg" alt="Menu">
                                    <span>My Reviews</span>
                                </a>
                            </li> -->
                            <li *ngIf="userDetails">
                                <a href="javascript:void(0)" class="logout_btn" (click)="logout()">Logout</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</header>
<!-- /header -->
<app-popuplogin></app-popuplogin>
