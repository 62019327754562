<!-- Login -->
<div class="login-signup show">
    <div class="login-signup-centered" id="forgotFromdiv">
        <div class="log-sign-main">
            <h2>Forgot Password</h2>
            <div class="log-sign-form">
                <form class="form" [formGroup]="forgotFrom" (ngSubmit)="onSubmitLogin()">
                    <div class="form-group">
                        <input class="form-control" type="email" id="email" formControlName="email"
                               placeholder="Your Email"
                               [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">Email is required</div>
                            <div *ngIf="f.email.errors.email">Email must be a valid email address
                            </div>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-sign">Submit</button>
                </form>
                <div class="signup-link">If you have an account login?<a [routerLinkActive]="['active']" routerLink="/login" >Login</a></div>
            </div>
        </div>
    </div>
</div>
<div class="layer" style="display: none"></div>
<div class="alert" style="display: none">
    <h2 id="alerttitle">Alert !!!</h2>
    <button class="btn alert-close" (click)="closeAlert()"><img src="assets/images/close.svg"></button>
    <p id="alertContent"></p>
    <div class="alertbtns" style="margin-top: 15px">
        <button id="applyCoupon" class="red" (click)="closeAlert()">Okay</button>
    </div>
</div>