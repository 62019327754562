import {Component, OnDestroy, OnInit} from '@angular/core';
import * as $ from 'jquery';
import {NavigationEnd, Router, ActivatedRoute} from '@angular/router';
import {filter} from 'rxjs/internal/operators';
import {HttpClient} from '@angular/common/http';
import {DataService, AlertService, AuthenticationService} from '@app/_services';
import {apiUrls} from '@app/shared/app.constants';
import {environment} from '@environments/environment';
import {FormGroup, FormBuilder, FormControl, Validators, FormArray} from '@angular/forms';
import {MessageService} from '@app/_services/message.service';
import {HeaderService} from '@app/shared/header/header.service';
import {DestinationService} from '@app/destinations/destinations.service';
import {MustMatch} from '@app/_helpers/must-match.validator';
import {first} from 'rxjs/operators';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    submitted = false;
    loginForm: FormGroup;
    redirectTo: any;

    constructor(private http: HttpClient,
                private fb: FormBuilder,
                private authenticationService: AuthenticationService,
                private route: ActivatedRoute,
                private dataService: DataService,
                private headerService: HeaderService,
                private messageService: MessageService,
                private destinationService: DestinationService,
                private router: Router,
                private formBuilder: FormBuilder) {

    }

    ngOnInit(): void {
        this.loginForm = this.formBuilder.group({
            username: ['', [Validators.required]],
            password: ['', [Validators.required, Validators.minLength(6)]],
        });
        if (window.localStorage.getItem('redirect')) {
            this.redirectTo = window.localStorage.getItem('redirect');
        } else {
            this.redirectTo = '';
        }
    }

    get f() {
        return this.loginForm.controls;
    }

    onSubmitLogin() {

        this.submitted = true;
        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.authenticationService.login(this.f.username.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                data => {
                    console.log(data);
                    this.dataService.saveUserToken(data);
                    if (this.redirectTo !== '' && this.redirectTo !== this.router.url) {
                        this.router.navigate([this.redirectTo]);
                    } else {
                        this.router.navigate(['/my-account']);
                    }

                },
                (err) => {
                    alert(err);
                    //this.flashMessage.show("Invalid credentials", {cssClass: 'alert-danger', timeout: 10000}); //alert-success
                });

    }

}
