<section id="main" class="payment-main-wrap gray-bg">
    <div class="mobile-sub-nav visible-sm visible-xs">
        <a href="#" class="go-back-btn"><img src="./assets/images/left-short-arrow-mobile.svg"></a>
        <h2>Payment</h2>
    </div>
    <div class="payment-progress">
        <div class="container">
            <div class="payment-progress-step active">
                <span class="step-number">1</span>
                <span class="step-name">Review Booking</span>
            </div>
            <div class="payment-progress-step">
                <span class="step-number">2</span>
                <span class="step-name">Payment Info</span>
            </div>
            <div class="payment-progress-step">
                <span class="step-number">3</span>
                <span class="step-name">Booking Success</span>
            </div>
        </div>
    </div>
    <section class="payment-info-wrap clearfix">
        <div class="container">
            <div class="row">
                <div class="col-sm-9 fare-details-wrap-col">
                    <div class="vehicle-item vehicle-item-plain clearfix">
                        <div class="clearfix">
                            <div class="vehicle-images-gallery hidden-sm hidden-xs">
                                <div class="vehicle-gallery-min">
                                    <div *ngFor="let images of resultView.quote.model.version.vehicle.moreImages" class="v-gallery-min-item">
                                        <figure><img src="{{images.image}}" alt="vehicle"></figure>
                                    </div>
                                </div>
                                <div class="v-gallery-min-num"></div>
                                <a href="javascript:void(0)" class="gallery-fullmode" data-toggle="modal" data-target="#GallerylightBox"><img src="./assets/images/full-screen.svg"></a>
                            </div>

                            <div class="vehicle-details">
                                <div class="vehicle-title">
                                    <h3>{{resultView.quote.model.version.vehicle.name}}</h3>
                                    <!-- <div class="vehicle-rating hidden-sm hidden-xs"><img src="./assets/images/rating.png">
                                    </div> -->
                                    <div style="display: none" class="vehicle-overall-rating hidden-sm hidden-xs"><span>Good, 72%</span> | 500+ Reviews
                                    </div>
                                </div>
                                <div style="display: none" class="veh-confirm-avail">
                                    <span class="confirm-badge">24h confirmation</span>
                                    <span class="avail-num">Available : 3</span>
                                </div>
                                <div class="vehicle-features">
                                    <ul>
                                        <li>
                                            <span class="v-feature-icon"><img src="./assets/images/bed.svg"></span>
                                            <span class="v-feature-name">{{resultView.quote.model.version.vehicle.capacity.bedCapacity}}</span>
                                        </li>
                                        <li>
                                            <span class="v-feature-icon"><img src="./assets/images/toilet.svg"></span>
                                            <span class="v-feature-name">Toilet</span>
                                        </li>
                                        <li>
                                            <span class="v-feature-icon"><img src="./assets/images/shower.svg"></span>
                                            <span class="v-feature-name">Shower</span>
                                        </li>
                                        <li>
                                            <span class="v-feature-icon"><img
                                                    src="./assets/images/manual-transmission.svg"></span>
                                            <span class="v-feature-name">Manual</span>
                                        </li>
                                        <li>
                                            <span class="v-feature-icon"><img src="./assets/images/caravan.svg"></span>
                                            <span class="v-feature-name">{{resultView.quote.model.version.vehicle.vehicleAge.text}}</span>
                                        </li>
                                    </ul>
                                </div>
                                <!-- <div class="vehicle-benifits hidden-sm hidden-xs">
                                    <ul>
                                        <li><span class="v-benefits-label">Benefits :</span></li>
                                        <li><span class="v-benefit-icon"><img
                                                src="./assets/images/check.svg"></span><span class="v-benefit-name">Equipped</span></li>
                                        <li><span class="v-benefit-icon"><img
                                                src="./assets/images/check.svg"></span><span class="v-benefit-name">Esky</span></li>
                                        <li><span class="v-benefit-icon"><img
                                                src="./assets/images/check.svg"></span><span class="v-benefit-name">Outdoor table/chairs</span></li>
                                        <li><span class="v-benefit-icon"><img
                                                src="./assets/images/check.svg"></span><span class="v-benefit-name">Portable</span></li>
                                        <li><span class="v-benefit-icon"><img
                                                src="./assets/images/check.svg"></span><span class="v-benefit-name">Outdoor table/chairs</span></li>
                                    </ul>
                                </div> -->
                                <a class="full-specs-btn-sm visible-xs visible-sm" href="#"><i class="fa fa-plus"
                                                                                               aria-hidden="true"></i>
                                                                                               View Full Details</a>
                                <div class="vehicle-more-details hidden-sm hidden-xs vehicle-more-details-top-space">
                                    <ul class="nav nav-tabs">
                                        <li><a data-toggle="tab" href="#FullSpecs3">View Full Details <i class="fa fa-plus"
                                                                                                  aria-hidden="true"></i></a>
                                        </li>
                                        <li><a data-toggle="tab" href="#Features3">Features</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="vehicle-more-details-content">
                            <div class="tab-content">
                                <div id="FullSpecs3" class="tab-pane fade">
                                    <div class="v-details-wrap">
                                        <div class="v-details-row">
                                            <div class="v-details-block">

                                                <h4>Vehicle Specifications</h4>
                                                <ul>
                                                    <li>
                                                        <span>Vehicle Age</span><span>{{resultView.quote.model.version.vehicle.vehicleAge.text}}</span>
                                                    </li>
                                                    <li>
                                                        <span>Vehicle Style</span><span>{{resultView.quote.model.version.vehicle.vehicleStyle.text}}</span>
                                                    </li>
                                                    <li>
                                                        <span>Vehicle Length</span><span>{{resultView.quote.model.version.vehicle.length.text}}</span>
                                                    </li>
                                                    <li>
                                                        <span>Transmission</span><span>{{resultView.quote.model.version.vehicle.transmissionProperties[0].text}}</span>
                                                    </li>
                                                    <li>
                                                        <span>Fuel Type</span><span>{{resultView.quote.model.version.vehicle.energyProperties[0].text}}</span>
                                                    </li>
                                                </ul>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div id="Features3" class="tab-pane fade">
                                    <div class="v-details-wrap">
                                        <div class="v-details-row">
                                            <div class="v-details-block">
                                                <ul>
                                                    <li>
                                                        <span class="width200">Living Amenities </span><span>{{resultView.amenities}}</span>
                                                    </li>
                                                    <li><span class="width200">Vehicle Features </span><span>{{resultView.vehicle_features}}</span>
                                                    </li>
                                                    <li>
                                                        <span class="width200">Equipment </span><span>{{resultView.equipment_features}}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="booking-summary-flow">
                            <div class="booking-summary-flow-item">
                                <span class="location-d-icon"><img src="../assets/images/passenger.svg"></span>
                                <div class="location-details-txt">
                                    <span class="location-details-label location-details-passenger">Passengers</span>
                                    <span class="location-details-point">Sleeps 2</span>
                                </div>
                            </div>
                            <div class="booking-summary-flow-pick-drop">
                                <div class="booking-summary-flow-pick-drop-item">
                                    <span class="location-d-icon"><img src="../assets/images/location_van_red.svg"></span>
                                    <div class="location-details-txt">
                                        <span class="location-details-label">PICK UP</span>
                                        <span class="location-details-point">Brisbane</span>
                                        <span class="location-details-extra">San Antonio, TX 78209</span>
                                    </div>
                                </div>
                                <div class="booking-summary-flow-pick-drop-item">
                                    <span class="location-d-icon"><img src="../assets/images/location_van_green.svg"></span>
                                    <div class="location-details-txt">
                                        <span class="location-details-label">DROP OFF</span>
                                        <span class="location-details-point">Brisbane</span>
                                        <span class="location-details-extra">San Antonio, TX 78209</span>
                                    </div>
                                </div>
                            </div>
                            <div class="booking-summary-flow-date-time">
                                <div class="booking-summary-flow-date-time-item">
                                    <span class="location-d-icon"><img src="../assets/images/clock.svg"></span>
                                    <div class="location-details-txt">
                                        <span class="location-details-label">Pick up date & TIme</span>
                                        <span class="location-details-point">12 Apr'20 <span>|</span> 12:30 PM</span>
                                    </div>
                                </div>
                                <div class="booking-summary-flow-date-time-item">
                                    <span class="location-d-icon"><img src="../assets/images/clock.svg"></span>
                                    <div class="location-details-txt">
                                        <span class="location-details-label">Drop off date & time</span>
                                        <span class="location-details-point">12 Apr'20 <span>|</span> 12:30 PM</span>
                                    </div>
                                </div>
                            </div>
                            <div class="booking-summary-flow-map">
                                <span class="location-details-label">Pick Up Location</span>
                                <div class="booking-summary-flow-map-location"  data-toggle="modal" data-target="#locationView"><img src="../assets/images/map-location2.jpg"></div>
                            </div>
                        </div>

                    </div>

                    <!-- Extra Items -->
                    <!-- Desktop -->

                    <div class="extra-items-wrap boxwrap" *ngFor="let collections of resultView.loopcollection | keyvalue">
                        <h3 class="sub-heading-3">{{collections.key}}</h3>
                        <div class="extra-items-block itemBox">

                            <div *ngFor="let colection of collections.value | keyvalue">
                                <div class="extra-item-row row component-row">
                                    <div class="col-12 col-sm-6">
                                        <div class="subkey">
                                            {{colection.key}}
                                            <ul class="dash" *ngIf="colection.value.subchilds.length > 0">
                                                <li class="small" *ngFor="let subTitle of colection.value.subchilds">
                                                    {{subTitle}}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-2">
                                        <div class="row customize-select" *ngIf="colection.value.included">
                                            <div class="col-4">
                                                <span class="fa fa-check text-success" aria-hidden="true"></span>
                                            </div>
                                        </div>
                                        <div class="row customize-select" *ngIf="!colection.value.included">
                                            <div class="col-4" *ngIf="colection.value.function=='option'">

                                            </div>
                                            <div class="col-4" *ngIf="colection.value.function=='select-quantity'">
                                                <!-- input type="number" min="0" [attr.data-key]="colection.value.key" [attr.data-cost]="colection.value.cost" (change)="addToCartselect($event)" -->
                                                <div class="number-value1">
                                                    1
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div class="col-12 col-sm-4" style="text-align: right" *ngIf="colection.value.cost!=''">
                                        {{colection.value.currency}}{{colection.value.cost}}
                                    </div>
                                    <div class="col-12 col-sm-4" style="text-align: right" *ngIf="colection.value.cost==''">
                                        &nbsp;
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>




                </div>
                <div class="col-sm-3 fare-summary-wrap-col">
                    <div class="fare-summary-wrap">
                        <div class="fare-summary-sm">
                            <h3>Fare Summary</h3>
                            <div class="fare-block">
                                <h4>Base Fare</h4>
                                <ul>
                                    <li>
                                        <span class="pay-cat pay-cat-fees">{{resultView.quote.model.components.base.salesPrice.currency}} {{resultView.quote.model.components.base.salesPrice.value}}</span>
                                        <span class="pay-amount">{{resultView.quote.model.components.base.salesPrice.currency}} {{baseFare}}</span>
                                    </li>
                                    <li class="total-row">
                                        <span class="pay-cat">Total</span>
                                        <span class="pay-amount">{{resultView.quote.model.components.base.salesPrice.currency}} {{total}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <button class="btn btn-make-payment hidden-sm hidden-xs btn-make-payment-primary" (click)="proceesToPayment()">Proceed To payment
                        </button>
                        <div id="alertmsg">

                        </div>
                        <div class="mobile-payment-foot visible-sm visible-xs">
                            <div class="mobile-total-amount" id="openFareSummaryMobile">
                                <span><small>AU$</small><strong>158.80</strong></span>
                            </div>
                            <button class="btn btn-make-payment btn-make-payment-primary">Continue</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section>
</section>

<div class="modal modal-cust fade" tabindex="-1" id="locationView" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Location</h4>
            </div>
            <div class="modal-body">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4855.352993150176!2d13.294153495293497!3d52.521193379348624!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a85125754291f3%3A0x8bb8ae492a79fe1a!2sCharlottenburg%20Palace!5e0!3m2!1sen!2sin!4v1593715757217!5m2!1sen!2sin"
                        frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0" class="map-frame"></iframe>
                <div class="modal-footer">
                    <button class="btn btn-primary" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
<!-- /.modal -->


<!-- GallerylightBox -->
<div class="modal modal-lightbox fade" id="GallerylightBox" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span>
                </button>
                <h4 class="modal-title">Awesome Classic Camper (2-5 persons) <span id="showSlideCount"></span></h4>
            </div>
            <div class="modal-body">
                <div class="gallary-lightbox-slider gallary-lightbox-view">
                    <div *ngFor="let images of resultView.quote.model.version.vehicle.moreImages" class="gallary-lightbox-item">
                        <figure><img src="{{images.image}}" alt="vehicle"></figure>
                    </div>
                </div>
                <div class="gallary-lightbox-slider-nav gallary-lightbox-view">
                    <div *ngFor="let images of resultView.quote.model.version.vehicle.moreImages" class="gallary-lightbox-nav-item">
                        <figure><img src="{{images.image}}" alt="vehicle"></figure>
                    </div>
                </div>
                <div class="text-center" style="display: none">
                    <button class="btn btn-cust btn-primary">Select</button>
                </div>
            </div>
        </div>
    </div>
</div>
<form method="POST" action="https://mdepayments.epdq.co.uk/ncol/test/orderstandard.asp" id="form" name="form">
    <!-- general parameters -->
    <input type="hidden" name="AMOUNT" id="amount" value="">
    <input type="hidden" name="CURRENCY" id="currency" value="GBP">
    <input type="hidden" name="LANGUAGE" id="language" value="en_US">
    <input type="hidden" name="ORDERID" id="orderid" value="">
    <input type="hidden" name="PSPID" value="amhtest999">
    <input type="hidden" name="SHASIGN" id="shasign" value="">
</form>