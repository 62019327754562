<section id="main">
    <div id="banner-inner" style="background:url({{countryInfo.itenaries_banner}})">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="itinerarie_name_rating">
                        <h2>{{itenary.title}}</h2>

                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" style="display: none;">
                    <div class="itinerarie_distance_time">
                        <div class="distance_time_iteam">
                            <figure><img src="assets/images/location_distance.svg"></figure>
                            <span>Distance<strong>3250 km</strong></span>
                        </div>
                        <div class="distance_time_iteam">
                            <figure><img src="assets/images/clock_icon.svg"></figure>
                            <span>Est Driving <strong>Time 14-28 days</strong></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="itinerarie_overview itinerarie_overviewdetail">
        <div class="container">

            <div class="overview_tabs_wrap">

                <div class="" *ngFor="let roadtrip of roadtripsList | keyvalue : returnZero; let index = index"
                     [class.active]="index == 0" id="tripdiv_{{roadtrip.value.id}}">
                    <div class="leg_header ">
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <h4><strong>{{roadtrip.key}} </strong> {{roadtrip.value.start_location}} <span *ngIf="roadtrip.value.end_location"> to
                                {{roadtrip.value.end_location}}</span></h4>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div class="itinerarie_distance_time">
                                <div class="distance_time_iteam">
                                    <figure><img src="assets/images/location_distance.svg"></figure>
                                    <span>Distance<strong>{{roadtrip.value.distance}} {{roadtrip.value.distance_unit}}</strong></span>
                                </div>
                                <div class="distance_time_iteam">
                                    <!-- <figure><img src="assets/images/clock_icon.svg"></figure> -->
                                    <!-- <span>Est Driving Time <strong>{{roadtrip.value.est_driving_days}}</strong></span> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row leg_content">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="overview_text">
                                <p [innerHTML]="roadtrip.value.description"></p>
                                <div class="itinerarie_place_stay" *ngIf="roadtrip.value.place_to_stay">
                                    <figure><img src="assets/images/location_distance.svg"></figure>
                                    <p><strong>A place to stay: </strong>{{roadtrip.value.place_to_stay}}</p>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
    <div style="display: none" class="search-Motorhomes" id="search-Motorhomes">
        <div class="container">
            <h2>Search for Motorhomes</h2>
            <div class="journey-search-main">
                <form>
                    <div class="search-form-wrap">
                        <div class="search-field-country">
                            <label class="search-form-label">Country</label>
                            <div class="country_item">
                                <span class="country_flag flag flag-us"></span>
                                <span class="country_name">United States</span>
                            </div>
                        </div>
                        <div class="search-field-date">
                            <div class="field-fromto-date">
                                <label class="search-form-label">Pick up date</label>
                                <div class="input-group date">
                                    <input type="text" class="form-control field-fromto-date-control startDate"/>
                                </div>
                            </div>
                            <div class="form-calendar-icon">
                                <div class="form-calendar-icon-img"><img src="assets/images/calendar.svg"
                                                                         alt="calendar"></div>
                            </div>
                            <div class="field-fromto-date">
                                <label class="search-form-label">Drop off date</label>
                                <div class="input-group date">
                                    <input type="text" class="form-control field-fromto-date-control endDate"/>
                                </div>
                            </div>
                        </div>
                        <div class="search-field-pass-age">
                            <div class="search-field-passengers">
                                <label class="search-form-label search-form-label-drop">Pick up</label>
                                <span class="label-main">Brisbane</span>
                            </div>
                            <div class="search-field-passengers">
                                <label class="search-form-label search-form-label-drop">Drop Off</label>
                                <span class="label-main">Brisbane</span>
                            </div>
                            <div class="search-field-passengers">
                                <label class="search-form-label search-form-label-drop">Passengers</label>
                                <span class="label-main">Sleeps 2</span>
                            </div>
                        </div>

                        <button class="btn" onclick="window.location.href='search-results.html'">Search</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
<!-- /main -->