import {Component, OnDestroy, OnInit} from '@angular/core';
import * as $ from 'jquery';
import {NavigationEnd, Router, ActivatedRoute} from '@angular/router';
import {filter} from 'rxjs/internal/operators';
import {HttpClient} from '@angular/common/http';
import {DataService} from '@app/_services';
import {apiUrls} from '@app/shared/app.constants';
import {environment} from '@environments/environment';
import {HeaderService} from '../shared/header/header.service';
import {MessageService} from '../_services/message.service';
import {DestinationService} from '../destinations/destinations.service';
import {FormGroup, FormBuilder, FormControl, Validators, FormArray} from '@angular/forms';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

    homeResponse: any;
    countries: {};
    destination: string = 'United Kingdom';
    passengerList: {};
    cities: {};
    filterCountryValue: any;
    startDate: any;
    endDate: any;
    pickupCity: any;
    dropoffCity: any;
    filterPassengers: any;
    filterAge: any;
    public travelForm: FormGroup;
    countryData: any;
    alertMessage: any;
    selectedCountry: any;
    passwordhash: any;
    itenaryCountries: any;

    constructor(private http: HttpClient,
                private fb: FormBuilder,
                private route: ActivatedRoute,
                private dataService: DataService,
                private headerService: HeaderService,
                private messageService: MessageService,
                private destinationService: DestinationService,
                private router: Router) {
        this.travelForm = this.fb.group({
            filter_country: new FormControl('', [Validators.required]),
        })
    }

    ngOnInit() {
        //$.getScript('./assets/js/jquery-2.2.4.min.js');
        $.getScript('./assets/js/datepicker.js');
        //$.getScript('./assets/js/dealsSlider.js');
        //$.getScript('./assets/js/popularSlider.js');
        $.getScript('./assets/js/motorhomeSlider.js');
        $.getScript('./assets/js/partnerSlider.js');
        this.getFilters();
        this.getNoOfPassengers();
        //this.getCountriesData('United Kingdom');
        this.travelForm.controls['filter_country'].setValue(this.destination);
        this.route.queryParams.subscribe(params => {
            this.passwordhash = params['activate'];
            console.log(params);
            if(this.passwordhash){
                this.activateAccount(this.passwordhash);
            }
        });
        this.getItenaries();
    }

    search(event) {
        this.filterCountryValue = this.dataService.getKeyByValue(window.localStorage.getItem('countries'), $('#filterCountry').val());
        this.startDate = $('#startDate').val();
        this.endDate = $('#endDate').val();
        this.pickupCity = $('#pickup_city').val();
        this.dropoffCity = $('#dropoff_city').val();
        this.filterPassengers = $('#filterPassengers').val();
        this.filterAge = $('#filterAge').val();
        if (this.filterAge <= 29) {
            alert('Age should be greater than 30');
        } else {
            const userSearchObj = {
                country: this.filterCountryValue,
                countryName: $('#filterCountry').val(),
                pickup_date: this.startDate,
                dropoff_date: this.endDate,
                dropoffcheck: 'on',
                pickup_city: this.pickupCity,
                dropoff_city: this.dropoffCity,
                no_of_passengers: this.filterPassengers,
                driver_age: this.filterAge

            };
            this.dataService.saveUserSearch(userSearchObj);
            this.router.navigate(['/search/results']);
        }

    }
    activateAccount(passwordhash: any){
        $('.loader').show();
        const subs = this.http.post<any>(`${environment.apiUrl}/${apiUrls.activateAccount}`,
            {
                'hashcode': passwordhash
            }).subscribe(res => {
            subs.unsubscribe();
            if (res) {
                $('.loader').hide();
                if(res.status == true){
                    this.alertMessage = res.message;
                }else{
                    this.alertMessage = res.message;
                }

                this.showAlert("login");
            }
        }, (err) => {
            $('.loader').hide();
        }, () => {
        });
    }
    navigate(destination: string) {
        this.selectedCountry = destination;
        this.getCities(destination);
        //this.router.navigate(['/destinations/' + `${destination}`]);
    }

    getCities(destination: any) {
        $('.loader').show();
        console.log('destination', destination)
        this.destinationService.getCitiesByDestination(destination).subscribe(
            result => {
                this.cities = result.response
                console.log(result.response);
                $('.loader').hide();
            },
            error => {
                console.log(error)
                $('.loader').hide();
                this.messageService.showErrorMessage(error);
            }
        );
    }

    // getCountriesData(destination: any) {
    //     this.destination = (destination) ? destination : this.destination;
    //     this.selectedCountry = this.destination;
    //     $('.loader').show();
    //     this.destinationService.getCountryInfo(this.destination).subscribe(
    //         result => {
    //             this.countryData = result;
    //             $.getScript('./assets/js/popularSlider.js');
    //             $('.loader').hide();
    //         },
    //         error => {
    //             $('.loader').hide();
    //             this.messageService.showErrorMessage(error);
    //         }
    //     );
    // }

    getFilters() {
        $('.loader').show();
        const subs = this.http.get<any>(`${environment.apiUrl}/${apiUrls.homeFilters}`).subscribe(res => {
            subs.unsubscribe();
            if (res) {
                this.homeResponse = res.response;
                if (this.homeResponse.countries) {
                    this.dataService.saveCountries(this.homeResponse.countries);
                    this.countries = this.homeResponse.countries
                }
                if (this.homeResponse.cities) {
                    this.cities = this.homeResponse.cities
                }
                $('.loader').hide();
                //this.filterCountryValue = 46;
            }
        }, (err) => {
            $('.loader').hide();
        }, () => {
            $('.loader').hide();
        });
    }

    getNoOfPassengers() {
        this.headerService.getPassengers().subscribe(
            result => {
                this.passengerList = result.response
                console.log(result);


            },
            error => {
                console.log(error)
                this.messageService.showErrorMessage(error);
            }
        );
    }

    changeToggle() {
        $('#dropoff_city_div').toggle();
    }

    subscribe(formid: any) {
        //$('.loader').show();
        const emailVal = $('#' + formid + '').val();
        const pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i
        if (!pattern.test(emailVal)) {
            //alert('not a valid e-mail address');
            this.alertMessage = 'Not a valid Email address';
            this.showAlert("");
        }​else
        {
            $('.loader').show();
            const subs = this.http.post<any>(`${environment.apiUrl}/${apiUrls.subscribe}`,
                {
                    email: emailVal
                }).subscribe(res => {
                subs.unsubscribe();
                if (res) {
                    $('.loader').hide();
                    this.alertMessage = res.message;
                    this.showAlert("");
                }
            }, (err) => {
                $('.loader').hide();
            }, () => {
                $('.loader').hide();
            });
        }

    }

    returnZero() {
        return 0;
    }

    showAlert(showtype:any) {
        $('.layer').show();
        $('.alert').show();
        $('#alerttitle').html('Alert !!!');
        $('#alertContent').html(this.alertMessage);
        if(showtype == 'login'){
            $('#loginbutton').show();
            $('#applyCoupon').hide();
        }else{
            $('#loginbutton').hide();
            $('#applyCoupon').show();
        }
    }

    closeAlert() {
        $('.layer').hide();
        $('.alert').hide();
        $('#alerttitle').html('');
        $('#alertContent').html('');
    }
    openlogin(){
        this.closeAlert();
        $('.login-signup2').show();
    }

    getItenaries() {
        $('.loader').show();
        const subs = this.http.get<any>(`${environment.apiUrl}/${apiUrls.getItenaries}`).subscribe(res => {
            subs.unsubscribe();
            if (res) {
                this.itenaryCountries = res.citenary;
                $.getScript('./assets/js/popularSlider.js');
                $('.loader').hide();
            }
        }, (err) => {
            $('.loader').hide();
        }, () => {
        });
    }

    truncateChar(text: string): string {
        let charlimit = 110;
        if(!text || text.length <= charlimit )
        {
            return text;
        }

        let without_html = text.replace(/<(?:.|\n)*?>/gm, '');
        let shortened = without_html.substring(0, charlimit) + "...";
        return shortened;
    }
}
