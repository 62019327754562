import {Component, OnInit} from '@angular/core';
import * as $ from 'jquery';
import {NavigationEnd, Router, ActivatedRoute} from '@angular/router';
import {filter} from 'rxjs/internal/operators';
import {HttpClient} from '@angular/common/http';
import {DataService} from '@app/_services';
import {apiUrls} from '@app/shared/app.constants';
import {environment} from '@environments/environment';
import {ResultsService} from './results.service'
import {MessageService} from '../../_services/message.service'
import {FormBuilder, FormControl, FormGroup, FormsModule, Validators} from '@angular/forms';
import {DestinationService} from '../../destinations/destinations.service';

@Component({
    selector: 'app-results',
    templateUrl: './results.component.html',
    styleUrls: ['./results.component.css']
})
export class ResultsComponent implements OnInit {
    [x: string]: any;
    resultsList: any;
    vehicleGalleries: any;
    userSelectedSearch: any;
    noSearchResultsFound: boolean = false;
    filterCountryValue: any;
    endDate: any;
    pickupCity: any;
    cities: {};
    dropoffCity: any;
    filterPassengers: any;
    filterAge: any;
    dropoff_city: string;
    pickup_city: string;
    end_Date: string;
    startDate: Date;
    sortValue: string = 'default';
    vehiclesData: any;
    public travelForm: FormGroup;
    showLoader: boolean = true;
    sortType: any;
    constructor(private http: HttpClient,
                private fb: FormBuilder,
                private route: ActivatedRoute,
                private formBuilder: FormBuilder,
                private dataService: DataService,
                private resultsService: ResultsService,
                private destinationService: DestinationService,
                private messageService: MessageService,
                private router: Router) {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        let usersearchdata: any = JSON.parse(window.localStorage.getItem('userSearch'));
        this.dropoff_city = usersearchdata.pickup_city;
        this.pickup_city = usersearchdata.pickup_city;
        this.startDate = usersearchdata.pickup_date;
        this.endDate = usersearchdata.dropoff_date;
        this.travelFormSearch = this.fb.group({
            sortbyprice: new FormControl('l2h'),
        });
        this.getCities(usersearchdata.countryName);
    }

    ngOnInit() {
        let query = '',
            filter = this.route.snapshot.queryParamMap.get('filter'),
            sort = this.route.snapshot.queryParamMap.get('sort');
        console.log(sort);
        if (sort == 'asc' || sort == 'desc') {
            this.sortValue = sort;
            query = `sort_type=${sort}`;
        } else {
            query = `Vehicle Specifications=${filter}`;
        }
        console.log(this.sortValue);
        // console.log("pickup_date:: ", JSON.parse(usersearchdata).pickup_date);
        this.getResults(query);

        $.getScript('./assets/js/datepickersingle.js');
        $.getScript('./assets/js/priceSlider.js');
        $.getScript('./assets/js/nouislider.js');
        $.getScript('./assets/js/filters.js');

    }

    reviewBooking(vehicleView: any) {
        window.localStorage.setItem('userSearchDetail', vehicleView);
        //console.log(vehicleView);
        this.router.navigate(['/review-booking']);
    }

    sort(event) {
        //.log("event", event.target.value)
        this.sortType = event.target.value;
        this.router.navigate(['/search/results'], {queryParams: {sort: this.sortType}});
    }

    getResults(queryParams?) {
        if (window.localStorage.getItem('userSearch')) {
            $('.loader').show();
            this.userSelectedSearch = window.localStorage.getItem('userSearch');

            this.resultsService.getSearchResults(this.userSelectedSearch, queryParams).subscribe(
                result => {
                    this.resultsList = result.response;

                    if (!result.response.quotes.quotes.results) {
                        this.noSearchResultsFound = true;
                        this.showLoader = false;
                    } else {
                        this.vehiclesData = this.resultsList.quotes.quotes.data;
                        this.showLoader = false;
                    }
                    //console.log("quote", result.response.quotes)

                    $.getScript('./assets/js/vehicleGallery.js');
                    $.getScript('./assets/js/slickcall.js');


                    window.localStorage.removeItem('miniCart');
                    $('.loader').hide();
                },
                error => {
                    //console.log(error)
                    this.messageService.showErrorMessage(error);
                    $('.loader').hide();
                }
            );
        } else {

        }

    }

    applyFilters() {
        let queryParam = '';
        var check1 = $('#customCheck1').prop('checked');
        var check2 = $('#customCheck2').prop('checked');
        var check3 = $('#customCheck3').prop('checked');
        var check4 = $('#customCheck4').prop('checked');
        var check5 = $('#customCheck5').prop('checked');
        var check6 = $('#customCheck6').prop('checked');
        var check7 = $('#customCheck11').prop('checked');
        var check8 = $('#customCheck12').prop('checked');
        var check9 = $('#customCheck13').prop('checked');
        var check10 = $('#customCheck14').prop('checked');
        var check11 = $('#customCheck15').prop('checked');
        var priceRangeMin = $('.noUi-handle-lower .noUi-tooltip').text();
        var priceRangeMax = $('.noUi-handle-upper .noUi-tooltip').text();
        if (check1) {
            queryParam = 'Automatic'
        }
        window.localStorage.setItem('queryParams', JSON.stringify({'Vehicle Specifications': queryParam}));
        this.router.navigate(['/search/results'], {queryParams: {filter: 'type'}});
    }

    search() {
        this.filterCountryValue = $('#filterCountry').val();
        this.startDate = $('#startDate').val();
        this.endDate = $('#endDate').val();
        this.pickupCity = $('#pickup_city').val();
        this.dropoffCity = $('#dropoff_city').val();
        this.filterPassengers = $('#filterPassengers').val();
        this.filterAge = $('#filterPassengers').val();

        const userSearchObj = {
            country: this.filterCountryValue,
            pickup_date: this.startDate,
            dropoff_date: this.endDate,
            dropoffcheck: 'on',
            pickup_city: this.pickupCity,
            dropoff_city: this.dropoffCity,
            no_of_passengers: this.filterPassengers,
            driver_age: this.filterAge

        };
        //console.log(userSearchObj);
        this.dataService.saveUserSearch(userSearchObj);
        this.router.navigate(['/search/results']);
    }

    openGallery(galleries: any) {
        //alert("test");
        $.getScript('./assets/js/unslick.js');
        //$.getScript('./assets/js/slickcall.js');
        this.vehicleGalleries = '';
        // console.log(this.vehicleGalleries);
        this.vehicleGalleries = galleries;
        //console.log(this.vehicleGalleries);
        $.getScript('./assets/js/slickcall.js');
    }
    closeGallary(){
        console.log("Reset gallary");
        this.vehicleGalleries = [];
    }
    flitersToggle() {
        //$("#filtersWrap ").show();
    }

    getCities(destination: any) {
        //$('.loader').show();
        //console.log("destination", destination)
        this.destinationService.getCitiesByDestination(destination).subscribe(
            result => {

                this.cities = result.response
                //console.log(this.cities);
                //$('.loader').hide();
            },
            error => {
                //console.log(error)
                // $('.loader').hide();
                this.messageService.showErrorMessage(error);
            }
        );
    }

    opentab(tabid: any, tabname: any) {
        $('#' + tabname + tabid + '').toggle();
        $('#dicon' + tabid + '').toggleClass("fa-minus");
        $('#' + tabname + tabid + '').parents('.vehicle-item').find('.vehicle-more-details-content').addClass('show');
    }

    closetab(tabid: any, tabname: any) {
        $('#' + tabname + tabid + '').toggle();
        $('#' + tabname + tabid + '').parents('.vehicle-item').find('.vehicle-more-details-content').removeClass('show');
    }
}
