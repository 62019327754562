import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";

@Injectable({
    providedIn: "root"
})
export class MessageService {
    constructor(private toastr: ToastrService) { }

    showMessage(data: any) {
        if (data.code === 200) {
            this.toastr.success(data.message, "Success :", { closeButton: true });
        }
    }

    showInfoMessage(message: string) {
        this.toastr.info(message, "Info :", { closeButton: true });
    }

    showErrorMessage(data: any) {
        switch (data.code) {
            case 401:
                this.toastr.error(data.message, "Error :");
                break;
            case 409:
                this.toastr.info(data.message, "Info :", { closeButton: true, timeOut: 1000 });
                break;
            case 403 || 463:
                this.toastr.error(data.message, "Error :");
                break;
            case 422:
                this.toastr.error(data.message, "Error :");
                break;
            default:
                this.toastr.error(data.message, "Error :");
                break;
        }
    }

    aclError() {
        this.toastr.error(
            "You are not authorized!, Please contact system support",
            "Error :"
        );
    }

    authError() {
        this.toastr.error(
            "You are not authorized to see PreAuthorization Data",
            "Error :"
        );
    }
}
