<section id="main">
    <div class="itineraries_banner">
        <div class="container">
            <div class="itineraries_cont">
                <h2>Welcome to Affordable Fleet Collection</h2>

            </div>
        </div>
    </div>

    <div class="itineraries_list fleetList">
        <div class="container">
            <h3>Campervans; Motorhomes and RV’s</h3>
            <p>For many, the names Campervan, Motorhome and RV all mean the same thing. However there are many differences between the various vehicles available in the market.</p>
            <br><p>Campervans are normally compact 2 (or sometimes 3) berth vehicles. Usually there is no division between the driving area and the living area and certainly no space to stand upright. These vehicles will have very basic kitchen facilities and no bathroom but are easy to handle and economical to drive. They are primarily built for the explorer in mind. Most vans, from almost every manufacturer, have been converted to campervans at some stage. Variations include the Volkswagen Caddy, Nissan NV200, Fiat Doblo, Citroen Berlingo or Nemo, and the Renault Kangoo. In some places, such as Iceland or countries in Africa, Campervans will be a little more rugged, based on a 4x4 vehicle such as a Toyota Hi-Lux or Landcruiser. In some cases, these will involve roof-tents rather than in-vehicle accomodation.</p>
            <br><p>Motorhomes are larger vehicles which can sleep up to 8 people.  Usually built on a truck or bus chassis, there will be more separation between the driving and living areas. In many Motorhomes, the driver and passenger seats can be swiveled around to become part of the living area. Some may have a bed space above the drivers compartment.  Motorhomes will have kitchen and bathroom facilities (often with more than one WC) and room to walk around inside. They will also have a dining area, which can usually be folded away. Whilst there will be storage, this can be limited dependent on the number of passengers.</p>
            <br><p>RV’s are the vehicle that many people aspire to own, or at least get the opportunity to rent.  Some US-versions are as big as a coach and have room for a small car in the rear compartment. Many have slide-out sections which significantly increase the living space. They also offer plenty of storage and can offer up to ten sleeping spaces and full bathrooms. RV’s are found at the more luxurious end of the market, with superb finishing and appliances.</p>
        </div>
    </div>
    <div class="country_list fleetCountryList fleetCountryListf">
        <div class="container">
            <div class="country_list_in">
                <ul>
                    <li *ngFor="let country of fleetCountries">
                        <a [routerLinkActive]="['active']" routerLink="/fleet-details/{{country.name}}">
                        <div class="dLogo">
                            <img src="{{country.banner}}" alt="{{country.name}}">
                        </div>
                        <a [routerLinkActive]="['active']" routerLink="/fleet-details/{{country.name}}"><span>{{country.name}}</span></a>
                        <span class="flag flag-{{country.country_code}} flag-24"></span>
                        </a>
                    </li>

                </ul>
            </div>
        </div>
    </div>

</section>