<section class="account-wrap">
    <div class="mobile-sub-nav visible-sm visible-xs">
        <a href="#" class="go-back-btn"><img src="../assets/images/left-arrow-mobile.svg"></a>
        <h2>My Reviews</h2>
    </div>
    <div class="container">
        <div class="account-wrap-row">
            <div class="profile-details-column">
                <app-accountmenu></app-accountmenu>
            </div>
            <div class="account-content-column">
                <div class="account-content-main">
                    <div class="account-nav-tabs">
                        <ul>
                            <li><a href="#" class="active">My Reviews list</a></li>
                        </ul>
                    </div>
                    <div class="account-content-in">
                        <div class="review-item">
                            <div class="vehicle-item clearfix hidden-sm hidden-xs">
                                <div class="clearfix">
                                    <div class="vehicle-images-gallery hidden-md">
                                        <div class="vehicle-gallery-min">
                                            <div class="v-gallery-min-item">
                                                <figure><img src="../assets/images/vehicle.png" alt="vehicle"></figure>
                                            </div>
                                        </div>
                                        <div class="v-gallery-min-num">1/10</div>
                                        <a href="#" class="gallery-fullmode"><img
                                                src="../assets/images/full-screen.svg"></a>
                                    </div>

                                    <div class="vehicle-details">
                                        <div class="vehicle-title">
                                            <h3><a href="#">Awesome Classic Camper</a></h3>
                                            <div class="vehicle-rating"><img src="../assets/images/rating.png"></div>
                                            <div class="vehicle-overall-rating"><span>Good, 72%</span> | 500+ Reviews
                                            </div>
                                        </div>
                                        <div class="veh-confirm-avail">
                                            <span class="confirm-badge">24h confirmation</span>
                                        </div>
                                        <div class="vehicle-features">
                                            <ul>
                                                <li>
                                                    <span class="v-feature-icon"><img
                                                            src="../assets/images/bed.svg"></span>
                                                    <span class="v-feature-name">2 Adults</span>
                                                </li>
                                                <li>
                                                    <span class="v-feature-icon"><img
                                                            src="../assets/images/toilet.svg"></span>
                                                    <span class="v-feature-name">Toilet</span>
                                                </li>
                                                <li class="feature-unavailable">
                                                    <span class="v-feature-icon"><img
                                                            src="../assets/images/shower.svg"></span>
                                                    <span class="v-feature-name">Shower</span>
                                                </li>
                                                <li>
                                                    <span class="v-feature-icon"><img
                                                            src="../assets/images/manual-transmission.svg"></span>
                                                    <span class="v-feature-name">Manual</span>
                                                </li>
                                                <li>
                                                    <span class="v-feature-icon"><img
                                                            src="../assets/images/caravan.svg"></span>
                                                    <span class="v-feature-name">1999-2000</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="vehicle-benifits">
                                            <ul>
                                                <li><span class="v-benefits-label">Benefits :</span></li>
                                                <li><span class="v-benefit-icon"><img
                                                        src="../assets/images/check.svg"></span><span
                                                        class="v-benefit-name">Equipped</span></li>
                                                <li><span class="v-benefit-icon"><img
                                                        src="../assets/images/check.svg"></span><span
                                                        class="v-benefit-name">Esky</span></li>
                                                <li><span class="v-benefit-icon"><img
                                                        src="../assets/images/check.svg"></span><span
                                                        class="v-benefit-name">Outdoor table/chairs</span></li>
                                                <li><span class="v-benefit-icon"><img
                                                        src="../assets/images/check.svg"></span><span
                                                        class="v-benefit-name">Portable</span></li>
                                                <li><span class="v-benefit-icon"><img
                                                        src="../assets/images/check.svg"></span><span
                                                        class="v-benefit-name">Outdoor table/chairs</span></li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="vehicle-rent-amt">
                                        <div class="vehicle-rent">
                                            <span class="original-amt">AU$167.80</span>
                                            <span class="final-amt">AU$158.80</span>
                                            <span class="fees-amt">+ fees AU$60.00</span>
                                        </div>
                                        <button class="btn vehicle-select-btn">Select</button>
                                    </div>

                                    <div class="vehicle-item-options">
                                        <span class="vehicle-distance">2 Miles away</span>
                                        <a href="#" class="like-vehicle active"><i class="fa fa-heart"
                                                                                   aria-hidden="true"></i></a>
                                        <a href="#" class="share-vehicle"><i class="fa fa-share-alt"
                                                                             aria-hidden="true"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="vehicle-item clearfix visible-xs visible-sm">
                                <div class="vehicle-title">
                                    <h3>Awesome Classic Camper</h3>
                                </div>
                                <div class="veh-confirm-avail">
                                    <span class="confirm-badge">24h confirmation</span>
                                    <span class="avail-num">Available : 3</span>
                                </div>
                                <div class="clearfix">
                                    <div class="vehicle-images-gallery">
                                        <div class="vehicle-gallery-min">
                                            <div class="v-gallery-min-item">
                                                <figure><img src="../assets/images/vehicle.png" alt="vehicle"></figure>
                                            </div>
                                        </div>
                                        <div class="v-gallery-min-num">1/10</div>
                                        <a href="#" class="gallery-fullmode"><img
                                                src="../assets/images/full-screen.svg"></a>
                                    </div>

                                    <div class="vehicle-details">

                                        <div class="vehicle-features">
                                            <ul>
                                                <li>
                                                    <span class="v-feature-icon"><img
                                                            src="../assets/images/bed.svg"></span>
                                                    <span class="v-feature-name">2 Adults</span>
                                                </li>
                                                <li>
                                                    <span class="v-feature-icon"><img
                                                            src="../assets/images/toilet.svg"></span>
                                                    <span class="v-feature-name">Toilet</span>
                                                </li>
                                                <li class="feature-unavailable">
                                                    <span class="v-feature-icon"><img
                                                            src="../assets/images/shower.svg"></span>
                                                    <span class="v-feature-name">Shower</span>
                                                </li>
                                                <li>
                                                    <span class="v-feature-icon"><img
                                                            src="../assets/images/manual-transmission.svg"></span>
                                                    <span class="v-feature-name">Manual</span>
                                                </li>
                                                <li>
                                                    <span class="v-feature-icon"><img
                                                            src="../assets/images/caravan.svg"></span>
                                                    <span class="v-feature-name">1999-2000</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <a class="full-specs-btn-sm" href="#"><i class="fa fa-plus"
                                                                                 aria-hidden="true"></i> Full Specs</a>

                                        <div class="vehicle-benifits hidden-sm hidden-xs">
                                            <ul>
                                                <li><span class="v-benefits-label">Benefits :</span></li>
                                                <li><span class="v-benefit-icon"><img
                                                        src="../assets/images/check.svg"></span><span
                                                        class="v-benefit-name">Equipped</span></li>
                                                <li><span class="v-benefit-icon"><img
                                                        src="../assets/images/check.svg"></span><span
                                                        class="v-benefit-name">Esky</span></li>
                                                <li><span class="v-benefit-icon"><img
                                                        src="../assets/images/check.svg"></span><span
                                                        class="v-benefit-name">Outdoor table/chairs</span></li>
                                                <li><span class="v-benefit-icon"><img
                                                        src="../assets/images/check.svg"></span><span
                                                        class="v-benefit-name">Portable</span></li>
                                                <li><span class="v-benefit-icon"><img
                                                        src="../assets/images/check.svg"></span><span
                                                        class="v-benefit-name">Outdoor table/chairs</span></li>
                                            </ul>
                                        </div>

                                    </div>

                                    <div class="vehicle-rent-amt">
                                        <div class="vehicle-rent">
                                            <span class="original-amt">AU$167.80</span>
                                            <span class="final-amt">AU$158.80</span>
                                            <span class="fees-amt">+ fees AU$60.00</span>
                                        </div>
                                        <button class="btn vehicle-select-btn">Select</button>
                                    </div>

                                    <div class="vehicle-item-options">
                                        <a href="#" class="like-vehicle"><i class="fa fa-heart" aria-hidden="true"></i></a>
                                        <a href="#" class="share-vehicle"><i class="fa fa-share-alt"
                                                                             aria-hidden="true"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="your-rating-view">
                                <h3>Your Ratings</h3>
                                <div class="star-rating"><span>3.5</span>
                                    <ul>
                                        <li><i class="fa fa-star active" aria-hidden="true"></i></li>
                                        <li><i class="fa fa-star active" aria-hidden="true"></i></li>
                                        <li><i class="fa fa-star active" aria-hidden="true"></i></li>
                                        <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                        <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                    </ul>
                                </div>
                                <h4>Review Title</h4>
                                <blockquote>"Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when
                                    an unknown printer took a galley of type and scrambled it to make a type specimen
                                    book."<i class="fa fa-quote-left" aria-hidden="true"></i>
                                </blockquote>
                            </div>
                        </div>

                        <div class="review-item">
                            <div class="vehicle-item clearfix hidden-sm hidden-xs">
                                <div class="clearfix">
                                    <div class="vehicle-images-gallery hidden-md">
                                        <div class="vehicle-gallery-min">
                                            <div class="v-gallery-min-item">
                                                <figure><img src="../assets/images/vehicle.png" alt="vehicle"></figure>
                                            </div>
                                        </div>
                                        <div class="v-gallery-min-num">1/10</div>
                                        <a href="#" class="gallery-fullmode"><img
                                                src="../assets/images/full-screen.svg"></a>
                                    </div>

                                    <div class="vehicle-details">
                                        <div class="vehicle-title">
                                            <h3><a href="#">Awesome Classic Camper</a></h3>
                                            <div class="vehicle-rating"><img src="../assets/images/rating.png"></div>
                                            <div class="vehicle-overall-rating"><span>Good, 72%</span> | 500+ Reviews
                                            </div>
                                        </div>
                                        <div class="veh-confirm-avail">
                                            <span class="confirm-badge">24h confirmation</span>
                                        </div>
                                        <div class="vehicle-features">
                                            <ul>
                                                <li>
                                                    <span class="v-feature-icon"><img
                                                            src="../assets/images/bed.svg"></span>
                                                    <span class="v-feature-name">2 Adults</span>
                                                </li>
                                                <li>
                                                    <span class="v-feature-icon"><img
                                                            src="../assets/images/toilet.svg"></span>
                                                    <span class="v-feature-name">Toilet</span>
                                                </li>
                                                <li class="feature-unavailable">
                                                    <span class="v-feature-icon"><img
                                                            src="../assets/images/shower.svg"></span>
                                                    <span class="v-feature-name">Shower</span>
                                                </li>
                                                <li>
                                                    <span class="v-feature-icon"><img
                                                            src="../assets/images/manual-transmission.svg"></span>
                                                    <span class="v-feature-name">Manual</span>
                                                </li>
                                                <li>
                                                    <span class="v-feature-icon"><img
                                                            src="../assets/images/caravan.svg"></span>
                                                    <span class="v-feature-name">1999-2000</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="vehicle-benifits">
                                            <ul>
                                                <li><span class="v-benefits-label">Benefits :</span></li>
                                                <li><span class="v-benefit-icon"><img
                                                        src="../assets/images/check.svg"></span><span
                                                        class="v-benefit-name">Equipped</span></li>
                                                <li><span class="v-benefit-icon"><img
                                                        src="../assets/images/check.svg"></span><span
                                                        class="v-benefit-name">Esky</span></li>
                                                <li><span class="v-benefit-icon"><img
                                                        src="../assets/images/check.svg"></span><span
                                                        class="v-benefit-name">Outdoor table/chairs</span></li>
                                                <li><span class="v-benefit-icon"><img
                                                        src="../assets/images/check.svg"></span><span
                                                        class="v-benefit-name">Portable</span></li>
                                                <li><span class="v-benefit-icon"><img
                                                        src="../assets/images/check.svg"></span><span
                                                        class="v-benefit-name">Outdoor table/chairs</span></li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="vehicle-rent-amt">
                                        <div class="vehicle-rent">
                                            <span class="original-amt">AU$167.80</span>
                                            <span class="final-amt">AU$158.80</span>
                                            <span class="fees-amt">+ fees AU$60.00</span>
                                        </div>
                                        <button class="btn vehicle-select-btn">Select</button>
                                    </div>

                                    <div class="vehicle-item-options">
                                        <span class="vehicle-distance">2 Miles away</span>
                                        <a href="#" class="like-vehicle active"><i class="fa fa-heart"
                                                                                   aria-hidden="true"></i></a>
                                        <a href="#" class="share-vehicle"><i class="fa fa-share-alt"
                                                                             aria-hidden="true"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="vehicle-item clearfix visible-xs visible-sm">
                                <div class="vehicle-title">
                                    <h3>Awesome Classic Camper</h3>
                                </div>
                                <div class="veh-confirm-avail">
                                    <span class="confirm-badge">24h confirmation</span>
                                    <span class="avail-num">Available : 3</span>
                                </div>
                                <div class="clearfix">
                                    <div class="vehicle-images-gallery">
                                        <div class="vehicle-gallery-min">
                                            <div class="v-gallery-min-item">
                                                <figure><img src="../assets/images/vehicle.png" alt="vehicle"></figure>
                                            </div>
                                        </div>
                                        <div class="v-gallery-min-num">1/10</div>
                                        <a href="#" class="gallery-fullmode"><img
                                                src="../assets/images/full-screen.svg"></a>
                                    </div>

                                    <div class="vehicle-details">

                                        <div class="vehicle-features">
                                            <ul>
                                                <li>
                                                    <span class="v-feature-icon"><img
                                                            src="../assets/images/bed.svg"></span>
                                                    <span class="v-feature-name">2 Adults</span>
                                                </li>
                                                <li>
                                                    <span class="v-feature-icon"><img
                                                            src="../assets/images/toilet.svg"></span>
                                                    <span class="v-feature-name">Toilet</span>
                                                </li>
                                                <li class="feature-unavailable">
                                                    <span class="v-feature-icon"><img
                                                            src="../assets/images/shower.svg"></span>
                                                    <span class="v-feature-name">Shower</span>
                                                </li>
                                                <li>
                                                    <span class="v-feature-icon"><img
                                                            src="../assets/images/manual-transmission.svg"></span>
                                                    <span class="v-feature-name">Manual</span>
                                                </li>
                                                <li>
                                                    <span class="v-feature-icon"><img
                                                            src="../assets/images/caravan.svg"></span>
                                                    <span class="v-feature-name">1999-2000</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <a class="full-specs-btn-sm" href="#"><i class="fa fa-plus"
                                                                                 aria-hidden="true"></i> Full Specs</a>

                                        <div class="vehicle-benifits hidden-sm hidden-xs">
                                            <ul>
                                                <li><span class="v-benefits-label">Benefits :</span></li>
                                                <li><span class="v-benefit-icon"><img
                                                        src="../assets/images/check.svg"></span><span
                                                        class="v-benefit-name">Equipped</span></li>
                                                <li><span class="v-benefit-icon"><img
                                                        src="../assets/images/check.svg"></span><span
                                                        class="v-benefit-name">Esky</span></li>
                                                <li><span class="v-benefit-icon"><img
                                                        src="../assets/images/check.svg"></span><span
                                                        class="v-benefit-name">Outdoor table/chairs</span></li>
                                                <li><span class="v-benefit-icon"><img
                                                        src="../assets/images/check.svg"></span><span
                                                        class="v-benefit-name">Portable</span></li>
                                                <li><span class="v-benefit-icon"><img
                                                        src="../assets/images/check.svg"></span><span
                                                        class="v-benefit-name">Outdoor table/chairs</span></li>
                                            </ul>
                                        </div>

                                    </div>

                                    <div class="vehicle-rent-amt">
                                        <div class="vehicle-rent">
                                            <span class="original-amt">AU$167.80</span>
                                            <span class="final-amt">AU$158.80</span>
                                            <span class="fees-amt">+ fees AU$60.00</span>
                                        </div>
                                        <button class="btn vehicle-select-btn">Select</button>
                                    </div>

                                    <div class="vehicle-item-options">
                                        <a href="#" class="like-vehicle"><i class="fa fa-heart" aria-hidden="true"></i></a>
                                        <a href="#" class="share-vehicle"><i class="fa fa-share-alt"
                                                                             aria-hidden="true"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="your-rating-view">
                                <h3>Your Ratings</h3>
                                <div class="star-rating"><span>3.5</span>
                                    <ul>
                                        <li><i class="fa fa-star active" aria-hidden="true"></i></li>
                                        <li><i class="fa fa-star active" aria-hidden="true"></i></li>
                                        <li><i class="fa fa-star active" aria-hidden="true"></i></li>
                                        <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                        <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                    </ul>
                                </div>
                                <h4>Review Title</h4>
                                <blockquote>"Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when
                                    an unknown printer took a galley of type and scrambled it to make a type specimen
                                    book."<i class="fa fa-quote-left" aria-hidden="true"></i>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>
        