import {Component, OnInit} from '@angular/core';
import * as $ from 'jquery';
import {NavigationEnd, Router, ActivatedRoute} from '@angular/router';
import {filter} from 'rxjs/internal/operators';
import {HttpClient} from '@angular/common/http';
import {DataService, AuthenticationService} from '@app/_services';
import {apiUrls} from '@app/shared/app.constants';
import {environment} from '@environments/environment';
import {MessageService} from '../../_services/message.service';

@Component({
    selector: 'app-accountmenu',
    templateUrl: './accountmenu.component.html',
    styleUrls: ['./accountmenu.component.css']
})
export class AccountmenuComponent implements OnInit {

    routerSubscription: any;
    countries: any;
    passengerList: {};
    userDetails: any;

    constructor(private http: HttpClient,
                private route: ActivatedRoute,
                private dataService: DataService,
                private authService: AuthenticationService,
                private messageService: MessageService,
                private router: Router) {
        //this.userDetails = JSON.parse(window.localStorage.getItem('userdata'));
    }

    ngOnInit(){
        if (window.localStorage.getItem('token')) {
            this.userDetails = JSON.parse(window.localStorage.getItem('token'));
        }
    }
    logout(){
        this.authService.logout();
    }
}
