<footer class="footer">
    <div class="container">
        <div class="top_destinations">
            <h4>Top destinations</h4>
            <ul class="destinations_list">
                <li *ngFor="let country of countries">
                    <a [routerLinkActive]="['active']" routerLink="/destinations/{{country.name}}">
                        <span class="flag flag-{{country.country_code}} flag-24"></span>
                        <span class="dest_name">{{country.name}}</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="footer_in">
            <div class="footer_lft">
                <ul>
                    <li>
                        <a href="#">
                            <img src="assets/images/logo.png" alt="logo">
                        </a>
                    </li>
                    <li>
                        <address>
                            Broxbourne Business Centre <br>
                            Pindar Road Hoddesdon <br>
                            Hertfordshire EN11 0FJ <br>
                            United Kingdom
                        </address>
                    </li>
                    <li>
                        <h5><a href="tel:0345 9000 420">Tel: 0345 9000 420</a></h5>
                        <h5>Email: <a href="mailto:info@affordablemotorhomeholidays.com">info@affordablemotorhomeholidays.com</a></h5>
                    </li>
                </ul>
            </div>
            <div class="footer_rgt">
                <ul>
                    <li><a href="https://www.facebook.com/affordablemotorhomerentals" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                    <li><a href="http://twitter.com/" target="_blank"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                    <li><a href="https://www.instagram.com/affordablemotorhomerentals/" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                </ul>
            </div>
        </div>
        <div class="footer_bottom">
            <ul>
                <li>
                    © Affordable Motorhomes 2021
                </li>
                <li><a [routerLinkActive]="['active']" routerLink="/about-us">About Us</a></li>
                <li><a [routerLinkActive]="['active']" routerLink="/contact-us">Contact Us</a></li>
                <li><a [routerLinkActive]="['active']" routerLink="/gdpr-statement">GDPR Statement</a></li>
                <li><a [routerLinkActive]="['active']" routerLink="/terms-and-conditions">Terms and Conditions</a></li>
            </ul>
        </div>
    </div>
</footer>