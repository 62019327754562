import {AbstractControl} from '@angular/forms';

export function AgeValidator(control: AbstractControl): { [key: string]: boolean } | null {

    const now = new Date();
    const past = new Date(control.value);
    const nowYear = now.getFullYear();
    const pastYear = past.getFullYear();
    const age = nowYear - pastYear;
    if (age > 18) {
        return {age: true};
    }
    return null;
}