import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { RestfulApiService } from "../_services/restful.service";

@Injectable({
    providedIn: "root"
})
export class DestinationService {
    constructor(private restful: RestfulApiService) { }
    getCitiesByDestination(destination: string): Observable<any> {
        return this.restful.sendRequest(
            `/api/Destinations/getCities/${destination}`,
            "GET"
        );
    }

    getCountries() {
        return this.restful.sendRequest(
            `/api/Search/getCountries`,
            "GET"
        );
    }

    getCountryInfo(destination: string) {
        return this.restful.sendRequest(
            `/api/Destinations/getInfo/${destination}`,
            "GET"
        );
    }

    getFAQs(destination: string) {
        return this.restful.sendRequest(
            `/api/Search/getCountriesInfo`,
            "POST",
            `{"country":${destination}}`
        );
    }
}
