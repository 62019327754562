<section id="main">
    <div class="itineraries_banner aboutus_banner">
        <div class="container">
            <div class=" ">
                <h2>About Us</h2>
            </div>
        </div>
    </div>

    <div class="aboutUsCont">
        <div class="aboutUsContTop">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 col-md-8 col-sm-12">
                        <div class="aboutUsContIn">
                            <h3>Hey welcome to <span>Affordable Motorhome Holidays!!</span></h3>
                            <p>
                                We’re thrilled to have you looking through our website and the possibility you could be booking an amazing experience of a lifetime!
                            </p>
                            <p>
                                Established in 2004, as a family run business specialising in worldwide car rental, due to demand from our partners and also a fantastic family holiday with our children in a Motorhome, one never to be forgotten! We began to create, search for partnerships with
                                many high quality Motorhome &amp; Campervan operators around the world the experts in their filed for the ultimate experience. We finally launched a worldwide Motorhome &amp; Campervan Holiday website in 2018, Affordable Motorhome Rentals and renamed our
                                platform to Affordable Motorhome Holidays in 2020, afterall we are selling a holiday experience. 
                            </p>
                            <p>
                                Our commitment is to provide competitive pricing, quality service plus many great offers and a wide range of touring experiences via our 135 Motorhome &amp; Campervan partners. Providing a comprehensive range of destinations with over 50 countries, 20,000 locations,
                                worldwide!
                            </p>   
                            <p>
                                We have created 100&#39;s of itineraries to help you along the way, written by our travel journalists and many of our Motorhome Holidaying experts, if you would like to become an ambassador to Affordable Motorhome Holidays and join our club and become part of our Affordable Motorhome family please contact our team at:- 
                                <a href="mailto:AmbassadorClub@Affordablemotorhomeholidays.com">AmbassadorClub@Affordablemotorhomeholidays.com</a>
                            </p> 
                            <p>
                                Although we have created an easy to use website, with lots of information. If you are new to a Motorhome Holiday experience, we do understand that you may have a few questions, so please don&#39;t hesitate to contact our experienced team either via live chat, you can email <a href="mailto:info@affordablemotorhomeholidays.com">info@affordablemotorhomeholidays.com</a> or even call us on :- <a href="tel:0345 9000420">0345 9000420</a> as we are here to help!
                            </p>
                            <p>
                                Love team Affordable x
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-12">
                        <figure> <img src="assets/images/about-us.jpg" class="hidden-xs">
                        </figure>
                    </div>
                </div>
            </div>
        </div>
        <div class="ourTeam">
            <div class="container">
                <div class="ourTeamMembers">
                    <h4>Our Team</h4>
                    <ul>
                        <li>
                            <div class="teamLogo">
                                <img src="assets/images/team-logo-1.jpg" alt="team-logo">
                            </div>
                            <h2>Angela Day</h2>
                            <h3>CEO</h3>
                            <a (click)="aboutpopup('team1')">View More</a>
                        </li>
                        <li>
                            <div class="teamLogo">
                                <img src="assets/images/team-logo-2.jpg" alt="team-logo">
                            </div>
                            <h2>Fred W Finn</h2>
                            <h3>Global Brand Ambassador</h3>
                            <a (click)="aboutpopup('team3')">View More</a>
                        </li>
                        <li>
                            <div class="teamLogo">
                                <img src="assets/images/David.jpg" alt="team-logo">
                            </div>
                            <h2>David Gordon</h2>
                            <h3>Editorial &amp; Itineraries</h3>
                            <a (click)="aboutpopup('team4')">View More</a>
                        </li>
                        <li>
                            <div class="teamLogo">
                                <img src="assets/images/Julie.jpg" alt="team-logo">
                            </div>
                            <h2>Julie House</h2>
                            <h3>General Manager</h3>
                            <a (click)="aboutpopup('team5')">View More</a>
                        </li>
                        <li>
                            <div class="teamLogo">
                                <img src="assets/images/LucyClare.jpg" alt="team-logo">
                            </div>
                            <h2>Lucy Clare</h2>
                            <h3>Head of partnerships</h3>
                            <a (click)="aboutpopup('team6')">View More</a>
                        </li>
                        <li>
                            <div class="teamLogo">
                                <img src="assets/images/JEANETTE-Hudson.jpg" alt="team-logo">
                            </div>
                            <h2>Jeanette Hudson</h2>
                            <h3>Head of reservations</h3>
                            <a (click)="aboutpopup('team7')">View More</a>
                        </li>
                        <li>
                            <div class="teamLogo">
                                <img src="assets/images/AYSHE-IBRAHIM.jpg" alt="team-logo">
                            </div>
                            <h2>Ayshe Ibrahim</h2>
                            <h3>Head of accounts</h3>
                            <a (click)="aboutpopup('team8')">View More</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

</section>
<!-- /main -->

<!-- Login -->
<div class="login-signup aboutUsPopup" id="team1">
    <div class="login-signup-centered">
        <div class="log-sign-main">
            <button class="btn log-sign-close" (click)="closeabt()"><img src="assets/images/close.svg"></button>
            <div class="teampopHead">
                <div class="teampopHeadLogo">
                    <img src="assets/images/team-logo-1.jpg" alt="team-logo">
                </div>
                <div class="teampopHeadDtls">
                    <h2>Angela Day</h2>
                    <h3>CEO</h3>
                    <h4><span>angela.day@affordablemotorhomeholidays.com</span></h4>
                </div>
            </div>
            <div class="teampopBody">
                <p>Angela initially set up Affordable with her husband James from their converted garage in 2004 with the dream of building an online car rental and in later years developed a motorhome platform specialising with the holiday sector.</p>
                <p>Angela quotes ‘I’m absolutely thrilled to welcome you to the Affordable Motorhome Holidays Platform’ especially after such a challenging year with the impact of COVID 19. Motorhome Holidays have become a very popular way to enjoy touring with freedom and isolation. We value your business and booking with us, its very important to my team and I that you have a wonderful holiday experience touring in your ideal Motorhome – they are the best holidays always        remembered!! Family run businesses are always up against the odds in todays climate but we hope with our special features to make your experience a great one, you will be part of our family and journey to create a wonderful Motorhome Touring website.</p>
                <p>And finally, we have also created our Ambassador Club for incentives, to take part in blogs, videos and our many special offers - we would be delighted if you’d join us!! And for our business partners we provide affiliate partnerships if you would like further information on how to be part of our affiliate network scheme, please email partnerships@affordablemotorhomeholidays.com’</p>
                <p>Best wishes always<br>
                Angela x</p>
                <p>Angela has been an ITT Board Director for the past 12 years.</p>
                <h6>Personal awards include:-</h6>
                <ul>
                    <li>Entrepreneurial Woman’s Innovation Award UK 2016 Breast Cancer Now</li>
                    <li>Top 100 Travel Women in Business Award</li>
                    <li>CMX International Leading Women Entrepreneur small business award 2017 / 18</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="login-signup aboutUsPopup" id="team3">
    <div class="login-signup-centered">
        <div class="log-sign-main">
            <button class="btn log-sign-close" (click)="closeabt()"><img src="assets/images/close.svg"></button>
            <div class="teampopHead">
                <div class="teampopHeadLogo">
                    <img src="assets/images/team-logo-2.jpg" alt="team-logo">
                </div>
                <div class="teampopHeadDtls">
                    <h2>Fred W Finn</h2>
                    <h3>Global Brand Ambassador</h3>
                    <h4><span>fred@affordablemotorhomeholidays.com</span></h4>
                </div>
            </div>
            <div class="teampopBody">
                <p>Fred Finn has joined Luxury Cruise & More as Global Brand Ambassador. Fred W Finn is a Guinness World Record Holder, with the honour bestowed upon him in 1983 when Guinness World Records confirmed he had flown 15,000,000 miles. Fred also holds the record for the greatest number of flights as a passenger on Concorde, having made 718 crossings. Fred Finn has visited 139 countries, many of them more than once, and has a particular fondness for Kenya, which he has visited 600 times, and for the country of Georgia. Fred will be hosting special cruises and private events exclusively for clients and guests of Luxury Cruise & More.</p>
            </div>
        </div>
    </div>
</div>
<div class="login-signup aboutUsPopup" id="team4">
    <div class="login-signup-centered">
        <div class="log-sign-main">
            <button class="btn log-sign-close" (click)="closeabt()"><img src="assets/images/close.svg"></button>
            <div class="teampopHead">
                <div class="teampopHeadLogo">
                    <img src="assets/images/David.jpg" alt="team-logo">
                </div>
                <div class="teampopHeadDtls">
                    <h2>DAVID GORDON</h2>
                    <h3>Editorial &amp; Itineraries</h3>
                    <h4><span>david@affordablemotorhomeholidays.com</span></h4>
                </div>
            </div>
            <div class="teampopBody">
                <p>David has been talking about travel &amp; tourism for over twenty years. His byline has appeared in national newspapers across the UK and Ireland. His travel podcasts have been downloaded over half a million times and broadcast on more than 20 radio stations in the UK, France, Australia and USA.</p>
                <p>David delivers talks to travel &amp; tourism students in schools, colleges and universities across the UK and is a Director of the prestigious British Guild of Travelwriters. David has been part of the Affordable team since 2019.</p>
            </div>
        </div>
    </div>
</div>
<div class="login-signup aboutUsPopup" id="team5">
    <div class="login-signup-centered">
        <div class="log-sign-main">
            <button class="btn log-sign-close" (click)="closeabt()"><img src="assets/images/close.svg"></button>
            <div class="teampopHead">
                <div class="teampopHeadLogo">
                    <img src="assets/images/Julie.jpg" alt="team-logo">
                </div>
                <div class="teampopHeadDtls">
                    <h2>Julie House</h2>
                    <h3>General Manager</h3>
                    <h4><span>customerservices@affordablemotorhomeholidays.com</span></h4>
                </div>
            </div>
            <div class="teampopBody">
                <p>I started working at Affordable in 2011 but have worked in the travel industry for the last 40 years.<br>
                Working in a variety of departments and teams starting as a junior travel consultant working my way up to branch manager.</p>
                <p>At Affordable I look after the team and between myself and Angela oversee everything customer related.</p>
                <p> Having seen throughout my many years in travel I have seen so many changes and can honestly say I am so excited being part of the Motorhome team and the new way of future holidaying worldwide and locally. </p>
            </div>
        </div>
    </div>
</div>
<div class="login-signup aboutUsPopup" id="team6">
    <div class="login-signup-centered">
        <div class="log-sign-main">
            <button class="btn log-sign-close" (click)="closeabt()"><img src="assets/images/close.svg"></button>
            <div class="teampopHead">
                <div class="teampopHeadLogo">
                    <img src="assets/images/LucyClare.jpg" alt="team-logo">
                </div>
                <div class="teampopHeadDtls">
                    <h2>Lucy Clare</h2>
                    <h3>Head of partnerships</h3>
                    <h4><span>partnerships@affordablemotorhomeholidays.com</span></h4>
                </div>
            </div>
            <div class="teampopBody">
                <p>Lucy has been part of the Affordable family for 10 years. She started off in the reservations team before working her way up to the Sales team and now being Head of Partnerships and Commercial for Affordable Motorhome Holidays. Travel and Tourism has always been her passion after studying this at college then working for Travel Agents and Tour Operators before joining Affordable. She is always on hand to give assistance in planning your next Motorhome adventure.</p>
            </div>
        </div>
    </div>
</div>
<div class="login-signup aboutUsPopup" id="team7">
    <div class="login-signup-centered">
        <div class="log-sign-main">
            <button class="btn log-sign-close" (click)="closeabt()"><img src="assets/images/close.svg"></button>
            <div class="teampopHead">
                <div class="teampopHeadLogo">
                    <img src="assets/images/JEANETTE-Hudson.jpg" alt="team-logo">
                </div>
                <div class="teampopHeadDtls">
                    <h2>Jeanette Hudson</h2>
                    <h3>Head of reservations</h3>
                    <h4><span>info@affordablemotorhomeholidays.com</span></h4>
                </div>
            </div>
            <div class="teampopBody">
                <p>Jeanette has worked in the Travel industry for 28 years and joined Affordable 10 years ago to work within the Reservations Team. Jeanette is looking forward to the new challenge of Motorhome holidays and heading the Reservations Team to success.</p>
            </div>
        </div>
    </div>
</div>
<div class="login-signup aboutUsPopup" id="team8">
    <div class="login-signup-centered">
        <div class="log-sign-main">
            <button class="btn log-sign-close" (click)="closeabt()"><img src="assets/images/close.svg"></button>
            <div class="teampopHead">
                <div class="teampopHeadLogo">
                    <img src="assets/images/AYSHE-IBRAHIM.jpg" alt="team-logo">
                </div>
                <div class="teampopHeadDtls">
                    <h2>Ayshe Ibrahim</h2>
                    <h3>Head of accounts</h3>
                    <h4><span>accounts@affordablemotorhomeholidays.com</span></h4>
                </div>
            </div>
            <div class="teampopBody">
                <p>Ayshe is the Head of Accounts which joined affordable over 2 years ago. Offering over 15 years experience in the accounts sector.</p>
                <p>Before then, she has serviced a variety of administration and bookkeeping needs for businesses small and large. </p>
                <p>She is extremely excited to run the accounts department together with her team for our new motorhome venture.</p>
            </div>
        </div>
    </div>
</div>