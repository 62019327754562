<section class="account-wrap">
    <div class="mobile-sub-nav visible-sm visible-xs">
        <a href="#" class="go-back-btn"><img src="../assets/images/left-arrow-mobile.svg"></a>
        <h2>My Bookings</h2>
    </div>
    <div class="container">
        <div class="account-wrap-row">
            <div class="profile-details-column">
                <app-accountmenu></app-accountmenu>
            </div>
            <div class="account-content-column">
                <div class="account-content-main">
                    <div class="account-nav-tabs">
                        <ul>
                            <li><a href="#" class="active">Bookings</a></li>
                        </ul>
                    </div>
                    <div class="account-content-in">
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-9 fare-details-wrap-col">
                                    <div *ngFor="let resultView of bookingsList; index as i"
                                         class="vehicle-item vehicle-item-plain clearfix">
                                        <div class="clearfix">
                                            <div class="vehicle-images-gallery hidden-sm hidden-xs" *ngIf="resultView">
                                                <div class="vehicle-gallery-min">
                                                    <div *ngFor="let images of resultView.quote.quote.model.version.vehicle.moreImages"
                                                         class="v-gallery-min-item" data-toggle="modal"
                                                         data-target="#GallerylightBox">
                                                        <figure><img src="{{images.image}}" alt="vehicle"></figure>
                                                    </div>
                                                </div>
                                                <div class="v-gallery-min-num"></div>
                                                <a href="javascript:void(0)" class="gallery-fullmode"
                                                   data-toggle="modal"
                                                   data-target="#GallerylightBox"><img
                                                        src="./assets/images/full-screen.svg"></a>
                                            </div>

                                            <div class="vehicle-details">
                                                <div class="vehicle-title">
                                                    <h3 *ngIf="resultView">
                                                        {{resultView.quote.quote.model.version.vehicle.name}}</h3>
                                                    <!-- <div class="vehicle-rating hidden-sm hidden-xs"><img
                                                            src="./assets/images/rating.png">
                                                    </div> -->
                                                    <div style="display: none"
                                                         class="vehicle-overall-rating hidden-sm hidden-xs">
                                                        <span>Good, 72%</span>
                                                        | 500+ Reviews
                                                    </div>
                                                </div>
                                                <div style="display: none" class="veh-confirm-avail">
                                                    <span class="confirm-badge">24h confirmation</span>
                                                    <span class="avail-num">Available : 3</span>
                                                </div>
                                                <div class="vehicle-features">
                                                    <ul>
                                                        <li>
                                                            <span class="v-feature-icon"><img
                                                                    src="./assets/images/bed.svg"></span>
                                                            <span class="v-feature-name" *ngIf="resultView">{{resultView.quote.quote.model.version.vehicle.capacity.bedCapacity}}</span>
                                                        </li>
                                                        <li>
						<span class="v-feature-icon"><img
                                src="./assets/images/toilet.svg"></span>
                                                            <span class="v-feature-name">Toilet</span>
                                                        </li>
                                                        <li>
						<span class="v-feature-icon"><img
                                src="./assets/images/shower.svg"></span>
                                                            <span class="v-feature-name">Shower</span>
                                                        </li>
                                                        <li>
					<span class="v-feature-icon"><img
                            src="./assets/images/manual-transmission.svg"></span>
                                                            <span class="v-feature-name">Manual</span>
                                                        </li>
                                                        <li>
						<span class="v-feature-icon"><img
                                src="./assets/images/caravan.svg"></span>
                                                            <span class="v-feature-name" *ngIf="resultView">{{resultView.quote.quote.model.version.vehicle.vehicleAge.text}}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <a class="full-specs-btn-sm visible-xs visible-sm" href="#"><i
                                                        class="fa fa-plus"
                                                        aria-hidden="true"></i>
                                                    View Full Details</a>
                                                <div class="vehicle-more-details hidden-sm hidden-xs">

                                                    <ul class="">
                                                        <li class="view-more-btn full-specs-btn-sm"><a
                                                                (click)="opentab(1,'FullSpecs')">View Full Details <i
                                                                class="fa fa-plus" id="dicon1"></i></a>

                                                        </li>
                                                        <li style="display: none"><a data-toggle="tab"
                                                                                     href="#Insurance1">Capacity</a>
                                                        </li>
                                                        <li style="display: none"><a data-toggle="tab"
                                                                                     href="#Features1">Features</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="vehicle-more-details-content">
                                            <div class="tab-content">
                                                <div id="FullSpecs1" class="tab-pane">
                                                    <div class="v-details-wrap">
                                                        <button class="btn log-sign-close" (click)="closetab(1,'FullSpecs')"><img src="assets/images/close.svg"></button>
                                                        <div class="v-details-row viewVehicleSpec">
                                                            <ul>
                                                                <li *ngFor="let customproperty of resultView.quote.quote.model.version.vehicle.customproperties">
                                                    <span class="v-feature-icon" *ngIf="customproperty.image"><img
                                                            src="assets/images/{{customproperty.image}}"></span>
                                                                    <span class="v-feature-name" [ngClass]="{'stikeout' : !customproperty.status}">{{customproperty.label}} <strong *ngIf="customproperty.value"> {{customproperty.value}}</strong></span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="Insurance1" class="tab-pane fade" style="display: none">
                                                    <div class="v-details-wrap">
                                                        <div class="v-details-row">
                                                            <div class="v-details-block">
                                                                <h4>Capacity</h4>
                                                                <ul>
                                                                    <li *ngFor="let property of resultView.quote.quote.model.version.vehicle.capacity | keyvalue">
                                                                        <span>{{property.key}}</span><span>{{property.value}}</span>
                                                                    </li>
                                                                </ul>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="Features1" class="tab-pane fade">
                                                    <div class="v-details-wrap">
                                                        <div class="v-details-row">
                                                            <div class="v-details-block">
                                                                Not available
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>
