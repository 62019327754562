import {Component, OnInit} from '@angular/core';
import * as $ from 'jquery';
import {NavigationEnd, Router, ActivatedRoute} from '@angular/router';
import {filter} from 'rxjs/internal/operators';
import {HttpClient} from '@angular/common/http';
import {DataService, AuthenticationService} from '@app/_services';
import {apiUrls} from '@app/shared/app.constants';
import {environment} from '@environments/environment';
import {MessageService} from '../../_services/message.service';
import {HeaderService} from '@app/shared/header/header.service';

@Component({
  selector: 'app-my-reviews',
  templateUrl: './my-reviews.component.html',
  styleUrls: ['./my-reviews.component.css']
})
export class MyReviewsComponent implements OnInit {

    constructor(private http: HttpClient,
                private route: ActivatedRoute,
                private dataService: DataService,
                private authService: AuthenticationService,
                private messageService: MessageService,
                private router: Router) {
    }

    ngOnInit(): void {
        if (!window.localStorage.getItem('token')) {
            this.router.navigate(['/login']);
        }
    }

}
