import {Component, OnInit} from '@angular/core';
import * as $ from 'jquery';
import {NavigationEnd, Router, ActivatedRoute} from '@angular/router';
import {filter} from 'rxjs/internal/operators';
import {HttpClient} from '@angular/common/http';
import {DataService} from '@app/_services';
import {apiUrls} from '@app/shared/app.constants';
import {environment} from '@environments/environment';
import {FormGroup, FormBuilder, FormControl, Validators} from '@angular/forms';
import {PaymentsService} from '@app/proceed-payment/payments.service';
import * as shajs from 'sha.js';


@Component({
    selector: 'app-booking-success',
    templateUrl: './booking-success.component.html',
    styleUrls: ['./booking-success.component.css']
})
export class BookingSuccessComponent implements OnInit {

    userSelectedView: any;
    resultView: any;
    total: number;
    baseFare: number;
    prevTotal: number = 0;
    selectBoxTotal: number = 0;
    selectBoxArr: any = [];
    selectedCart = [];
    reviewForm: FormGroup;
    userCustomizations: string;
    messageService: any;
    saleID: any;
    invoiceId: any;
    shal1String: any;
    beforeEncrypt: any;
    shaphr: any;
    paymentid: any;
    userSearchSelected: any;
    miniCartData: any;
    constructor(private http: HttpClient,
                private route: ActivatedRoute,
                private dataService: DataService,
                private paymentsService: PaymentsService,
                private fb: FormBuilder,
                private router: Router) {
    }

    ngOnInit(): void {
        if(window.localStorage.getItem('quoteView')){
            this.resultView = JSON.parse(window.localStorage.getItem('quoteView'));
        }else{
            this.getView();
        }
        if (window.localStorage.getItem('userSearch')) {
            this.userSearchSelected = JSON.parse(window.localStorage.getItem('userSearch'));
        }
        if (window.localStorage.getItem('miniCart')) {
            this.miniCartData = JSON.parse(window.localStorage.getItem('miniCart'));
            this.total = Number(window.localStorage.getItem('carttotal'));

        } else {
            this.miniCartData = '';
        }
        if (window.localStorage.getItem('responseid')) {
            this.paymentid = window.localStorage.getItem('responseid');
        }else{
            this.paymentid = '';
        }
        $.getScript('./assets/js/vehicleGallery.js');
        $.getScript('./assets/js/slickcall.js');
    }

    getView() {
        if (window.localStorage.getItem('userSearchDetail')) {
            $('.loader').show();
            this.userSelectedView = window.localStorage.getItem('userSearchDetail');
            const subs = this.http.get<any>(`${environment.apiUrl}/${apiUrls.searchView}?${this.userSelectedView}`).subscribe(res => {
                subs.unsubscribe();
                if (res) {
                    console.log(res);
                    this.resultView = res.response;
                    this.total = this.resultView.quote.model.components.base.salesPrice.value;
                    this.prevTotal = this.total
                    this.baseFare = this.resultView.quote.model.components.base.salesPrice.value;
                    $.getScript('./assets/js/vehicleGallery.js');
                    $.getScript('./assets/js/slickcall.js');
                    $('.loader').hide();
                }
            }, (err) => {
                $('.loader').hide();
            }, () => {
            });
        } else {
            this.router.navigate(['/search/results']);
        }

    }

    opentab(tabid: any, tabname: any) {
        $('#' + tabname + tabid + '').toggle();
        $('#dicon' + tabid + '').toggleClass("fa-minus");
        $('#' + tabname + tabid + '').parents('.vehicle-item').find('.vehicle-more-details-content').addClass('show');
    }

    closetab(tabid: any, tabname: any) {
        $('#' + tabname + tabid + '').toggle();
        $('#' + tabname + tabid + '').parents('.vehicle-item').find('.vehicle-more-details-content').removeClass('show');
    }
}
