import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { DestinationsComponent } from './destinations/destinations.component';
import { DealsComponent } from './deals/deals.component';
import { ListingComponent } from './itineraries/listing/listing.component';
import { DetailsComponent } from './itineraries/details/details.component';
import { DetailsOverviewComponent } from './itineraries/details-overview/details-overview.component';
import { LoginComponent } from './user/login/login.component';
import { MyAccountComponent } from './user/my-account/my-account.component';
import { MyBookingsComponent } from './user/my-bookings/my-bookings.component';
import { MyFavoritesComponent } from './user/my-favorites/my-favorites.component';
import { MyReviewsComponent } from './user/my-reviews/my-reviews.component';
import { ResultsComponent } from './search/results/results.component';
import { ReviewBookingComponent } from './review-booking/review-booking.component';
import { BookingSuccessComponent } from './booking-success/booking-success.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { ProceedPaymentComponent } from './proceed-payment/proceed-payment.component';
import { RegisterComponent} from '@app/user/register/register.component';
import { BookingInfoComponent} from '@app/booking-info/booking-info.component';
import {StripePaymentComponent} from '@app/stripe-payment/stripe-payment.component';
import {TermsAndConditionsComponent} from '@app/pages/terms-and-conditions/terms-and-conditions.component';
import {PrivacyPolicyComponent} from '@app/pages/privacy-policy/privacy-policy.component';
import {GdprStatementComponent} from '@app/pages/gdpr-statement/gdpr-statement.component';
import { ContactUsComponent} from '@app/contact-us/contact-us.component';
import { VehiclesComponent} from '@app/vehicles/vehicles.component';
import { AboutUsComponent} from '@app/about-us/about-us.component';
import { ForgotPasswordComponent} from '@app/user/forgot-password/forgot-password.component';
import { RetrievePasswordComponent} from '@app/user/retrieve-password/retrieve-password.component';
import { ActivateAccountComponent} from '@app/user/activate-account/activate-account.component';
import { FleetDetailsComponent} from '@app/fleet-details/fleet-details.component';
import { RoadtripsComponent} from '@app/itineraries/roadtrips/roadtrips.component';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        component: HomeComponent
    },
    {
        path: 'search/results',
        component: ResultsComponent
    },
    {
        path: 'destinations1',
        component: DestinationsComponent
    },
    {
        path: 'destinations1/:destination',
        component: DestinationsComponent
    },
    {
        path: 'deals',
        component: DealsComponent
    },
    {
        path: 'partners',
        component: ListingComponent
    },
    {
        path: 'destinations',
        component: DetailsComponent
    },
    {
        path: 'destinations/:destination',
        component: DetailsOverviewComponent
    },
    {
        path: 'destinations/:destination/:itinerary/:itineraryid',
        component: RoadtripsComponent
    },

    {
        path: 'review-booking',
        component: ReviewBookingComponent
    },
    {
        path: 'payments/:saleID',
        component: ProceedPaymentComponent
    },
    {
        path: 'payments',
        component: ProceedPaymentComponent
    },
    {
        path: 'payment/success',
        component: BookingSuccessComponent
    },
    {
        path: 'booking-info',
        component: BookingInfoComponent
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent
    },
    {
        path: 'retrieve-password/:passwordhash',
        component: RetrievePasswordComponent
    },
    {
        path: 'activate-account/:passwordhash',
        component: ActivateAccountComponent
    },
    {
        path: 'register',
        component: RegisterComponent
    },
    {
        path: 'my-account',
        component: MyAccountComponent
    },
    {
        path: 'my-bookings',
        component: MyBookingsComponent
    },
    {
        path: 'my-favourates',
        component: MyFavoritesComponent
    },
    {
        path: 'my-reviews',
        component: MyReviewsComponent
    },
    {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent
    },
    {
        path: 'contact-us',
        component: ContactUsComponent
    },
    {
        path: 'about-us',
        component: AboutUsComponent
    },
    {
        path: 'fleet',
        component: VehiclesComponent
    },
    {
        path: 'fleet-details/:countryCode',
        component: FleetDetailsComponent
    },
    {
        path: 'terms-and-conditions',
        component: TermsAndConditionsComponent
    },
    {
        path: 'gdpr-statement',
        component: GdprStatementComponent
    },
    {
        path: '404',
        component: NotfoundComponent
    },
    {
        path: '**',
        redirectTo: '/404'
    }
];

@NgModule({
    declarations: [],
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled'})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
