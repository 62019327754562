import {Component, OnInit} from '@angular/core';
import * as $ from 'jquery';

@Component({
    selector: 'app-about-us',
    templateUrl: './about-us.component.html',
    styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {
    popid: any;

    constructor() {
    }

    ngOnInit(): void {
    }

    aboutpopup(popid: any) {
        $(".aboutUsPopup").hide();
        $('#' + popid + '').show();
    }
    closeabt(){
        $(".aboutUsPopup").hide();
    }
}
