import {Component, OnInit} from '@angular/core';
import * as $ from 'jquery';
import {NavigationEnd, Router, ActivatedRoute} from '@angular/router';
import {filter} from 'rxjs/internal/operators';
import {HttpClient} from '@angular/common/http';
import {DataService} from '@app/_services';
import {apiUrls} from '@app/shared/app.constants';
import {environment} from '@environments/environment';
import {FormGroup, FormBuilder, FormControl, Validators} from '@angular/forms';
import {PaymentsService} from '@app/proceed-payment/payments.service';
import * as shajs from 'sha.js';

@Component({
    selector: 'app-proceed-payment',
    templateUrl: './proceed-payment.component.html',
    styleUrls: ['./proceed-payment.component.css']
})
export class ProceedPaymentComponent implements OnInit {
    userSelectedView: any;
    resultView: any;
    total: number;
    baseFare: number;
    prevTotal: number = 0;
    selectBoxTotal: number = 0;
    selectBoxArr: any = [];
    selectedCart = [];
    reviewForm: FormGroup;
    userCustomizations: string;
    messageService: any;
    saleID: any;
    invoiceId: any;
    shal1String: any;
    beforeEncrypt: any;
    shaphr: any;
    constructor(private http: HttpClient,
                private route: ActivatedRoute,
                private dataService: DataService,
                private paymentsService: PaymentsService,
                private fb: FormBuilder,
                private router: Router) {
    }

    ngOnInit(): void {
        //$.getScript('./assets/js/vehicleGallery.js');
        this.getView();
        this.route.params.subscribe(routeParams => {
            this.invoiceId = routeParams.saleID;
        });
    }

    proceesToPayment() {
        /* if (!window.localStorage.getItem('token')) {
            window.localStorage.setItem("redirect",this.router.url);
            window.localStorage.setItem("paymentid",this.invoiceId);
            this.router.navigate(['/login']);
        }else{ */
            this.total = this.total * 100;
            $("#amount").val(this.total);
            $("#orderid").val(this.invoiceId);
            this.shaphr = '7f9e436d-3b3c-4169-9112-affc85c673d7';
            this.beforeEncrypt = "AMOUNT="+this.total+this.shaphr+
                "CURRENCY=GBP"+this.shaphr+
                "LANGUAGE=en_US"+this.shaphr+
                "ORDERID="+this.invoiceId+this.shaphr+"PSPID=amhtest999"+this.shaphr;
            // let shal = require('sha1');
            // this.shal1String = shal(this.beforeEncrypt);
            // console.log(this.beforeEncrypt);
            this.shal1String = shajs('sha1').update(this.beforeEncrypt).digest('hex');
            // console.log(this.shal1String);
            $("#shasign").val(this.shal1String);
            $("#form").submit();
            //this.router.navigate(['/payment/success']);
            //return false;
       // }


    }


    arrayToObject(arr: any) {
        var rv = {};
        for (var key in arr) {
            rv[key] = arr[key]
        }
        return rv;
    }

    getView() {
        if (window.localStorage.getItem('userSearchDetail')) {
            $('.loader').show();
            this.userSelectedView = window.localStorage.getItem('userSearchDetail');
            const subs = this.http.get<any>(`${environment.apiUrl}/${apiUrls.searchView}?${this.userSelectedView}`).subscribe(res => {
                subs.unsubscribe();
                if (res) {
                    console.log(res);
                    this.resultView = res.response;
                    this.total = this.resultView.quote.model.components.base.salesPrice.value;
                    this.prevTotal = this.total
                    this.baseFare = this.resultView.quote.model.components.base.salesPrice.value;
                    $.getScript('./assets/js/vehicleGallery.js');
                    $.getScript('./assets/js/slickcall.js');
                    $('.loader').hide();
                }
            }, (err) => {
                $('.loader').hide();
            }, () => {
            });
        } else {
            this.router.navigate(['/search/results']);
        }

    }

}
