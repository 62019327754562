import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/internal/operators';
import { HttpClient } from '@angular/common/http';
import { DataService } from '@app/_services';
import { apiUrls } from '@app/shared/app.constants';
import { environment } from '@environments/environment';
import { RestfulApiService } from "../_services/restful.service";
import { Observable } from 'rxjs';
import { DestinationService } from "./destinations.service";
import { MessageService } from "../_services/message.service";
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { HeaderService } from '../shared/header/header.service'
@Component({
    selector: 'app-destinations',
    templateUrl: './destinations.component.html',
    styleUrls: ['./destinations.component.css']
})
export class DestinationsComponent implements OnInit {
    countryData: any;
    faq: any;
    info: any;
    cities: {};
    countries: {};
    passengerList: {};
    destination: string;
    currentDestination: string;
    filterCountryValue: any;
    startDate: any;
    endDate: any;
    pickupCity: any;
    dropoffCity: any;
    filterPassengers: any;
    filterAge: any;
    populardestinations: any;
    public travelForm: FormGroup;

    constructor(private http: HttpClient,
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private dataService: DataService,
        private messageService: MessageService,
        private destinationService: DestinationService,
        private headerService: HeaderService,
        private router: Router) {
        console.log("inside 1");
        this.travelForm = this.fb.group({
            filter_country: new FormControl('', [Validators.required]),
        })
    }

    ngOnInit() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        $.getScript('./assets/js/datepicker.js');
        $.getScript('./assets/js/dealsSlider.js');
        this.route.params.subscribe(routeParams => {
            this.destination = routeParams.destination;
        });
        this.getCities(this.destination)
        this.getFAQs(this.destination)
        this.getCountries();
        this.getNoOfPassengers();
        // this.travelForm.get("filter_country").disable();
        this.travelForm.controls["filter_country"].setValue(this.destination);
    }

    search() {
        this.filterCountryValue = this.filterCountryValue = this.dataService.getKeyByValue(window.localStorage.getItem('countries'), $("#filterCountry").val());
        this.startDate = $("#startDate").val();
        this.endDate = $("#endDate").val();
        this.pickupCity = $("#pickup_city").val();
        this.dropoffCity = $("#dropoff_city").val();
        this.filterPassengers = $("#filterPassengers").val();
        this.filterAge = $("#filterPassengers").val();

        const userSearchObj = {
            country: this.filterCountryValue,
            pickup_date: this.startDate,
            dropoff_date: this.endDate,
            dropoffcheck: 'on',
            pickup_city: this.pickupCity,
            dropoff_city: this.dropoffCity,
            no_of_passengers: this.filterPassengers,
            driver_age: this.filterAge

        };
        console.log(userSearchObj);
        this.dataService.saveUserSearch(userSearchObj);
        this.router.navigate(['/search/results']);
    }

    private getCountries() {
        $('.loader').show();
        this.destinationService.getCountries().subscribe(
            result => {
                this.countries = result.countries
                console.log(result.countries);
                $('.loader').hide();
            },
            error => {
                console.log(error)
                this.messageService.showErrorMessage(error);
                $('.loader').hide();
            }
        );
    }

    navigate(destination: string) {
        this.router.navigate(['/destinations/' + `${destination}`]);
    }

    getCities(destination: string) {
        this.destination = (destination) ? destination : this.destination;
        $('.loader').show();
        this.destinationService.getCitiesByDestination(this.destination).subscribe(
            result => {
                this.cities = result.response
                console.log(result.response);
                $('.loader').hide();
            },
            error => {
                $('.loader').hide();
                console.log(error)
                this.messageService.showErrorMessage(error);
            }
        );
    }

    getCountriesData(destination: any) {
        $('.loader').show();
        const subs = this.http.post<any>(`${environment.apiUrl}/${apiUrls.getCountriesInfo}`,
            {
                'country': destination
            }
        ).subscribe(res => {
            subs.unsubscribe();
            $('.loader').hide();
            if (res.success) {
                this.countryData = res;
            } else {
                this.router.navigate(['/']);
            }
        }, (err) => {
            $('.loader').hide();
        }, () => {
            $('.loader').hide();
        });
    }

    private getNoOfPassengers() {
        this.headerService.getPassengers().subscribe(
            result => {
                this.passengerList = result.response
                console.log(result);
            },
            error => {
                console.log(error)
                this.messageService.showErrorMessage(error);
            }
        );
    }

    getFAQs(destination: string) {
        this.destination = (destination) ? destination : this.destination;
        $('.loader').show();
        this.destinationService.getCountryInfo(this.destination).subscribe(
            result => {
                console.log(result)
                this.countryData = result
                // this.populardestinations = result.popularDestinations
                this.faq = result.countryInfo.faqs
                this.info = result.countryInfo.info
                $('.loader').hide();
            },
            error => {
                $('.loader').hide();
                console.log(error)
                this.messageService.showErrorMessage(error);
            }
        );
    }
}
