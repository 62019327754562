import {Component, OnInit} from '@angular/core';
import * as $ from 'jquery';
import {NavigationEnd, Router, ActivatedRoute} from '@angular/router';
import {FormGroup, FormBuilder, FormControl, Validators, FormArray} from '@angular/forms';
import {filter} from 'rxjs/internal/operators';
import {HttpClient} from '@angular/common/http';
import {DataService, AuthenticationService} from '@app/_services';
import {apiUrls} from '@app/shared/app.constants';
import {environment} from '@environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';

import {HeaderService} from '@app/shared/header/header.service';

import {first} from 'rxjs/operators';
import {User} from '@app/_models';
import {MustMatch} from '@app/_helpers/must-match.validator';

@Component({
    selector: 'app-popuplogin',
    templateUrl: './popuplogin.component.html',
    styleUrls: ['./popuplogin.component.css']
})
export class PopuploginComponent implements OnInit {

    forgotFrom: FormGroup;
    alertMessage: any;
    registerForm: FormGroup;
    submittedpop = false;
    submitted: any;
    loginFormPop: FormGroup;
    countries: any;
    passengerList: {};
    userDetails: any;
    redirectTo: any;
    public currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    private loggedIn = new BehaviorSubject<boolean>(false);

    constructor(private fb: FormBuilder,
                private formBuilder: FormBuilder,
                private authenticationService: AuthenticationService,
                private http: HttpClient,
                private route: ActivatedRoute,
                private dataService: DataService,
                private authService: AuthenticationService,
                private router: Router) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('token')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    ngOnInit() {
        if (window.localStorage.getItem('token')) {
            this.userDetails = window.localStorage.getItem('token');
        } else {
            this.userDetails = false;
        }
        this.loginFormPop = this.formBuilder.group({
            username: ['', [Validators.required]],
            password: ['', [Validators.required, Validators.minLength(6)]],
        });
        this.registerForm = this.formBuilder.group({
            firstName: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            mobile: ['', [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
            password: ['', [Validators.required, Validators.minLength(6)]],
            confirmPassword: ['', Validators.required],
        }, {
            validator: MustMatch('password', 'confirmPassword')
        });
        this.forgotFrom = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]]
        });
    }

    get f() {
        return this.loginFormPop.controls;
    }

    get reg() {
        return this.registerForm.controls;
    }

    onPopSubmitLogin() {

        this.submittedpop = true;
        // stop here if form is invalid
        if (this.loginFormPop.invalid) {
            return;
        }

        const subs = this.http.post<any>(`${environment.apiUrl}/${apiUrls.login}`, {
            'username': this.f.username.value,
            'password': this.f.password.value
        }).subscribe(res => {
            subs.unsubscribe();
            if (res.success) {
                this.dataService.saveUserToken(res);
                this.loggedIn.next(true);
                this.currentUserSubject.next(res);
                $('.login-signup2').hide();
                this.router.navigate(['/my-account'])
                    .then(() => {
                        window.location.reload();
                    });
                //this.router.navigate(['/my-account']);
            } else {
                $('#poperror').html('Invalid Credentials');
            }
        }, (err) => {

            return;
        }, () => {
        });
        return;
    }

    onSubmit() {
        $("#regerrortop").html("");
        this.submitted = true;
        // stop here if form is invalid
        if (this.registerForm.invalid) {
            return;
        }

        const subs = this.http.post<any>(`${environment.apiUrl}/${apiUrls.register}`,
            {
                'email': this.reg.email.value,
                'firstname': this.reg.firstName.value,
                'phone': this.reg.mobile.value,
                'password': this.reg.password.value
            }).subscribe(res => {
            subs.unsubscribe();
            if(res.success == true){
                this.registerForm.reset();
                $('#registerformalert').show();
                $('#registerform').hide();
                $('#loginform').hide();
                this.alertMessage = res.message;
                $('#regalert').html(res.message);
            }else{
                $("#regerrortop").html(res.message);
            }

            //this.login(this.f.email.value, this.f.password.value);
        }, (err) => {


        }, () => {
        });
    }

    get fog() {
        return this.forgotFrom.controls;
    }

    onSubmitLogin() {

        this.submitted = true;
        // stop here if form is invalid
        if (this.forgotFrom.invalid) {
            return;
        } else {
            $('.loader').show();
            const subs = this.http.post<any>(`${environment.apiUrl}/${apiUrls.forgotPassword}`,
                {
                    'email': this.fog.email.value,
                }).subscribe(res => {
                subs.unsubscribe();
                if (res) {
                    $('.loader').hide();
                    this.forgotFrom.reset();
                    $("#popfogerror").html(res.message);
                    this.alertMessage = res.message;
                }
            }, (err) => {
                $('.loader').hide();
            }, () => {
            });
        }
    }
    emailchange(){
        $("#popfogerror").html('');
    }
    loginpopupclose() {
        $('.login-signup2').hide();
        $('#registerform').hide();
        $('#loginform').show();
        $('#forgotFromdiv').hide();
    }

    showreg() {
        $('#registerform').show();
        $('#loginform').hide();
        $('#forgotFromdiv').hide();
    }
    showlogin(){
        $('#registerform').hide();
        $('#loginform').show();
        $('#forgotFromdiv').hide();
    }
    showforgot(){
        $('#forgotFromdiv').show();
        $('#registerform').hide();
        $('#loginform').hide();
    }
}
