import {Component, OnInit} from '@angular/core';
import * as $ from 'jquery';
import {NavigationEnd, Router, ActivatedRoute} from '@angular/router';
import {filter} from 'rxjs/internal/operators';
import {HttpClient} from '@angular/common/http';
import {DataService, AuthenticationService} from '@app/_services';
import {apiUrls} from '@app/shared/app.constants';
import {environment} from '@environments/environment';
import {MessageService} from '../../_services/message.service';
import {HeaderService} from '@app/shared/header/header.service';

@Component({
  selector: 'app-details-overview',
  templateUrl: './details-overview.component.html',
  styleUrls: ['./details-overview.component.css']
})
export class DetailsOverviewComponent implements OnInit {

    itenariesCountries: any;
    itenariesList: any;
    countryCode: any;
    selectedCountry: any;
    countryInfo: any;
    constructor(private http: HttpClient,
                private route: ActivatedRoute,
                private dataService: DataService,
                private authService: AuthenticationService,
                private messageService: MessageService,
                private router: Router) {
    }

    ngOnInit() {
        this.route.params.subscribe(routeParams => {
            this.countryCode = routeParams.destination;
            this.getItenariesList(this.countryCode);
        });
    }


    getItenariesList(country: any) {
        $('.loader').show();
        const subs = this.http.post<any>(`${environment.apiUrl}/${apiUrls.getItenariesList}`, {
            'country_id': country
        }).subscribe(res => {
            subs.unsubscribe();
            if (res) {
                this.itenariesList = res.ItenariesListData;
                this.selectedCountry = res.selectedCountry;
                this.countryInfo = res.countryInfo;
                console.log(this.countryInfo);
                $('.loader').hide();
            }
        }, (err) => {
            $('.loader').hide();
        }, () => {
        });
    }


}
