<div class="profile-details-main">
    <div class="profile-details">
        <!-- <figure class="user-profile-pic"><img src="../assets/images/user-pic.jpg"></figure> -->
        <aside>
            <h3>{{userDetails.user.name}}&nbsp;{{userDetails.user.last_name}}</h3>
            <span>{{userDetails.user.phone}}</span>
        </aside>
    </div>
    <nav class="account-detals-nav">
        <ul>
            <li><a [routerLinkActive]="['active']" routerLink="/my-account">My Account</a></li>
            <li><a [routerLinkActive]="['active']" routerLink="/my-bookings">My Bookings</a></li>
            <!-- <li><a [routerLinkActive]="['active']" routerLink="/my-favourates">My Favorites</a></li>
            <li><a [routerLinkActive]="['active']" routerLink="/my-reviews">My Reviews</a></li> -->
            <li><a href="javascript:void(0)" (click)="logout()">Sign Out</a></li>
        </ul>
    </nav>
</div>