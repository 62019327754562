<section id="main" class="payment-main-wrap gray-bg">
    <div class="mobile-sub-nav visible-sm visible-xs">
        <a href="#" class="go-back-btn"><img src="./assets/images/left-short-arrow-mobile.svg"></a>
        <h2>Payment</h2>
    </div>
    <div class="payment-progress">
        <div class="container">
            <div class="payment-progress-step active">
                <span class="step-number">1</span>
                <span class="step-name">Review Booking</span>
            </div>
            <div class="payment-progress-step">
                <span class="step-number">2</span>
                <span class="step-name">Payment Info</span>
            </div>
            <div class="payment-progress-step">
                <span class="step-number">3</span>
                <span class="step-name">Booking Success</span>
            </div>
        </div>
    </div>
    <section class="payment-info-wrap clearfix">
        <div class="container" *ngIf="resultView">
            <div class="row">
                <div class="col-sm-9 fare-details-wrap-col">
                    <div class="vehicle-item vehicle-item-plain clearfix">
                        <div class="clearfix">
                            <div class="vehicle-images-gallery hidden-sm hidden-xs" *ngIf="resultView">
                                <div class="vehicle-gallery-min">
                                    <div *ngFor="let images of resultView.quote.model.version.vehicle.moreImages"
                                         class="v-gallery-min-item" data-toggle="modal" data-target="#GallerylightBox">
                                        <figure><img src="{{images.image}}" alt="vehicle"></figure>
                                    </div>
                                </div>
                                <div class="v-gallery-min-num"></div>
                                <a href="javascript:void(0)" class="gallery-fullmode" data-toggle="modal"
                                   data-target="#GallerylightBox"><img src="./assets/images/full-screen.svg"></a>
                            </div>

                            <div class="vehicle-details">
                                <div class="vehicle-title">
                                    <h3 *ngIf="resultView">{{resultView.quote.model.version.vehicle.name}}</h3>
                                    <!-- <div class="vehicle-rating hidden-sm hidden-xs"><img
                                            src="./assets/images/rating.png">
                                    </div> -->
                                    <div style="display: none" class="vehicle-overall-rating hidden-sm hidden-xs"><span>Good, 72%</span>
                                        | 500+ Reviews
                                    </div>
                                </div>
                                <div style="display: none" class="veh-confirm-avail">
                                    <span class="confirm-badge">24h confirmation</span>
                                    <span class="avail-num">Available : 3</span>
                                </div>
                                <div class="vehicle-features">
                                    <ul>
                                        <li>
                                            <span class="v-feature-icon"><img src="./assets/images/bed.svg"></span>
                                            <span class="v-feature-name" *ngIf="resultView">{{resultView.quote.model.version.vehicle.capacity.bedCapacity}}</span>
                                        </li>
                                        <li>
                                            <span class="v-feature-icon"><img src="./assets/images/toilet.svg"></span>
                                            <span class="v-feature-name">Toilet</span>
                                        </li>
                                        <li>
                                            <span class="v-feature-icon"><img src="./assets/images/shower.svg"></span>
                                            <span class="v-feature-name">Shower</span>
                                        </li>
                                        <li>
                                            <span class="v-feature-icon"><img
                                                    src="./assets/images/manual-transmission.svg"></span>
                                            <span class="v-feature-name">Manual</span>
                                        </li>
                                        <li>
                                            <span class="v-feature-icon"><img src="./assets/images/caravan.svg"></span>
                                            <span class="v-feature-name" *ngIf="resultView">{{resultView.quote.model.version.vehicle.vehicleAge.text}}</span>
                                        </li>
                                    </ul>
                                </div>

                                <!-- <div class="vehicle-benifits hidden-sm hidden-xs">

                                    <ul>
                                        <li><span class="v-benefits-label">Benefits :</span></li>
                                        <li><span class="v-benefit-icon"><img
                                                src="./assets/images/check.svg"></span><span class="v-benefit-name">Equipped</span>
                                        </li>
                                        <li><span class="v-benefit-icon"><img
                                                src="./assets/images/check.svg"></span><span
                                                class="v-benefit-name">Esky</span></li>
                                        <li><span class="v-benefit-icon"><img
                                                src="./assets/images/check.svg"></span><span class="v-benefit-name">Outdoor table/chairs</span>
                                        </li>
                                        <li><span class="v-benefit-icon"><img
                                                src="./assets/images/check.svg"></span><span class="v-benefit-name">Portable</span>
                                        </li>
                                        <li><span class="v-benefit-icon"><img
                                                src="./assets/images/check.svg"></span><span class="v-benefit-name">Outdoor table/chairs</span>
                                        </li>
                                    </ul>
                                </div> -->
                                <a class="full-specs-btn-sm visible-xs visible-sm" href="#"><i class="fa fa-plus"
                                                                                               aria-hidden="true"></i>
                                                                                               View Full Details</a>
                                <div class="vehicle-more-details hidden-sm hidden-xs  vehicle-more-details-top-space">

                                    <ul class="">
                                        <li class="view-more-btn full-specs-btn-sm"><a (click)="opentab(1,'FullSpecs')">View Full Details <i
                                                class="fa fa-plus" id="dicon1"></i></a>

                                        </li>
                                        <li style="display: none"><a data-toggle="tab"
                                                                     href="#Insurance1">Capacity</a></li>
                                        <li style="display: none"><a data-toggle="tab"
                                                                     href="#Features1">Features</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="vehicle-more-details-content">
                            <div class="tab-content">
                                <div id="FullSpecs1" class="tab-pane">
                                    <div class="v-details-wrap">
                                        <button class="btn log-sign-close" (click)="closetab(1,'FullSpecs')"><img src="assets/images/close.svg"></button>
                                        <div class="v-details-row viewVehicleSpec">
                                            <ul>
                                                <li *ngFor="let customproperty of resultView.quote.model.version.vehicle.customproperties">
                                                    <span class="v-feature-icon" *ngIf="customproperty.image"><img
                                                            src="assets/images/{{customproperty.image}}"></span>
                                                    <span class="v-feature-name" [ngClass]="{'stikeout' : !customproperty.status}">{{customproperty.label}} <strong *ngIf="customproperty.value"> {{customproperty.value}}</strong></span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div id="Insurance1" class="tab-pane fade" style="display: none">
                                    <div class="v-details-wrap">
                                        <div class="v-details-row">
                                            <div class="v-details-block">
                                                <h4>Capacity</h4>
                                                <ul>
                                                    <li *ngFor="let property of resultView.quote.model.version.vehicle.capacity | keyvalue">
                                                        <span>{{property.key}}</span><span>{{property.value}}</span>
                                                    </li>
                                                </ul>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="Features1" class="tab-pane fade">
                                    <div class="v-details-wrap">
                                        <div class="v-details-row">
                                            <div class="v-details-block">
                                                Not available
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="location-details">
                            <ul>
                                <li>
                                    <span class="location-d-icon"><img
                                            src="./assets/images/localtion_van_gray.svg"></span>
                                    <div class="location-details-txt" *ngIf="resultView">
                                        <span class="location-details-label">Pick up</span>
                                        <span class="location-details-point">{{resultView.quote.model.version.pickUpLocation.text}}</span>
                                        <span class="location-details-extra">
                                            {{resultView.quote.model.version.pickUpLocation.address.companyName}},<br>
                                            {{resultView.quote.model.version.pickUpLocation.address.street}},<br>
                                            {{resultView.quote.model.version.pickUpLocation.address.cityName}},<br>
                                            {{resultView.quote.model.version.pickUpLocation.address.postalCode}},<br>
                                            {{resultView.quote.model.version.pickUpLocation.address.phoneFixnet}}
                                        </span>
                                    </div>
                                </li>
                                <li>
                                    <span class="location-d-icon"><img
                                            src="./assets/images/localtion_van_gray.svg"></span>
                                    <div class="location-details-txt">
                                        <span class="location-details-label">Drop Off</span>
                                        <span class="location-details-point"
                                              *ngIf="resultView.quote.model.version.dropOffLocation">
                                            {{resultView.quote.model.version.dropOffLocation.text}}
                                        </span>
                                        <span class="location-details-point"
                                              *ngIf="!resultView.quote.model.version.dropOffLocation">
                                            {{resultView.quote.model.version.pickUpLocation.text}}
                                        </span>
                                        <span class="location-details-extra"  *ngIf="resultView.quote.model.version.dropOffLocation">
                                            {{resultView.quote.model.version.dropOffLocation.address.companyName}},<br>
                                            {{resultView.quote.model.version.dropOffLocation.address.street}},<br>
                                            {{resultView.quote.model.version.dropOffLocation.address.cityName}},<br>
                                            {{resultView.quote.model.version.dropOffLocation.address.postalCode}},<br>
                                            {{resultView.quote.model.version.dropOffLocation.address.phoneFixnet}}
                                        </span>
                                        <span class="location-details-extra"  *ngIf="!resultView.quote.model.version.dropOffLocation">
                                            {{resultView.quote.model.version.pickUpLocation.address.companyName}},<br>
                                            {{resultView.quote.model.version.pickUpLocation.address.street}},<br>
                                            {{resultView.quote.model.version.pickUpLocation.address.cityName}},<br>
                                            {{resultView.quote.model.version.pickUpLocation.address.postalCode}},<br>
                                            {{resultView.quote.model.version.pickUpLocation.address.phoneFixnet}}
                                        </span>
                                    </div>
                                </li>
                                <li>
                                    <span class="location-d-icon"><img src="./assets/images/passenger.svg"></span>
                                    <div class="location-details-txt">
                                        <span class="location-details-label location-details-passenger">Passengers</span>
                                        <span class="location-details-point">{{userSearchSelected.no_of_passengers}}</span>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div class="selected-date-time">
                            <div class="sele-date-item">
                                <span class="location-d-icon"><img src="./assets/images/clock.svg"></span>
                                <div class="location-details-txt">
                                    <span class="location-details-label">Pick up date</span>
                                    <span class="location-details-point">{{resultView.dates.pickupDate}}</span>
                                </div>

                            </div>
                            <div class="sele-date-item">
                                <span class="location-d-icon"><img src="./assets/images/clock.svg"></span>
                                <div class="location-details-txt">
                                    <span class="location-details-label">Drop off date</span>
                                    <span class="location-details-point">{{resultView.dates.dropoffDate}}</span>
                                </div>

                            </div>
                        </div>
                    </div>

                    <!-- Extra Items -->
                    <!-- Desktop -->

                    <div class="extra-items-wrap boxwrap"
                         *ngFor="let collections of resultView.loopcollection | keyvalue">
                        <h3 class="sub-heading-3">{{collections.key}}</h3>
                        <div class="extra-items-block itemBox">

                            <div *ngFor="let colection of collections.value | keyvalue">
                                <div class="extra-item-row row component-row">
                                    <div class="col-12 col-sm-6 col-xs-6">
                                        <div class="subkey">
                                            {{colection.key}} <span *ngIf="colection.value.included" style="font-size: 9px;color:red">(Included)</span>
                                            <ul class="dash" *ngIf="colection.value.subchilds.length > 0">
                                                <li class="small" *ngFor="let subTitle of colection.value.subchilds">
                                                    {{subTitle}}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-2 col-xs-3">
                                        <div class="row customize-select" *ngIf="colection.value.included">
                                            <div class="col-4">
                                                <span class="fa fa-check text-success" aria-hidden="true"></span>
                                            </div>
                                        </div>
                                        <div class="row customize-select" *ngIf="!colection.value.included">
                                            <div class="col-4" *ngIf="colection.value.function=='option'">
                                                <input type="checkbox" [attr.data-keyvalue]="colection.key" [attr.data-key]="colection.value.key"
                                                       value="{{colection.value.cost}}"
                                                       (click)="addToCartCheckbox($event)">
                                            </div>
                                            <div class="col-4" *ngIf="colection.value.function=='select-pax'">
                                                <div class="number-value">
                                                    <button class="btn btn-dec"
                                                            (click)="updateQty(colection.value.key,colection.value.cost,'remove',colection.key)">
                                                        -
                                                    </button>
                                                    <input type="number" class="form-control {{colection.value.key}}"
                                                           value="0" min="0" [attr.data-keyvalue]="colection.key" [attr.data-key]="colection.value.key"
                                                           [attr.data-cost]="colection.value.cost"
                                                           (change)="addToCartselect($event)">
                                                    <button class="btn btn-inc"
                                                            (click)="updateQty(colection.value.key,colection.value.cost,'add',colection.key)">
                                                        +
                                                    </button>
                                                </div>

                                            </div>
                                            <div class="col-4" *ngIf="colection.value.function=='select-quantity'">
                                                <!-- input type="number" min="0" [attr.data-key]="colection.value.key" [attr.data-cost]="colection.value.cost" (change)="addToCartselect($event)" -->
                                                <div class="number-value">
                                                    <button class="btn btn-dec"
                                                            (click)="updateQty(colection.value.key,colection.value.cost,'remove',colection.key)">
                                                        -
                                                    </button>
                                                    <input type="number" class="form-control {{colection.value.key}}"
                                                           value="0" min="0" [attr.data-keyvalue]="colection.key" [attr.data-key]="colection.value.key"
                                                           [attr.data-cost]="colection.value.cost"
                                                           (change)="addToCartselect($event)">
                                                    <button class="btn btn-inc"
                                                            (click)="updateQty(colection.value.key,colection.value.cost,'add',colection.key)">
                                                        +
                                                    </button>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div class="col-12 col-sm-4 col-xs-3" style="text-align: right"
                                         *ngIf="colection.value.cost!=''">
                                        {{colection.value.currency}}{{colection.value.cost}}
                                    </div>
                                    <div class="col-12 col-sm-4 col-xs-3" style="text-align: right"
                                         *ngIf="colection.value.cost==''">
                                        &nbsp;
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>


                </div>
                <div class="col-sm-3 fare-summary-wrap-col">
                    <div class="fare-summary-wrap">
                        <div class="fare-summary-sm">
                            <h3>Fare Summary</h3>
                            <div class="fare-block">
                                <h4>Base Fare</h4>
                                <ul>
                                    <li>
                                        <span class="pay-cat pay-cat-fees">{{resultView.quote.model.components.base.salesPrice.currency}} {{resultView.quote.model.components.base.salesPrice.value}}</span>
                                        <span class="pay-amount">{{resultView.quote.model.components.base.salesPrice.currency}} {{baseFare}}</span>
                                    </li>
                                    <li>
                                        <ul *ngIf="miniCartData" class="minicartItems">
                                            <li *ngFor="let cartItem of miniCartData">
                                                <span class="itemText">{{cartItem.itemLabel}}</span>
                                                <span class="itemQty">x {{cartItem.itemQty}}</span>
                                                <span class="itemCost">{{cartItem.itemAmount}}</span>
                                            </li>
                                        </ul>
                                    </li>
                                    <li class="total-row">
                                        <span class="pay-cat">Total</span>
                                        <span class="pay-amount">{{resultView.quote.model.components.base.salesPrice.currency}} {{total}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="coupon-block">
                            <h5>COUPON CODE</h5>
                            <div class="form-field">
                                <input type="text" class="form-control">
                                <!-- <span class="coupon-applied">Applied</span> -->
                            </div>
                        </div>
                        <button class="btn btn-make-payment btn-make-payment-primary" id="bookingFormButton" type="button" (click)="bookingInfo()">Proceed To Booking Info
                        </button>

                        <div class="mobile-payment-foot visible-sm visible-xs">
                            <div class="mobile-total-amount">
                                <span><small>{{resultView.quote.model.components.base.salesPrice.currency}}</small><strong>{{total}}</strong></span>
                            </div>
                            <button class="btn btn-make-payment btn-make-payment-primary" (click)="bookingInfo()">Proceed To Booking Info</button>
                        </div>
                        
                        <!-- <div class="sticky-price-mobile visible-xs">
                            <div class="sticky-price-wrap">
                                <div class="sticky-price">
                                    <span class="sticky-pay-cat">Total</span>
                                    <span class="sticky-pay-amount">{{resultView.quote.model.components.base.salesPrice.currency}} {{total}}</span>
                                </div>
                                <div class="sticky-proceed">
                                    <button class="btn btn-sm btn-make-payment btn-make-payment-primary" id="bookingFormButton" type="button" (click)="bookingInfo()">Proceed To Booking Info
                                    </button>
                                </div>
                            </div>
                        </div> -->

                    </div>
                </div>
            </div>
        </div>
    </section>
</section>

<!-- GallerylightBox -->
<div class="modal modal-lightbox fade" id="GallerylightBox" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span>
                </button>
                <h4 class="modal-title">Awesome Classic Camper (2-5 persons) <span id="showSlideCount"></span></h4>
            </div>
            <div class="modal-body">
                <div class="gallary-lightbox-slider gallary-lightbox-view" *ngIf="resultView">
                    <div *ngFor="let images of resultView.quote.model.version.vehicle.moreImages"
                         class="gallary-lightbox-item">
                        <figure><img src="{{images.image}}" alt="vehicle"></figure>
                    </div>
                </div>
                <div class="gallary-lightbox-slider-nav gallary-lightbox-view" *ngIf="resultView">
                    <div *ngFor="let images of resultView.quote.model.version.vehicle.moreImages"
                         class="gallary-lightbox-nav-item">
                        <figure><img src="{{images.image}}" alt="vehicle"></figure>
                    </div>
                </div>
                <div class="text-center" style="display: none">
                    <button class="btn btn-cust btn-primary">Select</button>
                </div>
            </div>
        </div>
    </div>
</div>